export const getStoreName = (globalState, outletId) => {
    const outlet = globalState?.outlets?.find((outlet) => outlet.id === outletId);
    return outlet ? outlet.name : "";
  };
  
  export const getCompanyName = (globalState, outletId) => {
    const company = globalState?.companies?.find(
      (company) => company.id === outletId
    );
    return company ? company.name : "";
  };

  export function removeSymbols(text) {
    if(!text) return undefined
    return text.replace(/[^a-zA-Z0-9]/g, ''); // Menghapus semua karakter selain huruf dan angka
  };
import {
  FaBlog,
  FaFacebook,
  FaInstagram,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import {
  FcBullish,
  FcSms,
  FcEditImage,
  FcKindle,
  FcSettings,
  FcIdea,
  FcClapperboard,
  FcReading,
  FcVoicePresentation,
  FcFinePrint,
  FcDonate,
  FcTemplate,
  FcPortraitMode,
  FcFrame,
  FcGlobe,
  FcSpeaker,
  FcFilmReel,
  FcPicture,
  FcVideoFile,
  FcFilingCabinet,
  FcMultipleCameras,
  FcVideoProjector,
} from "react-icons/fc";

export const data = [
  {
    name: "Page Builder",
    icon: FcFrame,
    submenu: [
      { name: "Funnel", icon: FcEditImage, link: "/funnel" },
      { name: "Domain", icon: FcGlobe, link: "/domain" },
    ],
  },
  {
    name: "Text",
    icon: FcKindle,
    submenu: [
      // { name: "headline", icon: FcEditImage, link: "/create-aissistant" },
      // { name: "caption", icon: FcSms, link: "/folder-aissistant/:name" },
      {
        name: "facebook",
        icon: FaFacebook,
        link: "/folder-aissistant/:name",
      },
      {
        name: "instagram",
        icon: FaInstagram,
        link: "/folder-aissistant/:name",
      },
      {
        name: "tiktok",
        icon: FaTiktok,
        link: "/folder-aissistant/:name",
      },
      {
        name: "youtube",
        icon: FaYoutube,
        link: "/folder-aissistant/:name",
      },
      {
        name: "blog",
        icon: FaBlog,
        link: "/folder-aissistant/:name",
      },
    ],
  },
  {
    name: "Image",
    icon: FcPicture,
    submenu: [
      { name: "Create Image", icon: FcEditImage, link: "/image-generator" },
      {
        name: "Image Collection",
        icon: FcMultipleCameras,
        link: "/image-collection",
      },
    ],
  },
  {
    name: "Audio",
    icon: FcSpeaker,
    submenu: [
      { name: "Headline", icon: FcEditImage, link: "/create-aissistant" },
      // { name: "Caption", icon: FcSettings, link: "/folder-aissistant" },
      // { name: "Podcast", icon: FcSettings, link: "/folder-aissistant" },
      // { name: "Review", icon: FcSettings, link: "/folder-aissistant" },
      // { name: "Story Telling", icon: FcSettings, link: "/folder-aissistant" },
    ],
  },
  {
    name: "Video",
    icon: FcFilmReel,
    submenu: [
      {
        name: "Video",
        icon: FcClapperboard,
        link: "/video/templates",
      },
      {
        name: "Text to Video",
        icon: FcClapperboard,
        link: "/video/text2video",
      },
      { name: "Video Assets", icon: FcVideoFile, link: "/video/assets" },
      //   { name: "Podcast", icon: FcSettings, link: "/folder-aissistant" },
      //   { name: "Review", icon: FcSettings, link: "/folder-aissistant" },
      //   { name: "Story Telling", icon: FcSettings, link: "/folder-aissistant" },
    ],
  },
  {
    name: "Marketer",
    icon: FcBullish,
    submenu: [
      { name: "Create", icon: FcEditImage, link: "/create-aimarketer" },
      { name: "Folder", icon: FcSettings, link: "/folder-aimarketer" },
    ],
  },
  {
    name: "Train Your AI",
    icon: FcReading,
    submenu: [
      { name: "LP Training", icon: FcFrame, link: "/lptrain" },
      { name: "Text Training", icon: FcKindle, link: "/texttrain" },
      // { name: "Q&A", icon: FcSettings, link: "/qatrain" },
    ],
  },
  {
    name: "Templates",
    icon: FcFilingCabinet,
    submenu: [
      { name: "Web", icon: FcFrame, link: "/template-web" },
      { name: "Text", icon: FcKindle, link: "/template-text" },
      // { name: "Q&A", icon: FcSettings, link: "/qatrain" },
    ],
  },
];

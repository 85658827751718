import React from "react";
import LoginPage from "../Pages/Authentication/LoginPage";
import SigunUpPage from "../Pages/Authentication/SignUpPage";
import FunnelEditPageV2 from "../Pages/LadingPageV2/Funnels/FunnelEditPageV2";

const AuthenticationRouter = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/signup",
    element: <SigunUpPage />,
  },


];

export default AuthenticationRouter;

import React, { useEffect } from 'react'
import Uppy from'@uppy/core'
import { Dashboard } from '@uppy/react';
import XHR from '@uppy/xhr-upload';
// import ScreenCapture from '@uppy/screen-capture';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const accessToken =  process.env.REACT_APP_DROPBOX; // Replace with your Dropbox access token



function getResponseData(responseText, response) {
	const parser = new DOMParser();
	console.log(response,'ini di response')
	console.log(JSON.parse(responseText),'ini di responseText')
	const xmlDoc = parser.parseFromString(responseText, 'text/xml');
	console.log(xmlDoc)
	// navigate(-1)
	return {
		url: xmlDoc.querySelector('Location'),
	};
}


const headers = (file) => {
	console.log(file,'ini data file nya')
	return {
		'Content-Type': 'application/octet-stream',
		Authorization: `Bearer ${accessToken}`,
		'Dropbox-API-Arg': JSON.stringify({
		path: `/uploads/projectId/${file.name}`,
		mode: 'add',
		autorename: true,
		mute: false,
		}),

	};
};



const uppy = new Uppy();

uppy.use(XHR, { 
	endpoint: 'https://content.dropboxapi.com/2/files/upload',
	headers:headers,
});



function UppyComponent(props) {
	const navigate = useNavigate()
  return (
	<Dashboard uppy={uppy}  />
  )
}

export default UppyComponent
import { Avatar, Box, HStack, Text } from '@chakra-ui/react'

export const UserProfile = (props) => {
    const { name, image, email, onClick } = props
    return (
        <HStack spacing="3" px="2" onClick={onClick} cursor='pointer'>
            <Avatar name={name} src={image} boxSize="10" />
            <Box maxW={'130px'}>
                <Text textTransform={'capitalize'} fontWeight={500}fontSize="sm">
                    {name}
                </Text>
                <Text color="muted" fontSize="sm" noOfLines={1} >
                    {email}
                </Text>
            </Box>
        </HStack>
    )
}
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { pixabayVideoApi } from "../../Apis/videoApi";

function VideosSlider(props) {
  const [data, setData] = useState();

  console.log(props);

  useEffect(() => {
    pixabayVideoApi(props.data)
      .then((x) => {
        // console.log(props.data);
        // console.log(x.data.hits);
        setData(x.data.hits);
      })
      .catch((err) => console.log(err.message));

    return () => {
      setData();
    };
  }, []);

  return (
    <Flex
      width="full"
      overflowX="scroll"
      gap={4}
      mt={3}
      sx={{
        "&::-webkit-scrollbar": {
          w: "2",
          h: "1",
        },
        "&::-webkit-scrollbar-track": {
          w: "6",
          h: "5",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "10",
          bg: `gray.600`,
        },
      }}
    >
      {data?.map((x, i) => (
        <Box key={i} w="300px">
          {/* <Text>{x.videos.tiny.url}</Text> */}
          <Image
            src={`https://i.vimeocdn.com/video/${x.picture_id}_295x166.jpg`}
          />
          <Button
            my={3}
            size="xs"
            width="full"
            colorScheme="green"
            borderRadius="unset"
            onClick={() =>
              props.setPickVideo((prevPickVideo) => [
                ...prevPickVideo,
                { url: x.videos.medium.url },
              ])
            }
          >
            Choose this video
          </Button>
        </Box>
      ))}
    </Flex>
  );
}

export default VideosSlider;

import axios from "axios"
const apiKeyPixabay = process.env.REACT_APP_PIXABAY
const apikeyCreatomate = process.env.REACT_APP_CREATOMATE


export const pixabayVideoApi = async(data)=>{
	const url = `https://pixabay.com/api/videos/?key=${apiKeyPixabay}&q=${data}`
	console.log(data)
	try {
		const res = await axios.get(url)
		console.log(res)
		return res
	} catch (error) {
		console.log(error.message)
	}
}

export const pixabayImageApi = async(data)=>{
	const url = `https://pixabay.com/api/?key=${apiKeyPixabay}&q=${data}`
	try {
		const res = axios.get(url)
		return res
	} catch (error) {
		console.log(error.message)
	}
}

export const creatomateRenderApi = async(dataset, ratio)=>{

	// const result = Object.values(dataset);
	const result = dataset.map(x => Object.values(x)[0].toString())

	const newResult = result.map((x,i)=>{
	const updateResult =	{
		type: 'video',
		source: x,
		trim_start: 1,
		trim_duration: 5,
		track: 1,

	  }
	  if(i>0)
	  updateResult.animations=[
		{
            "time": "start",
            "duration": 0.5,
            "transition": true,
            "type": "fade"
          }
	  ]
	  return updateResult
	}
	)


	// return null
	const url = `https://api.creatomate.com/v1/renders`
		const data ={
			'source': {
			  'output_format': 'mp4',
			  'width': ratio?.width,
			  'height': ratio?.height,
			  'elements': newResult
			}
		  }

		  const headers = {
			headers: {
				'Authorization': `Bearer ${apikeyCreatomate}`,
				'Content-Type': 'application/json'
			  }
		}
		try {
			const res = axios.post(url,data,headers)
			return res
		} catch (error) {
			console.log(error.message)
		}
}

export const creatomateRenderApiWithAudio = async(dataset, audio, ratio)=>{
	const result = dataset.map(x => Object.values(x)[0].toString())

	const newResult = result.map((x,i)=>{
	const updateResult =	{
		type: 'video',
		source: x,
		trim_start: 1,
		trim_duration: 5,
		track: 1,

	  }
	  if(i>0)
	  updateResult.animations=[
		{
            "time": "start",
            "duration": 0.5,
            "transition": true,
            "type": "fade"
          }
	  ]
	  return updateResult
	}
	)

	const audioElements = {
		'type' : 'audio',
		'time' : 'end',
		'source' : `${audio}`,
		'audio_fade_out' : '1 s'
	}

	// return null
	const url = `https://api.creatomate.com/v1/renders`

	if(audio !== undefined){
		newResult.push(audioElements)

		const data ={
			'source': {
			  'output_format': 'mp4',
			  'width': ratio?.width,
			  'height': ratio?.height,
			  'elements': newResult
			}
		  }

		  const headers = {
			headers: {
				'Authorization': `Bearer ${apikeyCreatomate}`,
				'Content-Type': 'application/json'
			  }
		}
		try {
			const res = axios.post(url,data,headers)
			return res
		} catch (error) {
			console.log(error.message)
		}

	} else {
		const data ={
			'source': {
			  'output_format': 'mp4',
			  'width': ratio?.width,
			  'height': ratio?.height,
			  'elements': newResult
			}
		  }

		  const headers = {
			headers: {
				'Authorization': `Bearer ${apikeyCreatomate}`,
				'Content-Type': 'application/json'
			  }
		}
		try {
			const res = axios.post(url,data,headers)
			return res
		} catch (error) {
			console.log(error.message)
		}
	}
}

export const creatomateTemplateRenderApi = async(preview)=>{
	const url = `https://api.creatomate.com/v1/renders`
	const data ={
		'source': preview
	  }
	const headers = {
		headers: {
			'Authorization': `Bearer ${apikeyCreatomate}`,
			'Content-Type': 'application/json'
		  }
	}
	try {
		const res = axios.post(url,data,headers)
		return res
	} catch (error) {
		console.log(error.message)
	}
}

export const creatomateGetVideo = async(dataId) => {
	const url = `https://api.creatomate.com/v1/renders/${dataId}`
	
	const headers = {
		headers: {
			'Authorization': `Bearer ${apikeyCreatomate}`,
			'Content-Type': 'application/json'
		  }
	}
	try {
		const res = axios.get(url,headers)
		return res
	} catch (error) {
		console.log(error.message)
	}
}

export const creatomateVideoWithTemplate = async(templateId, dataTemplate) => {
	const url = `https://api.creatomate.com/v1/renders`
	const data = {
		'template_id': templateId,
		'modifications' : dataTemplate
	  }
	const headers = {
		headers: {
			'Authorization': `Bearer ${apikeyCreatomate}`,
			'Content-Type': 'application/json'
		  }
	}
	try {
		const res = axios.post(url,data,headers)
		return res
	} catch (error) {
		console.log(error.message)
	}
}
	
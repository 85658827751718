import React from "react";
import DashboardPage from "../Pages/Dashboard/DashboardPage";
import Layout from "../Layouts";

const DashboardRouter = [
  {
    path: "/",
    element: (
        <DashboardPage />
    ),
  },
];

export default DashboardRouter;

import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth } from "../../Config/firebase";
import { create } from "zustand";

const companyItem = localStorage.getItem('currentCompany')
const pojectItem = localStorage.getItem('currentProject')

export const UseAuthStore = create((set, get) => ({
  isLoggedIn: false,
  isPaid: false,
  companies: [],
  projects: [],
  currentCompany: companyItem || "",
  profileKey: "",
  currentProject: pojectItem || "",
  uid: "",
  name: "",
  email: "",
  currentXenditId: "",
  roleCompany: "",
  roleProject: "",
  

  setUid: (data) => {
    set({
      uid: data,
    });
  },

  setName: (data) => {
    set({
      name: data,
    });
  },

  setEmail: (data) => {
    set({
      email: data,
    });
  },


  setIsLoggedIn: (data) => {
    set({
      isLoggedIn: data,
    });
  },
  
  setIsPaid: (data) => {
    set({
      isPaid: data,
    });
  },

  setCompanies: (data) => {
    set({
      companies: data,
    });
  },

  setCurrentCompany: (data) => {
    set({
      currentCompany: data,
    });
  },

  setProjects: (data) => {
    set({
      projects: data,
    });
  },

  setCurrentProject: (data) => {
    set({
      currentProject: data,
    });
  },

  setRoleCompany: (data) => {
    set({
      roleCompany: data,
    });
  },

  setRoleProject: (data) => {
    set({
      roleProject: data,
    });
  },


  setCurrentXenditId: (data) => {
    set({
      currentXenditId: data,
    });
  },
}));

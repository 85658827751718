import React from "react";
import { Route, Routes } from "react-router-dom";
import { SettingAccountPage } from "../Pages/Settings/SettingAccountPage";
import SettingsPage from "../Pages/Settings/SettingsPage";
import SettingPasswordPage from "../Pages/Settings/SettingPasswordPage";
import Layout from "../Layouts";



const SettingRouter = [
  {
    path: "/change-password",
    element: (
        <SettingPasswordPage />
    ),
  },
  {
    path: "/account",
    element: (
        <SettingAccountPage />
    ),
  },
];

export default SettingRouter;

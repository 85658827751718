import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Preview, PreviewState } from "@creatomate/preview";
// import { TextInput } from "./TextInput";
import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import { CreateButton } from "../Button/CreateButton";
import {
  AspectRatio,
  Box,
  Center,
  Flex,
  HStack,
  SimpleGrid,
} from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { deepClone } from "./PreviewUtility";
import { Img } from "@chakra-ui/image";
import {
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  Spacer,
  InputGroup,
  InputRightElement,
  InputRightAddon,
  useToast,
} from "@chakra-ui/react";
import { transition } from "./Effect";
import {
  addDocumentFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from "../../Apis/firebaseApi";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import { decryptToken } from "../../Utils/encryptToken";
import UploadFileToDropbox from "../Dropbox/UploadVideoToDropbox";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../Config/firebase";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const dataImage = [
  "https://creatomate-static.s3.amazonaws.com/demo/harshil-gudka-77zGnfU_SFU-unsplash.jpg",
  "https://creatomate-static.s3.amazonaws.com/demo/samuel-ferrara-1527pjeb6jg-unsplash.jpg",
  "https://creatomate-static.s3.amazonaws.com/demo/simon-berger-UqCnDyc_3vA-unsplash.jpg",
];

export const SettingsPanel = (props) => {
  const toast = useToast();
  const globalState = UseAuthStore();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(dataImage);
  const [videoFile, setVideoFile] = useState();
  const [audioFile, setAudioFile] = useState();
  const [fileName, setFileName] = useState();
  const [element, setElement] = useState([]);
  const [url, setUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [videoUrl, setVideoUrl] = useState({});
  const [audioUrl, setAudioUrl] = useState({});
  const modalInputUrl = useDisclosure();
  const [isVideoLayer, setIsVideoLayer] = useState([]);
  const [isAudioLayer, setIsAudioLayer] = useState([]);
  const [videoCollection, setVideoCollection] = useState([]);
  const [audioCollection, setAudioCollection] = useState([]);
  const uploadModal = useDisclosure();
  const audioUrlModal = useDisclosure();
  const videoUrlModal = useDisclosure();
  const uploadAudioModal = useDisclosure();

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  const handleUploadVideo = async () => {
    setIsUploading(true);
    const token = await getSingleDocumentFirebase("token", "dropbox");

    const decryptResult = decryptToken(`${token?.access_token}`);

    const parentPath = `/${globalState.companies[0]?.name}/AI`; // Ganti dengan path folder di Dropbox yang ingin Anda gunakan
    const dropboxLink = await UploadFileToDropbox(
      videoFile,
      parentPath,
      decryptResult
    );

    const addToFirestore = await addDocumentFirebase(
      "files",
      {
        type: "video_assets",
        projectId: globalState.currentProject,
        path: parentPath,
        category: "video",
        link: dropboxLink?.link,
        name: fileName,
      },
      globalState.currentCompany
    );

    setIsUploading(false);
    uploadModal.onClose();
    console.log(addToFirestore);
  };

  const handleUploadAudio = async () => {
    setIsUploading(true);
    try {
      const token = await getSingleDocumentFirebase("token", "dropbox");

      const decryptResult = decryptToken(`${token?.access_token}`);

      const parentPath = `/${globalState.companies[0]?.name}/AI`; // Ganti dengan path folder di Dropbox yang ingin Anda gunakan
      const dropboxLink = await UploadFileToDropbox(
        audioFile,
        parentPath,
        decryptResult
      );

      console.log({
        type: "audio_assets",
        projectId: globalState?.currentProject,
        path: parentPath,
        category: "audio",
        link: dropboxLink?.link,
        name: fileName,
      });

      const addToFirestore = await addDocumentFirebase(
        "files",
        {
          type: "audio_assets",
          projectId: globalState?.currentProject,
          path: parentPath,
          category: "audio",
          link: dropboxLink?.link,
          name: fileName,
        },
        globalState.currentCompany
      );

      setIsUploading(false);

      toast({
        status: "success",
        title: "Deoapp AI",
        description: "Upload Success",
        duration: 2000,
      });
      uploadAudioModal.onClose();

      console.log(addToFirestore);
    } catch (error) {
      toast({
        status: "error",
        title: "Deoapp AI",
        description: "Upload Failed",
        duration: 2000,
      });
    } finally {
      setIsUploading(false);
    }
  };

  const getVideoCollection = async () => {
    try {
      const q = query(
        collection(db, "files"),
        where("category", "==", "video"),
        where("type", "==", "video_assets"),
        where("projectId", "==", globalState.currentProject)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const videoArr = [];
        querySnapshot.forEach((doc) => {
          videoArr.push({ ...doc.data(), id: doc.id });
        });
        setVideoCollection(videoArr);
      });
    } catch (error) {
      console.log(error);
    }
    // try {
    //   const conditions = [
    //     { field: "type", operator: "==", value: "video_assets" },
    //     {
    //       field: "projectId",
    //       operator: "==",
    //       value: globalState.currentProject,
    //     },
    //   ];
    //   const res = await getCollectionFirebase("files", conditions);
    //   // console.log(res, "xx");
    //   setVideoCollection(res);
    // } catch (error) {
    //   console.log(error, "ini error");
    // }
  };

  const handleInputVideoUrl = () => {
    videoCollection.push(videoUrl);
    videoUrlModal.onClose();
  };

  const handleInputAudioUrl = () => {
    audioCollection.push(audioUrl);
    audioUrlModal.onClose();
  };

  const getAudioCollection = async () => {
    try {
      const q = query(
        collection(db, "files"),
        where("type", "==", "audio_assets"),
        where("category", "==", "audio"),
        where("projectId", "==", globalState?.currentProject)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const audioArr = [];
        querySnapshot.forEach((doc) => {
          audioArr.push({ ...doc.data(), id: doc.id });
        });
        console.log(querySnapshot, "pppp");
        setAudioCollection(audioArr);
      });
    } catch (error) {
      console.log(error);
    }
    // try {
    //   const conditions = [
    //     { field: "type", operator: "==", value: "audio_assets" },
    //     {
    //       field: "projectId",
    //       operator: "==",
    //       value: globalState.currentProject,
    //     },
    //   ];
    //   const res = await getCollectionFirebase("files", conditions);
    //   // console.log(res, "xx");
    //   setAudioCollection(res);
    // } catch (error) {
    //   console.log(error, "ini error");
    // }
  };

  console.log(audioCollection, "ini audio");
  const checkMediaLayer = () => {
    const previewStateVideo = props.preview?.state?.elements?.filter(
      (element) => element?.source?.name?.startsWith("Video-")
    );
    const previewStateAudio = props.preview?.state?.elements?.filter(
      (element) => element?.source?.name?.startsWith("Audio-")
    );
    const previewStateImage = props.preview?.state?.elements?.filter(
      (element) => element?.source?.name?.startsWith("Image-")
    );

    setIsVideoLayer(previewStateVideo);
    setIsAudioLayer(previewStateAudio);
    // console.log(previewStateVideo, "ini hasilnya video");
    // console.log(previewStateAudio, "ini hasilnya audio");
  };

  useEffect(() => {
    getVideoCollection();
    getAudioCollection();
    checkMediaLayer();
  }, [globalState.currentProject]);

  const handleInputUrl = () => {
    dataImage?.push(url);
    setImageUrl(dataImage);

    modalInputUrl.onClose();
  };

  const handleAspectRatioChange = (event) => {
    const selectedOption = event.target.value;
    switch (selectedOption) {
      case "Standard Definition (640 x 480)":
        setPropertyValue(
          props.preview,
          "width",
          "640",
          modificationsRef.current
        );
        setPropertyValue(
          props.preview,
          "height",
          "480",
          modificationsRef.current
        );
        break;
      case "High Definition Landscape (1280 x 720)":
        setPropertyValue(
          props.preview,
          "width",
          "1280",
          modificationsRef.current
        );
        setPropertyValue(
          props.preview,
          "height",
          "720",
          modificationsRef.current
        );
        break;
      case "Full HD Landscape (1920 x 1080)":
        setPropertyValue(
          props.preview,
          "width",
          "1920",
          modificationsRef.current
        );
        setPropertyValue(
          props.preview,
          "height",
          "1080",
          modificationsRef.current
        );
        break;
      case "High Definition Portrait (720 x 1280)":
        setPropertyValue(
          props.preview,
          "width",
          "720",
          modificationsRef.current
        );
        setPropertyValue(
          props.preview,
          "height",
          "1280",
          modificationsRef.current
        );
        break;
      case "Full HD Portrait (1080 x 1920)":
        setPropertyValue(
          props.preview,
          "width",
          "1080",
          modificationsRef.current
        );
        setPropertyValue(
          props.preview,
          "height",
          "1920",
          modificationsRef.current
        );
        break;
      case "Square (1080 x 1080)":
        setPropertyValue(
          props.preview,
          "width",
          "1080",
          modificationsRef.current
        );
        setPropertyValue(
          props.preview,
          "height",
          "1080",
          modificationsRef.current
        );
        break;
      default:
        // Set default aspect ratio here if needed
        break;
    }
  };

  // In this variable, we store the modifications that are applied to the template
  // Refer to: https://creatomate.com/docs/api/rest-api/the-modifications-object
  const modificationsRef = useRef({});
  console.log(modificationsRef, "ini modip ref");
  // Get the slide elements in the template by name (starting with 'Slide-')
  let slideElements = useMemo(() => {
    return props.currentState?.elements.filter((element) =>
      element?.source?.name?.startsWith("Composition-")
    );
  }, [props.currentState]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(slideElements);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    slideElements = items;
  };

  // console.log(props.preview, "ini props preview");
  return (
    <Box>
      <CreateButton preview={props.preview} />

      <Stack my={4}>
        <Text>Video Setting</Text>
        <HStack>
          <HStack>
            <FormLabel>Video Size </FormLabel>
            <Select onChange={handleAspectRatioChange}>
              <option>Standard Definition {"(640 x 480)"}</option>
              <option>Square {"(1080 x 1080)"}</option>
              <option>High Definition Landscape {"(1280 x 720)"}</option>
              <option>High Definition Portrait {"(720 x 1280)"}</option>
              <option>Full HD Landscape {"(1920 x 1080)"}</option>
              <option>Full HD Portrait {"(1080 x 1920)"}</option>
            </Select>
          </HStack>
        </HStack>
        <Text>Duration: {props.preview?.state?.duration}s</Text>
        <Text fontSize={"sm"}>Your max duration for 1 video is 5 minutes</Text>
      </Stack>

      <Stack>
        <Text>Text Component</Text>
        <Stack>
          <Input
            bgColor="white"
            color="black"
            sx={inputStyles}
            placeholder="First-Text"
            onFocus={() =>
              ensureElementVisibility(props.preview, "First-Text", 1.5)
            }
            onChange={(e) =>
              setPropertyValue(
                props.preview,
                "First-Text",
                e.target.value,
                modificationsRef.current
              )
            }
          />
          <Input
            bgColor="white"
            color="black"
            sx={inputStyles}
            placeholder="Second-Text"
            onFocus={() =>
              ensureElementVisibility(props.preview, "Second-Text", 1.5)
            }
            onChange={(e) =>
              setPropertyValue(
                props.preview,
                "Second-Text",
                e.target.value,
                modificationsRef.current
              )
            }
          />
        </Stack>
      </Stack>

      <Box>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="elements">
            {(provided) => (
              <ul
                style={{ listStyle: "none" }}
                className="elements"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {slideElements?.map((slideElement, i) => {
                  const transitionAnimation =
                    slideElement?.source?.animations?.find(
                      (animation) => animation.transition
                    );

                  const nestedElements =
                    props.preview.getElements(slideElement);
                  const textElement = nestedElements.find((element) =>
                    element?.source?.name?.startsWith("Text-")
                  );
                  const imageElement = nestedElements.find((element) =>
                    element?.source?.name?.startsWith("Background-")
                  );
                  const videoElement = nestedElements.find((element) =>
                    element?.source?.name?.startsWith("Video-")
                  );

                  console.log(slideElements, "ini element");
                  console.log(imageElement, "ini video");

                  return (
                    <>
                      <Draggable
                        key={slideElement?.source?.id}
                        draggableId={slideElement?.source?.id}
                        index={i}
                      >
                        {(provided) => (
                          <li
                            style={{
                              border: "1px",
                              borderRadius: "md",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <Stack
                              bg={"blackAlpha.300"}
                              borderRadius={"md"}
                              key={i}
                              mx={"20px"}
                              my={3}
                              p={4}
                              spacing={3}
                            >
                              <Box mb={2}>Composition {i + 1}</Box>
                              {textElement && (
                                <>
                                  <Fragment>
                                    <Input
                                      bgColor="white"
                                      color="black"
                                      sx={inputStyles}
                                      placeholder={textElement?.source?.text}
                                      onFocus={() =>
                                        ensureElementVisibility(
                                          props.preview,
                                          textElement?.source?.name,
                                          1.5
                                        )
                                      }
                                      onChange={(e) =>
                                        setPropertyValue(
                                          props.preview,
                                          textElement?.source?.name,
                                          e.target.value,
                                          modificationsRef.current
                                        )
                                      }
                                    />
                                    <Select
                                      onFocus={() =>
                                        ensureElementVisibility(
                                          props.preview,
                                          textElement?.source?.name,
                                          1.5
                                        )
                                      }
                                      onChange={(e) =>
                                        setTextStyle(
                                          props.preview,
                                          textElement?.source?.name,
                                          e.target.value,
                                          modificationsRef.current
                                        )
                                      }
                                    >
                                      <option value="block-text">
                                        Block Text
                                      </option>
                                      <option value="rounded-text">
                                        Rounded Text
                                      </option>
                                    </Select>
                                    <Select
                                      colorScheme="white"
                                      value={transitionAnimation?.type}
                                      onFocus={() =>
                                        ensureElementVisibility(
                                          props.preview,
                                          slideElement?.source.name,
                                          0.5
                                        )
                                      }
                                      onChange={(e) =>
                                        setSlideTransition(
                                          props.preview,
                                          slideElement?.source.name,
                                          e.target.value
                                        )
                                      }
                                    >
                                      {transition.map((x, i) => (
                                        <option key={i} value={x.effect}>
                                          {x.name}
                                        </option>
                                      ))}
                                    </Select>
                                    {imageElement && (
                                      <Flex m={2} gap={5}>
                                        {imageUrl?.map((url, i) => (
                                          <Img
                                            cursor={"pointer"}
                                            w={75}
                                            src={url}
                                            key={i}
                                            onClick={async () => {
                                              await ensureElementVisibility(
                                                props.preview,
                                                imageElement?.source?.name,
                                                1.5
                                              );
                                              await setPropertyValue(
                                                props.preview,
                                                imageElement?.source?.name,
                                                url,
                                                modificationsRef.current
                                              );
                                            }}
                                          />
                                        ))}
                                        <Box
                                          alignItems={"center"}
                                          justifyContent={"center"}
                                        >
                                          <Button
                                            onClick={modalInputUrl.onOpen}
                                          >
                                            Add Image
                                          </Button>
                                        </Box>
                                      </Flex>
                                    )}
                                  </Fragment>

                                  {isVideoLayer.length > 0 ? (
                                    <Stack
                                      p={3}
                                      my={3}
                                      bg={"blackAlpha.300"}
                                      borderRadius={"md"}
                                    >
                                      {videoCollection.length > 0 ? (
                                        <>
                                          <Text align={"center"}>
                                            Change Video Content
                                          </Text>
                                          <HStack>
                                            {videoCollection.map((video, i) => (
                                              <>
                                                <Stack
                                                  key={i}
                                                  cursor={"pointer"}
                                                  _hover={{
                                                    bg: "blackAlpha.200",
                                                    transition: "0.3s",
                                                  }}
                                                  onClick={() =>
                                                    setPropertyValue(
                                                      props.preview,
                                                      // videoElement?.source?.name,
                                                      "Video-1",
                                                      video?.directLink,
                                                      modificationsRef.current
                                                    )
                                                  }
                                                >
                                                  <Box
                                                    bg={"green.300"}
                                                    borderRadius={"md"}
                                                    p={2}
                                                    maxW={"fit-content"}
                                                  >
                                                    {video.name}
                                                  </Box>
                                                  <Spacer />
                                                  <AspectRatio maxW={150}>
                                                    <iframe src={video?.link} />
                                                  </AspectRatio>
                                                </Stack>
                                              </>
                                            ))}
                                          </HStack>
                                          <HStack justifyContent={"center"}>
                                            {loading === true ? (
                                              <Button
                                                isLoading
                                                size={"sm"}
                                                colorScheme={"green"}
                                                onClick={uploadModal.onOpen}
                                              >
                                                Upload Video
                                              </Button>
                                            ) : (
                                              <Button
                                                size={"sm"}
                                                colorScheme={"green"}
                                                onClick={uploadModal.onOpen}
                                              >
                                                Upload Video
                                              </Button>
                                            )}
                                            <Button
                                              size={"sm"}
                                              colorScheme={"green"}
                                              onClick={videoUrlModal.onOpen}
                                            >
                                              Input Video URL
                                            </Button>
                                          </HStack>
                                        </>
                                      ) : (
                                        <Stack>
                                          <Center>No Video Data</Center>
                                          <HStack justifyContent={"center"}>
                                            {loading === true ? (
                                              <Button
                                                isLoading
                                                size={"sm"}
                                                colorScheme={"green"}
                                                onClick={uploadModal.onOpen}
                                              >
                                                Upload Video
                                              </Button>
                                            ) : (
                                              <Button
                                                size={"sm"}
                                                colorScheme={"green"}
                                                onClick={uploadModal.onOpen}
                                              >
                                                Upload Video
                                              </Button>
                                            )}
                                            <Button
                                              size={"sm"}
                                              colorScheme={"green"}
                                              onClick={videoUrlModal.onOpen}
                                            >
                                              Input Video URL
                                            </Button>
                                          </HStack>
                                        </Stack>
                                      )}
                                    </Stack>
                                  ) : null}

                                  {isAudioLayer.length > 0 ? (
                                    <Stack
                                      bg={"blackAlpha.300"}
                                      borderRadius={"md"}
                                      p={3}
                                    >
                                      {audioCollection.length > 0 ? (
                                        <>
                                          <Text align={"center"}>
                                            Change Audio Content
                                          </Text>
                                          <HStack>
                                            {audioCollection.map((audio, i) => (
                                              <>
                                                <Stack
                                                  maxW={300}
                                                  overflowX={"auto"}
                                                  key={i}
                                                  cursor={"pointer"}
                                                  _hover={{
                                                    bg: "blackAlpha.200",
                                                    transition: "0.3s",
                                                  }}
                                                  onClick={() =>
                                                    setPropertyValue(
                                                      props.preview,
                                                      "Audio-1",
                                                      audio.directLink,
                                                      modificationsRef.current
                                                    )
                                                  }
                                                >
                                                  <Box
                                                    bg={"green.300"}
                                                    borderRadius={"md"}
                                                    p={2}
                                                  >
                                                    {audio?.name}
                                                  </Box>
                                                </Stack>
                                              </>
                                            ))}
                                          </HStack>
                                          <HStack justifyContent={"center"}>
                                            {loading === true ? (
                                              <Button
                                                isLoading
                                                size={"sm"}
                                                colorScheme={"green"}
                                                onClick={
                                                  uploadAudioModal.onOpen
                                                }
                                              >
                                                Upload Audio
                                              </Button>
                                            ) : (
                                              <Button
                                                size={"sm"}
                                                colorScheme={"green"}
                                                onClick={
                                                  uploadAudioModal.onOpen
                                                }
                                              >
                                                Upload Audio
                                              </Button>
                                            )}
                                            <Button
                                              size={"sm"}
                                              colorScheme={"green"}
                                              onClick={audioUrlModal.onOpen}
                                            >
                                              Input Audio URL
                                            </Button>
                                          </HStack>
                                        </>
                                      ) : (
                                        <Stack>
                                          <Center>No Audio Data</Center>
                                          <HStack justifyContent={"center"}>
                                            {loading === true ? (
                                              <Button
                                                isLoading
                                                size={"sm"}
                                                colorScheme={"green"}
                                                onClick={
                                                  uploadAudioModal.onOpen
                                                }
                                              >
                                                Upload Audio
                                              </Button>
                                            ) : (
                                              <Button
                                                size={"sm"}
                                                colorScheme={"green"}
                                                onClick={
                                                  uploadAudioModal.onOpen
                                                }
                                              >
                                                Upload Audio
                                              </Button>
                                            )}
                                            <Button
                                              size={"sm"}
                                              colorScheme={"green"}
                                              onClick={audioUrlModal.onOpen}
                                            >
                                              Input Audio URL
                                            </Button>
                                          </HStack>
                                        </Stack>
                                      )}
                                    </Stack>
                                  ) : null}
                                </>
                              )}
                            </Stack>
                          </li>
                        )}
                      </Draggable>
                    </>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </Box>

      <Stack mb={2}>
        <Stack>
          <Text>Brand Name</Text>
          <Input
            bgColor="white"
            color="black"
            sx={inputStyles}
            placeholder="Your Brand Name"
            onFocus={() => ensureElementVisibility(props.preview, "Brand", 1.5)}
            onChange={(e) =>
              setPropertyValue(
                props.preview,
                "Brand",
                e.target.value,
                modificationsRef.current
              )
            }
          />
        </Stack>
        <Stack>
          <Text>Call To Action</Text>
          <Input
            bgColor="white"
            color="black"
            sx={inputStyles}
            placeholder="Your CTA"
            onFocus={() =>
              ensureElementVisibility(props.preview, "Call To Action", 1.5)
            }
            onChange={(e) =>
              setPropertyValue(
                props.preview,
                "Call To Action",
                e.target.value,
                modificationsRef.current
              )
            }
          />
        </Stack>
      </Stack>

      <Modal isOpen={modalInputUrl.isOpen} onClose={modalInputUrl.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Input Your Image URL</ModalHeader>
          <ModalBody>
            <Input
              placeholder="Your image url"
              onChange={(e) => setUrl(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme={"green"} onClick={handleInputUrl}>
              Add Image
            </Button>
            {/* <Button onClose={modalInputUrl.onClose}>Close</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={videoUrlModal.isOpen} onClose={videoUrlModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Input Your Image URL</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Video Name</FormLabel>
              <Input
                placeholder="Your video name"
                onChange={(e) =>
                  setVideoUrl({ ...videoUrl, name: e.target.value })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Video Url</FormLabel>
              <Input
                placeholder="Your video url"
                onChange={(e) =>
                  setVideoUrl({ ...videoUrl, directLink: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme={"green"} onClick={handleInputVideoUrl}>
              Add Video
            </Button>
            {/* <Button onClose={modalInputUrl.onClose}>Close</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={audioUrlModal.isOpen} onClose={audioUrlModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Input Your Audio URL</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Audio Name</FormLabel>
              <Input
                placeholder="Your video name"
                onChange={(e) =>
                  setAudioUrl({ ...audioUrl, name: e.target.value })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Audio Url</FormLabel>
              <Input
                placeholder="Your video url"
                onChange={(e) =>
                  setAudioUrl({ ...audioUrl, directLink: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme={"green"} onClick={handleInputAudioUrl}>
              Add Audio
            </Button>
            {/* <Button onClose={modalInputUrl.onClose}>Close</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={uploadModal.isOpen} onClose={uploadModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Your Video Here</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Video Name</FormLabel>
              <Input
                placeholder="Your Video Name"
                onChange={(e) => setFileName(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Video File</FormLabel>
              <Input
                type="file"
                onChange={(e) => setVideoFile(e.target.files)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              size={"sm"}
              colorScheme="blue"
              mr={3}
              onClick={uploadModal.onClose}
            >
              Close
            </Button>
            {isUploading === true ? (
              <Button
                isLoading
                size={"sm"}
                colorScheme="green"
                onClick={handleUploadVideo}
              >
                Upload
              </Button>
            ) : (
              <Button
                size={"sm"}
                colorScheme="green"
                onClick={handleUploadVideo}
              >
                Upload
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={uploadAudioModal.isOpen}
        onClose={uploadAudioModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Your Audio Here</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Audio Name</FormLabel>
              <Input
                placeholder="Your Audio Name"
                onChange={(e) => setFileName(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Audio File</FormLabel>
              <Input
                type="file"
                onChange={(e) => setAudioFile(e.target.files)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              size={"sm"}
              colorScheme="blue"
              mr={3}
              onClick={uploadModal.onClose}
            >
              Close
            </Button>
            {isUploading === true ? (
              <Button
                isLoading
                size={"sm"}
                colorScheme="green"
                onClick={handleUploadAudio}
              >
                Upload
              </Button>
            ) : (
              <Button
                size={"sm"}
                colorScheme="green"
                onClick={handleUploadAudio}
              >
                Upload
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Button
        colorScheme="green"
        onClick={() => addSlide(props.preview)}
        style={{ width: "100%" }}
      >
        Add Slide
      </Button>
    </Box>
  );
};

// Updates the provided modifications object
const setPropertyValue = async (preview, selector, value, modifications) => {
  if (value?.trim()) {
    // console.log(value, "ini value");
    // If a non-empty value is passed, update the modifications based on the provided selector
    modifications[selector] = value;
  } else {
    // If an empty value is passed, remove it from the modifications map, restoring its default
    delete modifications[selector];
  }

  // Set the template modifications
  await preview.setModifications(modifications);
};

// Sets the text styling properties
// For a full list of text properties, refer to: https://creatomate.com/docs/json/elements/text-element
const setTextStyle = async (preview, selector, style, modifications) => {
  if (style === "block-text") {
    modifications[`${selector}.background_border_radius`] = "0%";
  } else if (style === "rounded-text") {
    modifications[`${selector}.background_border_radius`] = "50%";
  }

  await preview.setModifications(modifications);
};

// Jumps to a time position where the provided element is visible
const ensureElementVisibility = async (preview, elementName, addTime) => {
  // Find element by name
  const element = preview
    .getElements()
    .find((element) => element.source?.name === elementName);
  if (element) {
    // Set playback time
    await preview.setTime(element.globalTime + addTime);
  }
};

// Sets the animation of a slide element
const setSlideTransition = async (preview, slideName, type) => {
  // Make sure to clone the state as it's immutable
  const mutatedState = deepClone(preview.state);

  // console.log(slideName);

  // Find element by name
  const element = preview
    .getElements(mutatedState)
    .find((element) => element.source?.name === slideName);
  if (element) {
    // Set the animation property
    // Refer to: https://creatomate.com/docs/json/elements/common-properties
    element.source.animations = [
      {
        type,
        duration: 1,
        transition: true,
      },
    ];

    // Update the video source
    // Refer to: https://creatomate.com/docs/json/introduction
    await preview.setSource(preview.getSource(mutatedState));
  }
};

const addSlide = async (preview) => {
  // Get the video source
  // Refer to: https://creatomate.com/docs/json/introduction
  const source = preview.getSource();

  // console.log(preview);
  console.log(source, "ini source");

  // Delete the 'duration' and 'time' property values to make each element (Slide-1, Slide-2, etc.) autosize on the timeline
  delete source.duration;
  for (const element of source.elements) {
    delete element.time;
  }

  // Find the last slide element (e.g. Slide-3)
  const lastSlideIndex = source.elements.findLastIndex((element) =>
    element.name?.startsWith("Composition-")
  );
  if (lastSlideIndex !== -1) {
    const slideName = `Composition-${lastSlideIndex}`;

    // Create a new slide
    const newSlideSource = createSlide(
      slideName,
      `This is the text caption for newly added slide ${lastSlideIndex}.`
    );

    console.log(newSlideSource, "ini newslide");

    // Insert the new slide
    source.elements.splice(lastSlideIndex + 1, 0, newSlideSource);

    // Update the video source
    await preview.setSource(source);

    // Jump to the time at which the text element is visible
    // await ensureElementVisibility(preview, `${slideName}-Text`, 1.5);
    await ensureElementVisibility(preview, `Text-${lastSlideIndex}`, 1.5);

    // Scroll to the bottom of the settings panel
    const panel = document.querySelector("#panel");
    if (panel) {
      panel.scrollTop = panel.scrollHeight;
    }
  }
};

const createSlide = (slideName, caption) => {
  // This is the JSON of a new slide. It is based on existing slides in the "Image Slideshow w/ Intro and Outro" template.
  // Refer to: https://creatomate.com/docs/json/introduction
  return {
    name: slideName,
    type: "composition",
    track: 1,
    duration: 4,
    animations: [
      {
        type: "fade",
        duration: 1,
        transition: true,
      },
    ],
    elements: [
      {
        name: `Background-${slideName}`,
        type: "image",
        animations: [
          {
            easing: "linear",
            type: "scale",
            fade: false,
            scope: "element",
            end_scale: "130%",
            start_scale: "100%",
          },
        ],
        source:
          "https://creatomate-static.s3.amazonaws.com/demo/samuel-ferrara-1527pjeb6jg-unsplash.jpg",
      },
      {
        name: `Text-${slideName}`,
        type: "text",
        time: 0.5,
        duration: 3.5,
        y: "83.3107%",
        width: "70%",
        height: "10%",
        x_alignment: "50%",
        y_alignment: "100%",
        fill_color: "#ffffff",
        animations: [
          {
            time: "start",
            duration: 1,
            easing: "quadratic-out",
            type: "text-slide",
            scope: "split-clip",
            split: "line",
            direction: "up",
            background_effect: "scaling-clip",
          },
          {
            easing: "linear",
            type: "scale",
            fade: false,
            scope: "element",
            y_anchor: "100%",
            end_scale: "130%",
            start_scale: "100%",
          },
        ],
        text: caption,
        font_family: "Roboto Condensed",
        font_weight: "700",
        background_color: "rgba(220,171,94,1)",
        background_x_padding: "80%",
      },
    ],
  };
};

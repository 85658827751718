import React from "react";
import FunnelCreatePageV2 from "../Pages/LadingPageV2/Funnels/FunnelCreatePageV2";
import LandingpageTraining from "../Pages/Training/LandingpageTraining";
import LandingpageTrainingTemplateContent from "../Pages/Training/LandingpageTrainingTemplateContent";
import TextTraining from "../Pages/Training/TextTraining";
import LandingPageTrainingView from "../Pages/Training/LandingPageTrainingView";
import LandingPageTrainingCreate from "../Pages/Training/LandingPageTrainingCreate";
import LandingPageTrainingEditPage from "../Pages/Training/LandingPageTrainingEditPage";
import TextTrainingEdit from "../Pages/Training/TextTrainingEdit";

const TrainingRouter = [
  {
    path: "/lptrain",
    element: <LandingpageTraining />,
  },
  {
    path: "/lptrain/:id",
    element: <LandingpageTrainingTemplateContent />,
  },
  {
    path: "/lptrain/:id/create",
    element: <LandingPageTrainingCreate />,
  },
  {
    path: "/lptrain/:id/view/:content",
    element: <LandingPageTrainingView />,
  },
  {
    path: "/lptrain/:id/view/:content/edit",
    element: <LandingPageTrainingEditPage />,
  },
  {
    path: "/texttrain",
    element: <TextTraining />,
  },
  {
    path: "/texttrain/:id/edit",
    element: <TextTrainingEdit />,
  },
];

export default TrainingRouter;

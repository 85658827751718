import {
  Button,
  Center,
  HStack,
  Heading,
  Input,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Image,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FcPlus } from "react-icons/fc";
import { UseAuthStore } from "../../../Hooks/Zustand/store";
import { Link, useNavigate } from "react-router-dom";
import {
  addDocumentFirebase,
  deleteDocumentFirebase,
  getCollectionFirebase,
} from "../../../Apis/firebaseApi";
import moment from "moment";
import vercelLogo from "../../../Assets/Image/Vercel.png";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { clientTypessense } from "../../../Apis/Typesense";
import BackButtons from "../../../Components/Button/BackButtons";
import {
  deleteDataApiBearer,
  getDataApiBearer,
} from "../../../Apis/axiosWithBarier";
import { deleteDomainCustom } from "../../../Apis/vercelAPI";

const DomainPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const globalState = UseAuthStore();
  const [data, setData] = useState([]);
  const [dataDomain, setDataDomain] = useState();
  const [lastUpdated, setLastUpdated] = useState(null);
  const navigate = useNavigate();
  const toast = useToast({
    position: "top",
    align: "center",
  });

  const getData = (date) => {
    const conditions = [
      { field: "companyId", operator: "==", value: globalState.currentCompany },
      // { field: "projectId", operator: "==", value: globalState.currentProject },
      // { field: "category", operator: "==", value: "domain" },
    ];
    const limitValue = 9;
    const startAfterData = date;
    const sortBy = { field: "createdAt", direction: "asc" };

    getCollectionFirebase(
      "domains",
      conditions
      // sortBy,
      // limitValue,
      // startAfterData
    )
      .then((x) => {
        if (x.length > 0) {
          setData((prevData) => [...prevData, ...x]);
          setLastUpdated(x[x.length - 1].lastUpdated);
        } else {
          setLastUpdated(null);
        }
      })
      .catch((err) => {
        console.log(err.message);
        toast({
          title: "Error",
          description: err.message,
          status: "error",
        });
      });
  };

  const handleSearchUsers = (q) => {
    if (!q) {
      return getData(null);
    }

    const searchParameters = {
      q: q,
      query_by: "title",
      filter_by: `type:aissistant AND createdBy:${globalState.uid} AND companyId:${globalState.currentCompany}`,
      sort_by: "_text_match:desc",
    };

    clientTypessense
      .collections("files")
      .documents()
      .search(searchParameters)
      .then((x) => {
        const hits = x.hits.map((x) => x.document);
        setData(hits);
        setLastUpdated(null);
      })
      .catch((err) => {
        console.log(err.message);
        toast({
          title: "Error",
          description: err.message,
          status: "error",
        });
      });
  };

  const handleDeleteDomain = async () => {
    try {
      const response = await deleteDomainCustom(dataDomain.name);

      if (response === 200) {
        const result = await deleteDocumentFirebase("domains", dataDomain.id);

        toast({
          status: "success",
          title: "Deoapp AI",
          description: "Domain Deleted",
          duration: 2000,
        });
      } else {
        toast({
          status: "error",
          title: "Deoapp AI",
          description: "Delete failed",
          duration: 2000,
        });
      }

      const logRes = addDocumentFirebase(
        "logs",
        {
          activity: `delete custom domain from vercel`,
          detail: { domainName: dataDomain.name, domainId: dataDomain.id },
          projectId: globalState.currentProject,
          uid: globalState.uid,
        },
        globalState.currentCompany
      );
      console.log(logRes, "log added");

      onClose();
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        title: "Deoapp AI",
        description: `${error}`,
        duration: 2000,
      });
    }
  };

  const handleCheckDomain = async (domain) => {
    const requestData = {
      domain_name: domain.vercelData.name,
    };

    try {
      const response = await getDataApiBearer(
        "https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/vercelCheckDomain",
        requestData
      );
    } catch (error) {
      toast({
        status: "error",
        title: "Deoapp AI",
        description: `${error.message}`,
        duration: 2000,
      });
    }
  };

  const handleOpenModal = (domain) => {
    onOpen();
    setDataDomain(domain);
  };

  const loadMoreData = () => {
    getData(lastUpdated);
  };

  useEffect(() => {
    getData(null);
    return () => {
      setData([]);
      setLastUpdated(null);
    };
  }, [globalState.currentCompany]);

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  return (
    <>
      <Stack p={[1, 1, 5]} spacing={5}>
        <HStack>
          <Heading>Domains</Heading>
          <Spacer />
          <Button
            colorScheme="green"
            variant="outline"
            onClick={() => navigate("/create-domain")}
          >
            <HStack spacing={2}>
              <FcPlus />
              <Text textTransform="uppercase" fontSize="sm" fontWeight={500}>
                New Domain
              </Text>
            </HStack>
          </Button>
        </HStack>

        <Input
          type="text"
          placeholder="Search Files ..."
          bgColor="white"
          color="black"
          sx={inputStyles}
          fontSize="sm"
          onChange={(e) => handleSearchUsers(e.target.value)}
        />

        {data.length > 0 ? (
          <TableContainer
            border={"1px"}
            borderRadius={"md"}
            shadow={"md"}
            p={2}
          >
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Domain Name</Th>
                  <Th>Funnels</Th>
                  <Th>Server</Th>
                  <Th>Status</Th>
                  <Th>Created At</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                <>
                  {data?.map((x, i) => (
                    <>
                      <Tr key={i}>
                        <Td>
                          <Link to={`/domain/detail/${x?.id}`}>{x?.name}</Link>
                        </Td>
                        <Td>{x?.funnels}</Td>
                        <Td>
                          <Image w={75} src={vercelLogo} />
                        </Td>
                        {x?.vercelData?.verified === true ? (
                          <Td color={"green.300"}>"Verified"</Td>
                        ) : (
                          <Td color={"red.300"}>"Not Verified"</Td>
                        )}
                        <Td>
                          {moment(x?.createdAt?.seconds * 1000).format("LLLL")}
                        </Td>
                        <Td>
                          <HStack>
                            <Button size={"sm"}>
                              <EditIcon />
                            </Button>
                            <Button
                              size={"sm"}
                              onClick={() => handleOpenModal(x)}
                            >
                              <DeleteIcon />
                            </Button>
                          </HStack>
                        </Td>
                      </Tr>
                    </>
                  ))}
                </>
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Stack fontSize="md">
            <Text textAlign="center" fontWeight="semibold">
              No Domain
            </Text>
          </Stack>
        )}

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>Domain will be deleted, are you sure?</ModalBody>
            <ModalFooter>
              <HStack>
                <Button
                  size={"sm"}
                  colorScheme="green"
                  onClick={handleDeleteDomain}
                >
                  Yes
                </Button>
                <Button size={"sm"} colorScheme="red" onClick={onClose}>
                  No
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* <SimpleGrid columns={[1, 2, 3]} gap="4">
          {data?.map((x, i) => (
            <Stack
              key={i}
              _hover={{
                transform: "scale(1.05)",
                shadow: "xl",
              }}
              transition="0.2s ease-in-out"
              spacing={2}
              borderTopWidth={3}
              borderColor="green.500"
              bgColor="#2B2B2B"
              py={4}
              px={2}
              borderRadius="md"
              shadow="md"
            >
              <Link to={`view/${x.id}`}>
                <HStack spacing={2}>
                  <Stack p={5}>
                    {x.template && (
                      <Icon
                        as={
                          aissistantTemplates.find((z) => z.id === x.template)
                            .icon
                        }
                        color={
                          aissistantTemplates.find((z) => z.id === x.template)
                            .iconColor
                        }
                        boxSize={12}
                      />
                    )}
                  </Stack>
                  <Box>
                    <Text textTransform="capitalize" fontWeight={500}>
                      {x.title}
                    </Text>
                    {x.template && (
                      <Text fontSize="2xs">
                        Type:{" "}
                        {
                          aissistantTemplates.find((z) => z.id === x.template)
                            .name
                        }
                      </Text>
                    )}
                    <Text fontSize="2xs">ID: {x.id}</Text>
                  </Box>
                </HStack>
              </Link>

              <Stack alignItems="flex-end" justifyContent="flex-end">
                <Text fontSize="2xs">
                  {moment
                    .unix(x?.lastUpdated?.seconds ?? x?.createdAt.seconds)
                    .fromNow()}
                </Text>
              </Stack>
            </Stack>
          ))}
        </SimpleGrid> */}

        {/* <Center>
          {lastUpdated && data?.length % 9 === 0 && (
            <Button mt="2" colorScheme="green" onClick={loadMoreData}>
              Load more
            </Button>
          )}
        </Center> */}
      </Stack>
    </>
  );
};

export default DomainPage;

import React from "react";
import AissistantViewPage from "../Pages/Copywritting/AissistantViewPage";
import Layout from "../Layouts";
import AissistantPage from "../Pages/Copywritting/AissistantPage";
import AiCreatePage from "../Pages/Copywritting/AiCreatePage";

const CopywrittingRouter = [
  {
    path: "/create-aissistant/:name/:content",
    element: <AiCreatePage />,
  },
  // {
  //   path: "/create-aissistant/:name/:type",
  //   element: <AiCreatePage />,
  // },
  {
    path: "/folder-aissistant/:name",
    element: <AissistantPage />,
  },
  {
    path: "/folder-aissistant/:name/view/:id",
    element: <AissistantViewPage />,
  },
  {
    path: "/folder-aissistant/view/:id",
    element: <AissistantViewPage />,
  },
];

export default CopywrittingRouter;

import {
  AspectRatio,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Textarea,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  List,
  ListItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FcPlus } from "react-icons/fc";
import { generateChatCompletion } from "../../Apis/openAi";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  creatomateGetVideo,
  creatomateRenderApi,
  creatomateVideoWithTemplate,
  pixabayVideoApi,
} from "../../Apis/videoApi";
import VideosSlider from "../../Components/Videos/VideosSlider";
import { CloseIcon } from "@chakra-ui/icons";
import UploadFileToDropbox from "../../Components/Dropbox/UploadVideoToDropbox";
import BackButtons from "../../Components/Button/BackButtons";
import {
  addDocumentFirebase,
  getSingleDocumentFirebase,
} from "../../Apis/firebaseApi";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import { useParams } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";

function VideoTextToVideo() {
  const toast = useToast();
  const [template, setTemplate] = useState();
  const [file, setFile] = useState();
  const [caption, setCaption] = useState({});
  const [data, setData] = useState({});
  const [dataVideo, setDataVideo] = useState();
  const [pickVideo, setPickVideo] = useState([]);
  const [renderedStatus, setRenderedStatus] = useState();
  const [resultStatus, setResultStatus] = useState();
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [loadingGPT, setLoadingGPT] = useState(false);
  const [isRendering, setIsRendering] = useState(false);
  // const [videoOrder, setVideoOrder] = useState()
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [videoUrl, setVideoUrl] = useState();
  const [fileName, setFileName] = useState();
  const globalState = UseAuthStore();
  const param = useParams();

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  const getDataTemplate = async () => {
    try {
      const res = await getSingleDocumentFirebase("templates", param.id);
      setTemplate(res);
    } catch (error) {
      console.log(error);
    }
  };

  const checkDataVideo = async () => {
    setLoadingGPT(true);
    setIsRendering(true);
    if (caption) {
      try {
        const resultVideo = await creatomateGetVideo(renderedStatus.id);
        console.log(resultVideo.data);
        setResultStatus(resultVideo?.data);

        if (resultStatus?.status !== "succeed") {
          toast({
            status: "success",
            title: "Deoapp AI",
            description: "Your video is being rendered, please wait",
            duration: 3000,
          });
        } else {
          return null;
        }
      } catch (error) {
        console.log(error.message);
        toast({
          status: "error",
          title: "Deoapp AI",
          description: "Input all the caption first",
          duration: 2000,
        });
      } finally {
        setLoadingGPT(false);
      }
    } else {
      toast({
        status: "error",
        title: "Deoapp AI",
        description: "choose the video first",
        duration: 3000,
      });
    }
  };

  const handleGenerateTemplate = async () => {
    setLoadingGPT(true);
    setIsRendering(true);
    const captionText = {
      modifications: {
        "b7a7fa0f-913d-4618-8f9b-544e4a10e023":
          "https://cdn.creatomate.com/files/assets/b5dc815e-dcc9-4c62-9405-f94913936bf5",
        "171c9f1f-43e4-4b75-8796-8f3820087dad":
          "https://cdn.creatomate.com/files/assets/b5dc815e-dcc9-4c62-9405-f94913936bf5",
        "Background-1":
          "https://cdn.creatomate.com/files/assets/4a7903f0-37bc-48df-9d83-5eb52afd5d07",
        "Text-1": `${caption.caption_1}`,
        "Background-2":
          "https://cdn.creatomate.com/files/assets/4a6f6b28-bb42-4987-8eca-7ee36b347ee7",
        "Text-2": `${caption.caption_2}`,
        "Background-3":
          "https://cdn.creatomate.com/files/assets/4f6963a5-7286-450b-bc64-f87a3a1d8964",
        "Text-3": `${caption.caption_3}`,
        "Background-4":
          "https://cdn.creatomate.com/files/assets/36899eae-a128-43e6-9e97-f2076f54ea18",
        "Text-4": `${caption.caption_4}`,
        "Background-5":
          "https://cdn.creatomate.com/files/assets/4f6963a5-7286-450b-bc64-f87a3a1d8964",
        "Text-5": `${caption.caption_5}`,
        "7788f2b3-5aed-4e40-87ec-103bac41fb45":
          "https://cdn.creatomate.com/files/assets/4a6f6b28-bb42-4987-8eca-7ee36b347ee7",
        "Text-6": `${caption.caption_6}`,
        "def718b4-173e-4480-ab8d-3a9759f94c90":
          "https://cdn.creatomate.com/files/assets/4a6f6b28-bb42-4987-8eca-7ee36b347ee7",
        "Text-CTA": `${caption.cta}`,
        "6619f96e-cf48-4e4a-a7bd-e410009887ed": `${caption.brandName}`,
        "Call To Action": `See you at ${caption.brandName}`,
      },
    };

    try {
      const res = await creatomateVideoWithTemplate(
        template.templateId,
        captionText.modifications
      );

      setRenderedStatus(res.data[0]);

      setLoadingGPT(false);
      toast({
        status: "success",
        title: "Deoapp AI",
        description: "Your video is being rendered, please wait",
        duration: 3000,
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoadingGPT(false);
      setIsRendering(false);
    }
  };

  const handleUploadVideo = async () => {
    const token = await getSingleDocumentFirebase("token", "dropbox");

    const parentPath = `/${globalState.companies[0]?.name}/AI/video-assets`; // Ganti dengan path folder di Dropbox yang ingin Anda gunakan
    const dropboxLink = await UploadFileToDropbox(
      file,
      parentPath,
      token.access_token
    );

    console.log(dropboxLink);

    pickVideo.push({ url: dropboxLink?.link });
    setPickVideo(pickVideo);

    const addToFirestore = await addDocumentFirebase(
      "files",
      {
        type: "video_assets",
        path: parentPath,
        category: "video",
        link: dropboxLink?.link,
        name: fileName,
      },
      globalState.currentCompany
    );

    console.log(addToFirestore);
  };

  useEffect(() => {
    getDataTemplate();
  }, []);

  return (
    <Stack>
      <BackButtons />
      <HStack my={5}>
        <Heading>Text to Video With Template</Heading>
      </HStack>

      <Box align={"center"}>
        <Text>Template Preview</Text>
        <AspectRatio maxWidth={"md"} my={5}>
          <iframe src={template?.link} />
        </AspectRatio>
      </Box>

      <Flex gap="5">
        <Stack p="2" w={"40%"}>
          <FormControl>
            <FormLabel>Brand Name</FormLabel>
            <Input
              bgColor="white"
              color="black"
              sx={inputStyles}
              placeholder="Your Brand Name"
              size="sm"
              onChange={(e) =>
                setCaption({ ...caption, brandName: e.target.value })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Caption 1</FormLabel>
            <Input
              bgColor="white"
              color="black"
              sx={inputStyles}
              placeholder="Your text caption 1"
              size="sm"
              onChange={(e) =>
                setCaption({ ...caption, caption_1: e.target.value })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Caption 2</FormLabel>
            <Input
              bgColor="white"
              color="black"
              sx={inputStyles}
              placeholder="Your text caption 2"
              size="sm"
              onChange={(e) =>
                setCaption({ ...caption, caption_2: e.target.value })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Caption 3</FormLabel>
            <Input
              bgColor="white"
              color="black"
              sx={inputStyles}
              placeholder="Your text caption 3"
              size="sm"
              onChange={(e) =>
                setCaption({ ...caption, caption_3: e.target.value })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Caption 4</FormLabel>
            <Input
              bgColor="white"
              color="black"
              sx={inputStyles}
              placeholder="Your text caption 4"
              size="sm"
              onChange={(e) =>
                setCaption({ ...caption, caption_4: e.target.value })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Caption 5</FormLabel>
            <Input
              bgColor="white"
              color="black"
              sx={inputStyles}
              placeholder="Your text caption 5"
              size="sm"
              onChange={(e) =>
                setCaption({ ...caption, caption_5: e.target.value })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Caption 6</FormLabel>
            <Input
              bgColor="white"
              color="black"
              sx={inputStyles}
              placeholder="Your text caption 6"
              size="sm"
              onChange={(e) =>
                setCaption({ ...caption, caption_6: e.target.value })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Call To Action</FormLabel>
            <Input
              bgColor="white"
              color="black"
              sx={inputStyles}
              placeholder="Your Call To Action Text"
              size="sm"
              onChange={(e) => setCaption({ ...caption, cta: e.target.value })}
            />
          </FormControl>

          {loadingGPT ? (
            <Center>
              <Button colorScheme="green" w={300} isLoading>
                Generate video
              </Button>
            </Center>
          ) : (
            <Center>
              <Button
                mt="2"
                p="2"
                w={300}
                colorScheme="green"
                onClick={() => handleGenerateTemplate()}
              >
                Generate video
              </Button>
            </Center>
          )}

          {/* <Button
              mt="2"
              p="2"
              w="full"
              colorScheme="red"
              onClick={() => console.log(pickVideo)}
            >
              Check State
            </Button> */}
        </Stack>
        <Box border={"1px"} p={5} mb={3} w={"60%"}>
          <Center>
            {loadingGPT === true ? (
              <Button
                isLoading
                mt="2"
                p="2"
                size={"sm"}
                w={200}
                colorScheme="red"
                onClick={() => checkDataVideo()}
              >
                Check Video Result
              </Button>
            ) : (
              <Button
                mt="2"
                p="2"
                size={"sm"}
                w={200}
                colorScheme="red"
                onClick={() => checkDataVideo()}
              >
                Check Video Result
              </Button>
            )}
          </Center>
          {resultStatus?.status === "succeeded" ? (
            <>
              <Text fontWeight={"semibold"} align={"center"}>
                Result Status
              </Text>
              <Box>
                <AspectRatio>
                  <iframe src={resultStatus?.url} />
                </AspectRatio>
              </Box>
            </>
          ) : (
            <>
              {isRendering === false ? (
                <Center mt={3}>
                  <Text>Click the button to see results</Text>
                </Center>
              ) : (
                <Loading />
              )}
            </>
          )}
        </Box>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Your Video Here</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Video Name</FormLabel>
              <Input
                placeholder="Your Video Name"
                onChange={(e) => setFileName(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Video File</FormLabel>
              <Input type="file" onChange={(e) => setFile(e.target.files)} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button size={"sm"} colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button size={"sm"} colorScheme="green" onClick={handleUploadVideo}>
              Upload
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default VideoTextToVideo;

import { Box, Divider, HStack, Icon, Link, Stack, Text } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import { FaBlog, FaFacebook, FaGlobe, FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

function DashboardPage() {

  const navigate = useNavigate()


  const dataLandingPage = [
    {
      name: "Landing Page Builder",
      link: "funnel",
      icon: FaGlobe,
      color: "white.300"
    },
  ]

  const dataCopyWriting = [
    {
      name: "Facebook",
      link: "folder-aissistant/facebook",
      icon: FaFacebook,
      color: "blue.300"
    },
    {
      name: "instagram",
      link: "folder-aissistant/instagram",
      icon: FaInstagram,
      color: "red.300"
    },
    {
      name: "tiktok",
      link: "folder-aissistant/tiktok",
      icon: FaTiktok,
      color: "black.300"
    },
    {
      name: "youtube",
      link: "folder-aissistant/youtube",
      icon: FaYoutube,
      color: "red.300"

    },
    {
      name: "blog",
      link: "folder-aissistant/blog",
      icon: FaBlog,
      color: "yellow.300"
    },
  ]

  return (
    <Stack p={[1, 1, 5]} spacing={10} >
      <Stack>
        <Text fontWeight={'bold'} fontSize='3xl'>🚀 Welcome to Deoapp AI</Text>
        <Text color={'gray.300'}>Make your business more efficient with the help of Artificial Intelligence (AI).</Text>
        <Divider />
      </Stack>

      <Stack>
        <Text color={'gray.100'}>Do you want to make your landingpage business for make your business growth ? </Text>
        <HStack maxW={'1000px'} overflowX='scroll'>
          {dataLandingPage.length > 0 && dataLandingPage.map((x, i) => {
            return (
              <Stack
                key={i}
                _hover={{
                  transform: "scale(1.05)",
                  shadow: "xl",
                }}
                transition="0.2s ease-in-out"
                spacing={2}
                borderTopWidth={3}
                borderColor="green.500"
                bgColor="#2B2B2B"
                py={4}
                px={2}
                borderRadius="md"
                shadow="md"
                onClick={() => navigate(`/${x.link}`)}
              >
                <HStack spacing={2} w='280px'>
                  <Stack p={5}>
                    <Icon as={x.icon} color={x.color} boxSize={12} />
                  </Stack>
                  <Box>
                    <Text textTransform="capitalize" fontWeight={500}>
                      {x.name}
                    </Text>
                  </Box>
                </HStack>

                <Stack alignItems="flex-end" justifyContent="flex-end">
                  <Text fontSize="2xs">
                    deoapp AI
                  </Text>
                </Stack>
              </Stack>
            )
          })}

        </HStack>
      </Stack>

      <Stack>
        <Text color={'gray.100'}>Do you want to make your copy writing for your social media, to make your digital marketing growth ? </Text>
        <HStack maxW={'1000px'} overflowX='scroll'>
          {dataCopyWriting.length > 0 && dataCopyWriting.map((x, i) => {
            return (
              <Stack
                key={i}
              
                _hover={{
                  transform: "scale(1.05)",
                  shadow: "xl",
                }}
                transition="0.2s ease-in-out"
                spacing={2}
                borderTopWidth={3}
                borderColor="green.500"
                bgColor="#2B2B2B"
                py={4}
                px={2}
                borderRadius="md"
                shadow="md"
                onClick={() => navigate(`/${x.link}`)}
              >
                <HStack spacing={2}   w='280px'>
                  <Stack p={5}>
                    <Icon as={x.icon} color={x.color} boxSize={12} />
                  </Stack>
                  <Box>
                    <Text textTransform="capitalize" fontWeight={500}>
                      {x.name}
                    </Text>
                  </Box>
                </HStack>

                <Stack alignItems="flex-end" justifyContent="flex-end">
                  <Text fontSize="2xs">
                    deoapp AI
                  </Text>
                </Stack>
              </Stack>
            )
          })}

        </HStack>
      </Stack>




    </Stack>
  )
}

export default DashboardPage
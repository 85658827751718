import { useEffect, useState } from "react";
import { Box, Stack, useColorMode, useToast } from "@chakra-ui/react";
import Layout from "./Layouts";
import MainRouter from "./Router/MainRouter";
import AuthRouter from "./Router/AuthRouter";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from "./Config/firebase";
import { getCollectionFirebase, getSingleDocumentFirebase } from "./Apis/firebaseApi";
import { UseAuthStore } from "./Hooks/Zustand/store";
import { useNavigate } from "react-router-dom";
import store from 'store';
import { collection, query, getDocs, getDoc, doc} from "@firebase/firestore";
import { logoutIfExpired, logoutUserWithIp } from "./Hooks/Middleware/sessionMiddleWare";
import { removeSymbols } from "./Utils/nameUtil";
import moment from "moment";
import Loading from "./Components/Loading/Loading";

function App() {
  const globalState = UseAuthStore();
  const { toggleColorMode } = useColorMode();
  const [getProject, setGetProject] = useState('')
  const [getCompany, setGetCompany] = useState('')
  const [isStripePaid, setIsStripePaid] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [companyId, setCompanyId] = useState(
    localStorage.getItem("currentCompany") || ""
  );
  const [projectId, setProjectId] = useState(
    localStorage.getItem("currentProject") || ""
  );
  
  const navigate = useNavigate("");

  const toast = useToast({
    position: "top",
    align: "center",
  });

  const middleWarePayment = (type) => {
    if (type === "companies") {
      toast({
        title: "Alert!",
        description:
          "You have to make payment first, for register your company!",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      navigate('/account')
    }else if(type === "companies and stripe"){
      toast({
        title: "Alert!",
        description:
          "Please register your company!",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      navigate('/account')
    } else {
      toast({
        title: "Alert!",
        description:
          "You have to create your project in tab team!",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      navigate('/account')

    }
  };

const checkDataPaymentStripe = async (uid) => {

  const dataUser = await getSingleDocumentFirebase(`customers`, uid)
  console.log(dataUser, 'uiuiou');

  if(dataUser !== {}){
    try {
      const res = await getCollectionFirebase(
        `customers/${uid}/payments`,
      );
      console.log(res, 'okokok');

        if(res.length === 0){
          toast({
            title: "Alert!",
            description:
              "You have to make your payment",
            status: "warning",
            duration: 2000,
            isClosable: true,
          });
          navigate('/account')
        } else {
          globalState.setIsPaid(true)
          setIsStripePaid(true)
          console.log(isStripePaid);
        }

        if (isStripePaid === true) {
          return middleWarePayment("companies and stripe");
        }
      } catch (error) {
      console.log(error, "ini error");
      }
  }    
}

  const fetchProjectsAndCompanies = async (uid) => {
    const fetchCompanyId = localStorage.getItem('currentCompany')

    const conditions = [
      {
        field: "users",
        operator: "array-contains",
        value: uid,
      },
    ];

     // const projects = await getCollectionFirebase("projects", conditions);
     const companies = await getCollectionFirebase("companies", conditions);
  
     if (companies.length === 0) {
       return middleWarePayment("companies");
     }

    if(!fetchCompanyId){
      try {
        globalState.setCompanies(companies);
        globalState.setCurrentCompany(companies[0]?.id);
        globalState.setCurrentXenditId(companies[0]?.xenditId);
        localStorage.setItem('currentCompany', companies[0]?.id)

        console.log(globalState.currentXenditId, 'gaada company localstorage')
  
        if (companies.length > 0 && companies[0].owner?.includes(uid)) {
          globalState.setRoleCompany("owner");
        } else if (companies.length > 0 && companies[0].managers?.includes(uid)) {
          globalState.setRoleCompany("managers");
        } else {
          globalState.setRoleCompany("user");
        }
  
      } catch (error) {
        console.log(error, "ini error");
      }
    } else {

      const getCompanies = await getSingleDocumentFirebase("companies", fetchCompanyId)

        globalState.setCompanies(companies);
        globalState.setCurrentCompany(companyId);
        globalState.setCurrentXenditId(getCompanies?.xenditId);

        if (getCompanies.owner?.includes(uid)) {
          globalState.setRoleCompany("owner");
        } else if (getCompanies.managers?.includes(uid)) {
          globalState.setRoleCompany("managers");
        } else {
          globalState.setRoleCompany("user");
        }

    }
  };

  const fetchProjects = async(uid) => { 
    const fetchProjectId = localStorage.getItem('currentProject')

    const conditions = [
      {
        field: "users",
        operator: "array-contains",
        value: uid,
      },
      {
        field: "companyId",
        operator: "==",
        value: globalState.currentCompany,
      },
    ];
    
    const projects = await getCollectionFirebase("projects", conditions);

    if(!fetchProjectId){
      try {
        globalState.setProjects(projects);
        localStorage.setItem('currentProject', projects[0]?.id)
        globalState.setCurrentProject(projects[0]?.id);
        

  
        if (projects.length > 0 && projects[0].owner?.includes(uid)) {
          globalState.setRoleProject("owner");
        } else if (projects.length > 0 && projects[0].managers?.includes(uid)) {
          globalState.setRoleProject("managers");
        } else {
          globalState.setRoleProject("user");
        }
  
        if (projects.length === 0 || undefined) {
          return middleWarePayment("project");
        }

        
      } catch (error) {
        console.log(error, 'ini error');
      }
    } else {
      const getProjects = await getSingleDocumentFirebase("projects", fetchProjectId)

        globalState.setProjects(projects);
        localStorage.setItem('currentProject', projects[0]?.id)
        globalState.setCurrentProject(fetchProjectId);


        if (getProjects.owner?.includes(uid)) {
          globalState.setRoleProject("owner");
        } else if (getProjects.managers?.includes(uid)) {
          globalState.setRoleProject("managers");
        } else {
          globalState.setRoleProject("user");
        }
    }
    
  }

  const handleLogout = async () => {
    const email = globalState.email
    const pathLink = 'ai'
    await logoutUserWithIp(window.location.hostname, email, pathLink);

    signOut(auth)
      .then(() => {
        // Sign-out successful.
        toast({
          status: "success",
          description: "Logged out success",
          duration: 2000,
        });

        globalState.setIsLoggedIn(false);
        navigate("/");
        store.clearAll();
      })
      .catch((error) => {
        console.log(error, "ini error");
      });
  };

  const checkSubscription = async () => {
    try {
      const dataCompany = await getSingleDocumentFirebase('companies', globalState.currentCompany)
      console.log(dataCompany, 'ini company');

      if(new Date(dataCompany.expired_at) < new Date()){
        console.log('Company should pay again')
        toast({
          title: 'Deoapp AI',
          description: 'Your Subscription is ended, please contact our cs or buy the package again to use our feature',
          status: 'warning',
          duration:3000
        })
        handleLogout()

      } else {
        console.log('Company is still on subscription')
      }
    } catch (error) {
      console.log(error, 'ini error');
    }
  }
  
  useEffect(() => {
    checkSubscription()
  }, [globalState.currentCompany])
  
  useEffect(() => {
    toggleColorMode("dark");
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const emailNonSymbol = removeSymbols(user.email)
        const pathLink = 'ai'  

        const res = await logoutIfExpired(window.location.hostname, emailNonSymbol, pathLink)

        if(res){
          handleLogout()
        }

        globalState.setIsLoggedIn(true);
        globalState.setUid(user.uid);
        globalState.setName(user.displayName);
        globalState.setEmail(user.email);
        checkDataPaymentStripe(user?.uid)
        fetchProjectsAndCompanies(user?.uid);

        setIsLoading(false)
      } else {
        globalState.setIsLoggedIn(false);
        setIsLoading(false)
      }
    });
    return () => { };
  }, []);

  console.log("current company", globalState.currentCompany);
  console.log("current project", globalState.currentProject);
 

  // useEffect(() => {
  //   console.log(globalState.uid);
  //   checkDataPaymentStripe(globalState.uid)
   
  // }, [])
  
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchProjects(user?.uid);
      } else {
        globalState.setIsLoggedIn(false);
      }
    });
  
    return () => {
    }
  }, [globalState.currentCompany])

  return (
    <Stack>
      {/* Where to route ? */}
      {/* {globalState.isLoggedIn ? (
        <Layout>
          <MainRouter />
        </Layout>
      ) : (
        <AuthRouter />
      )} */}
       {isLoading ? (
        // Show a loading indicator here if data is still being fetched
        <Box alignContent={'center'} justifyItems={'center'} mt={30}>
          <Loading />
        </Box>
      ) : globalState.isLoggedIn ? (
        <Layout>
          <MainRouter />
        </Layout>
      ) : (
        <AuthRouter />
      )}
    </Stack>
  );
}

export default App;

import { ChevronLeftIcon, ChevronRightIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  getCollectionFirebase,
  updateDocumentFirebase,
} from "../../../Apis/firebaseApi";
import { generateChatCompletion16K, openai } from "../../../Apis/openAi";
import BackButtons from "../../../Components/Button/BackButtons";
import Loading from "../../../Components/Loading/Loading";

function ExampleCreate() {
  const [message, setMessage] = useState({});
  const [loadingSave, setLoadingSave] = useState(false);
  const [choosenTemplate, setChoosenTemplate] = useState();
  const [tamplatesData, setTamplatesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [aiGenerator, setAiGenerator] = useState([]);
  const [answerCode, setAnswerCode] = useState([]);
  const [tamplateActive, setTamplateActive] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const browseTemplateModal = useDisclosure();

  const { state } = useLocation();

  const param = useParams();

  const orderArray = {};
  const toast = useToast({
    position: "top",
    align: "center",
  });

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  const getDataTamplate = async () => {
    const conditions = [{ field: "type", operator: "==", value: "website" }];
    // const sortBy = { field: "createdAt", direction: "asc" };
    // const limitValue = 10;

    try {
      const res = await getCollectionFirebase(
        "templates",
        conditions
        // sortBy,
        // limitValue
      );
      setTamplatesData(res);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const handleTemplate = async (x) => {
    // setChoosenTemplate(x);

    try {
      const res = await getCollectionFirebase(`templates/${x.id}/prompt`);
      setChoosenTemplate(res);

      const data = res.map((html) => {
        return {
          title: html?.title,
          loading: false,
          htmlContent: null,
          htmlTemplate: html?.code,
        };
      });

      setAiGenerator(data);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  useEffect(() => {
    getDataTamplate();
  }, []);

  // const handleAi = async () => {
  //   setLoading(true);

  //   try {
  //     for (let i = 0; i < aiGenerator?.length; i++) {
  //       const x = aiGenerator[i];
  //       setAiGenerator((prevState) => {
  //         const updatedGenerator = [...prevState];
  //         updatedGenerator[i] = { ...x, loading: true };
  //         return updatedGenerator;
  //       });

  //       console.log(x, "ini generator");
  //       const promptMap = {
  //         header: "navbar",
  //         heroes: "Headline and subheadline",
  //         valueProposition: 'strength of the product',
  //         keyFeature: "3 features and or benefits",
  //         benefits: "3 features and or benefits",
  //         testimony: "3 Testimonials",
  //         urgency: "offering product sales",
  //         cta: "Call to actions",
  //         footer: "Call to actions",
  //       };

  //       console.log(aiGenerator);

  //       const prompt = promptMap[x.title] || "";

  //       console.log(prompt);

  //       let str = "";
  //       for (const [p, val] of Object.entries(message)) {
  //         str += `${p.replace("_", " ")}:\n -${val}\n`;
  //       }

  //       console.log(x.htmlContent);
  //       const newJSON = `Create a high converting copy for ${x.title} section ${prompt} to be used in a sales page. Please make it simple, unique, not too long sentence, straight to the point and persuasive with data per below\n\n${str}`;
  //       // const newJSON = `Change the text content in this html inline css template ${x.htmlContent} code for a high converting copy for ${x.title} section ${prompt} to be used in a sales page. Please make it simple, unique, not too long sentence, straight to the point and persuasive with data per below\n\n${str}`;
  //       console.log(newJSON, "ini newJson");

  //       const requestMessage = [
  //         {
  //           role: "system",
  //           content:
  //             "You’re a site layout creator that responds with information which will be used to configure the sections of a page on a website, based on the user-provided input. All text content should be as impressive and exciting as possible.",
  //         },
  //         { role: "user", content: newJSON },
  //       ];

  //       const chatCompletion = await generateChatCompletion16K(requestMessage);

  //       const messageData = chatCompletion.choices[0]?.message;

  //       console.log(messageData, "ini messageData");

  //       if (messageData.content) {
  //         // const htmlPrompt = `Create a high converting ${x.title} section in html format to be used in a sales page with data per below\n\n${messageData.content}\n\n make it simple, unique, short and persuasive with beautiful and interesting UI for return in optimized HTML inline CSS format. I provide the code for your reference and you should follow from this code <section>${x?.htmlTemplate}</section>`;
  //         const htmlPrompt = `Change the text content in this html inline css template ${messageData.content} code for a high converting copy for ${x.title} section ${prompt} to be used in a sales page. Please make it simple, unique, not too long sentence, straight to the point and persuasive with data per below. \n\n${str}\n\n Match this data with the ${prompt} section, (example: hero is for headline and subheadline, etc) If it doesnt match, make a new code for that section`;
  //         console.log(htmlPrompt, "ini htmlPrompt");
  //         // const htmlRequestMessage = [
  //         //   {
  //         //     role: "system",
  //         //     content:
  //         //       "You’re a site layout creator that responds with information which will be used to configure the sections of a page on a website, based on the user-provided input. All text content should be as impressive and exciting as possible.",
  //         //   },
  //         //   { role: "user", content: htmlPrompt },
  //         // ];

  //         // const chatCompletionWeb = await generateChatCompletion16K(
  //         //   htmlRequestMessage
  //         // );
  //         // const htmlData = chatCompletionWeb.choices[0]?.message;
  //         // const htmlContent = htmlData.content;

  //         // console.log(htmlContent);
  //         // setAiGenerator((prevState) => {
  //         //   const updatedGenerator = [...prevState];
  //         //   updatedGenerator[i] = { ...x, loading: false, htmlContent };
  //         //   return updatedGenerator;
  //         // });
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }

  //   setLoading(false);
  // };

  const handleAi = async () => {
    setLoading(true);
    console.log(aiGenerator, "ini generator");

    const results = [];

    const generateLandingpage = async (currentData) => {
      let str = "";
      for (const [p, val] of Object.entries(message)) {
        str += `${p.replace("_", " ")}:\n -${val}\n`;
      }

      console.log(str);

      // const newJSON = `I want you to Create a high converting copy for ${aiGenerator[7].title} section ${aiGenerator[2].title} to be used in a sales page. Please make it simple, unique, not too long sentence, straight to the point and persuasive with data per below\n\n${str}`;
      const newJSON = `Based on this text element in this code \n\n${currentData.htmlTemplate}\n\nCreate a high converting copy for landing page section {${currentData.title}} to be used in a sales page. Please make it simple, unique, not too long sentence, straight to the point and persuasive with data per below\n\n${str}\n\n. Please match the data with section in curly bracket. Use ${message.language} for the answer`;

      const requestMessage = [
        {
          role: "system",
          content:
            "You’re a site copywriter that responds with information which will be used to configure the sections of a page on a website, based on the user-provided input. All text content should be as impressive and exciting as possible.",
        },
        { role: "user", content: newJSON },
      ];

      try {
        const generateCopy = await generateChatCompletion16K(requestMessage);
        console.log(generateCopy.choices[0]?.message.content);

        // const newHtml = `I give you this reference code \n\n${aiGenerator[7]?.htmlTemplate}\n\n. Based on that code, I want you to follow the code and modify only a text element from ${aiGenerator[7]?.title} section ${aiGenerator[2]?.title} in html to be used in a sales page landing page. Please make it simple, unique, not too long sentence, straight to the point and persuasive with this data copy ${generateCopy.choices[0]?.message?.content}`;
        const htmlPrompt = `Create a high converting landing page ${currentData?.title} section in html format to be used in a sales page with this data copy \n\n${generateCopy.choices[0]?.message.content}\n\n make it simple, unique, short and persuasive with beautiful and interesting UI for return in optimized, responsive, desktop and mobile friendly HTML inline CSS format only. Analyze the text element form the code, and change the text element from the data copy to the provided code. For the content, Analyze what section is for the code that i provide, Suit and match every name of this data \n\n${str}\n\nwith the word that i mentioned above inside curly bracket \n\nDo not change the css style code that i provide. I provide the code for your reference and you should refer from this code. \n\n<section>${currentData?.htmlTemplate}</section>`;
        // const newJSON = `Create a high converting copy for ${x.title} section ${prompt} to be used in a sales page. Please make it simple, unique, not too long sentence, straight to the point and persuasive with data per below\n\n${str}`;

        const requestHtml = [
          {
            role: "system",
            content:
              "You’re a site layout creator that responds with information which will be used to configure the sections of a page on a website, based on the user-provided input. All text content should be as impressive and exciting as possible.",
          },
          { role: "user", content: htmlPrompt },
        ];

        const generateHtml = await generateChatCompletion16K(requestHtml);

        const roughCode = generateHtml.choices[0].message.content;
        const cleanCode = roughCode.replace(/\n/g, "");

        return { title: currentData.title, htmlContent: cleanCode };
      } catch (error) {
        console.log(error);
      }
    };

    const promises = aiGenerator.map(generateLandingpage);

    try {
      const generatedResults = await Promise.all(promises);
      results.push(...generatedResults.filter((result) => result !== null));

      setAnswerCode(results);
    } catch (error) {
      console.log(error, "error during promise.all");
    } finally {
      setLoading(false);
      console.log(results, "ini resultnya");
    }
  };

  console.log(answerCode);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === aiGenerator?.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? aiGenerator?.length - 1 : prevIndex - 1
    );
  };

  const handleSave = async () => {
    setLoadingSave(true);
    const collectionName = `funnels/${param.id}/page`;
    const docName = state.id;
    const data = {
      message: answerCode,
    };

    try {
      const result = await updateDocumentFirebase(
        collectionName,
        docName,
        data
      );

      setLoadingSave(false);

      toast({
        title: "Success",
        description: `Success save funnel.`,
        status: "success",
        duration: 10000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <Box>
      <HStack>
        <BackButtons />
        <Spacer />
        <Heading>
          {state.title} - {state.title_page}
        </Heading>
      </HStack>

      <Stack gap={5} py={5}>
        <Stack>
          <Heading size={"md"} pb={3}>
            Choose template :{" "}
          </Heading>
          <Button colorScheme="green" onClick={browseTemplateModal.onOpen}>
            Browse Template
          </Button>

          <Divider />

          <Box p={4} color="black">
            {aiGenerator?.length > 0 && (
              <Flex alignItems="center" justifyContent="center">
                <IconButton
                  icon={<ChevronLeftIcon />}
                  variant="ghost"
                  size="lg"
                  onClick={handlePrev}
                />
                <Stack alignItems={"center"} justifyContent="center">
                  <Text
                    color={"white"}
                    fontWeight={500}
                    textTransform="capitalize"
                  >
                    {aiGenerator[currentIndex]?.title}
                  </Text>
                  {aiGenerator[currentIndex]?.loading ? (
                    <Spinner />
                  ) : (
                    <Box>
                      {aiGenerator[currentIndex]?.htmlContent ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: aiGenerator[currentIndex].htmlContent,
                          }}
                          style={{ background: "white" }}
                        />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: aiGenerator[currentIndex]?.htmlTemplate,
                          }}
                          style={{ background: "white" }}
                        />
                      )}
                    </Box>
                  )}
                </Stack>
                <IconButton
                  icon={<ChevronRightIcon />}
                  variant="ghost"
                  size="lg"
                  onClick={handleNext}
                />
              </Flex>
            )}
          </Box>
        </Stack>

        <Stack m="2" p="2" shadow="base" spacing={2}>
          <Stack>
            <FormControl>
              <FormLabel>Header</FormLabel>
              <Input
                bgColor="white"
                color="black"
                sx={inputStyles}
                type="text"
                disabled={loading}
                placeholder="Your headline and subheadline"
                onChange={(e) =>
                  setMessage({ ...message, header: e.target.value })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Heroes</FormLabel>
              <Input
                bgColor="white"
                color="black"
                sx={inputStyles}
                type="text"
                disabled={loading}
                placeholder="Your headline and subheadline"
                onChange={(e) =>
                  setMessage({ ...message, heroes: e.target.value })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Value Propotition</FormLabel>
              <Textarea
                bgColor="white"
                color="black"
                sx={inputStyles}
                type="text"
                resize="none"
                onChange={(e) =>
                  setMessage({
                    ...message,
                    valueProposition: e.target.value,
                  })
                }
                placeholder={`Our unique program equips you with the in-demand skills of a full stack programmer, unlocking a world of lucrative opportunities. Join our comprehensive training and fast-track your career to earn an impressive annual income of $200k or more. Say goodbye to financial worries and embrace a future filled with financial stability, job satisfaction, and limitless growth potential. Don't just dream of success, make it a reality with our proven pathway to a high-earning future in the dynamic world of technology.`}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Pricing</FormLabel>
              <Textarea
                bgColor="white"
                color="black"
                sx={inputStyles}
                type="text"
                resize="none"
                onChange={(e) =>
                  setMessage({ ...message, pricing: e.target.value })
                }
                placeholder={`$300 package one , $500 package two, $1000 package three`}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Key Features</FormLabel>
              <Textarea
                bgColor="white"
                color="black"
                sx={inputStyles}
                type="text"
                resize="none"
                onChange={(e) =>
                  setMessage({ ...message, keyFeatures: e.target.value })
                }
                placeholder={`Financial Security \nLucrative Career Opportunities \nPrestige and Recognition`}
              />
            </FormControl>
            <FormControl>
              <FormLabel>FAQ</FormLabel>
              <Textarea
                bgColor="white"
                color="black"
                sx={inputStyles}
                type="text"
                resize="none"
                onChange={(e) =>
                  setMessage({ ...message, faq: e.target.value })
                }
                placeholder={`Stagnant Income \nCompetitive Job Market \nLimited Career Options`}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Testimony</FormLabel>
              <Textarea
                bgColor="white"
                color="black"
                sx={inputStyles}
                type="text"
                resize="none"
                onChange={(e) =>
                  setMessage({ ...message, testimony: e.target.value })
                }
                placeholder={`Alumni Success Stories:"Thanks to the program, I secured a position at a leading tech company and doubled my previous salary within six months. The support from the alumni community has been invaluable in my professional growth." - Sarah Johnson, Program Graduate.`}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Call To Action</FormLabel>
              <Textarea
                bgColor="white"
                color="black"
                sx={inputStyles}
                type="text"
                resize="none"
                onChange={(e) =>
                  setMessage({ ...message, cta: e.target.value })
                }
                placeholder={`Alumni Success Stories:"Thanks to the program, I secured a position at a leading tech company and doubled my previous salary within six months. The support from the alumni community has been invaluable in my professional growth." - Sarah Johnson, Program Graduate.`}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Footer</FormLabel>
              <Textarea
                bgColor="white"
                color="black"
                sx={inputStyles}
                type="text"
                resize="none"
                onChange={(e) =>
                  setMessage({ ...message, footer: e.target.value })
                }
                placeholder={`Navigations and Company Name`}
              />
            </FormControl>
          </Stack>

          <Stack>
            <FormControl>
              <FormLabel>Brand Color</FormLabel>
              <Input
                bgColor="white"
                color="black"
                sx={inputStyles}
                type="text"
                onChange={(e) =>
                  setMessage({ ...message, brand_color: e.target.value })
                }
                placeholder={`#ffd600`}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Language</FormLabel>
              <Select
                placeholder="Language"
                onChange={(e) =>
                  setMessage({ ...message, language: e.target.value })
                }
              >
                <option value="use english for page content">English</option>
                <option value="use bahasa indonesia for page content">
                  Indonesia
                </option>
              </Select>
            </FormControl>
            {loading === true ? (
              <Button isLoading mt="5" w="full" colorScheme="green">
                Generating landing page with AI
              </Button>
            ) : (
              <Button
                onClick={() => handleAi()}
                mt="5"
                w="full"
                colorScheme="green"
              >
                Generate landing page with AI
              </Button>
            )}
            {loadingSave === true ? (
              <Button
                isLoading
                onClick={() => handleSave()}
                mt="5"
                w="full"
                colorScheme="green"
              >
                Save
              </Button>
            ) : (
              <Button
                onClick={() => handleSave()}
                mt="5"
                w="full"
                colorScheme="green"
              >
                Save
              </Button>
            )}
            {/* <Button
              onClick={() => console.log(aiGenerator)}
              mt="5"
              w="full"
              colorScheme="green"
            >
              Console
            </Button> */}
          </Stack>
        </Stack>
      </Stack>

      {answerCode.length > 0 ? (
        <>
          {answerCode.map((x, i) => (
            <Box key={i}>
              <div
                dangerouslySetInnerHTML={{
                  __html: x?.htmlContent,
                }}
                style={{ background: "white" }}
              />
            </Box>
          ))}
        </>
      ) : null}

      <Modal
        size={"2xl"}
        isOpen={browseTemplateModal.isOpen}
        onClose={browseTemplateModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Select Templates</Text>
          </ModalHeader>
          <ModalBody>
            {tamplatesData?.length > 0 && (
              <SimpleGrid
                columns={[2, null, 4]}
                spacing={5}
                h={400}
                overflowY={"auto"}
              >
                {tamplatesData?.map((x, index) => {
                  return (
                    <Stack
                      _hover={{
                        transform: "scale(1.03)",
                        shadow: "3xl",
                      }}
                      transition={"0.2s ease-in-out"}
                      spacing={2}
                      borderTopWidth={3}
                      borderColor="green.500"
                      alignItems="center"
                      justifyContent="start"
                      bgColor="#2B2B2B"
                      key={index}
                      py={4}
                      px={2}
                      borderRadius="md"
                      shadow="md"
                      onClick={() => handleTemplate(x)}
                      // Tambahkan properti disabled jika id kosong
                      disabled={!x.id}
                      opacity={!x.id ? 0.5 : 1}
                      cursor={!x.id ? "not-allowed" : "pointer"}
                    >
                      <Image src={x?.media} width="80px" borderRadius={"md"} />
                      <Text
                        textAlign={"center"}
                        textTransform={"capitalize"}
                        noOfLines={1}
                      >
                        {x?.title}
                      </Text>
                    </Stack>
                  );
                })}
              </SimpleGrid>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex gap={5}>
              {/* <Button
                // leftIcon={<AddIcon boxSize={3} />}
                size={"sm"}
                colorScheme="green"
                onClick={() => {
                  navigate("/create-domain");
                }}
              >
                Choose Template
              </Button> */}
              <Button
                leftIcon={<CloseIcon boxSize={3} />}
                size={"sm"}
                colorScheme="red"
                onClick={browseTemplateModal.onClose}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* <Stack bgColor={'white'} color='black'>
        <Box>
          {aiGenerator.length > 0 && (
            <Stack >
              {aiGenerator.map((x, index) => (
                <Stack key={index}>
                  <Text>{x.title}</Text>
                  {x.loading ? (
                    <Stack>
                      <Spinner />
                    </Stack>
                  ) : (
                    <Stack>
                      {x.htmlContent ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: x.htmlContent }}
                          style={{ background: "white" }}
                        />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: x.htmlTemplate }}
                          style={{ background: "white" }}
                        />
                      )}
                    </Stack>
                  )}
                </Stack>
              ))}
            </Stack>
          )}
        </Box>
      </Stack> */}
    </Box>
  );
}

export default ExampleCreate;

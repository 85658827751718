import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  Input,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FcPlus } from "react-icons/fc";
import { getCollectionFirebase } from "../../Apis/firebaseApi";
import {
  TiEdit,
  TiFeather,
  TiHeadphones,
  TiMessageTyping,
  TiMessages,
  TiNews,
  TiPuzzleOutline,
  TiSortAlphabeticallyOutline,
  TiStarFullOutline,
  TiThumbsOk,
  TiWaves,
} from "react-icons/ti";
import { useNavigate } from "react-router-dom";

const TemplateTextPage = () => {
  const navigate = useNavigate();
  const [templates, setTemplates] = useState();
  const [searchedData, setSearchedData] = useState();
  const [inputSearchedTemplate, setInputSearchTemplate] = useState("");

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  const getDataTemplate = async () => {
    const conditions = [
      {
        field: "companyId",
        operator: "==",
        value: "8NCG4Qw0xVbNR6JCcJw1",
      },
      {
        field: "type",
        operator: "==",
        value: "openai",
      },
    ];
    // const sortBy = { field: "name", direction: "desc" };
    // const limitValue = 10;

    try {
      const res = await getCollectionFirebase("templates", conditions);

      setTemplates(res);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const searchTemplate = (text) => {
    if (text) {
      const newData = templates?.filter((item) => {
        const itemData = item.title
          ? item.title.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setSearchedData(newData);
      setInputSearchTemplate(text);
    } else {
      setSearchedData(templates);
      setInputSearchTemplate(text);
    }
  };

  useEffect(() => {
    getDataTemplate();

    return () => {};
  }, []);
  return (
    <Box>
      <HStack my={5}>
        <Heading>Text Templates Collection</Heading>
        <Spacer />
        <Button
          colorScheme="green"
          variant="outline"
          onClick={() => navigate(`/template-web/create`)}
          // onClick={handleNewTemplate}
        >
          <HStack spacing={2}>
            <FcPlus />
            <Text textTransform="uppercase" fontSize="sm" fontWeight={500}>
              New Template
            </Text>
          </HStack>
        </Button>
      </HStack>

      <Input
        type="text"
        placeholder="Search Files ..."
        bgColor="white"
        color="black"
        sx={inputStyles}
        fontSize="sm"
        onChange={(e) => searchTemplate(e.target.value)}
      />

      <SimpleGrid columns={4} spacing={4} my={8}>
        {inputSearchedTemplate === "" ? (
          <>
            {templates?.map((x, i) => {
              return (
                <Stack
                  _hover={{
                    transform: "scale(1.03)",
                    shadow: "xl",
                  }}
                  transition={"0.2s ease-in-out"}
                  spacing={2}
                  borderTopWidth={3}
                  borderColor="green.500"
                  alignItems="center"
                  justifyContent="start"
                  bgColor="#2B2B2B"
                  key={i}
                  py={4}
                  px={2}
                  borderRadius="md"
                  shadow="md"
                  onClick={() => {
                    if (x.id) {
                      navigate(`/create-aissistant/instagram/${x.title}`);
                    }
                  }}
                  // Tambahkan properti disabled jika id kosong
                  disabled={!x.id}
                  opacity={!x.id ? 0.5 : 1}
                  cursor={!x.id ? "not-allowed" : "pointer"}
                >
                  <Stack p={5}>
                    <Icon
                      as={
                        x.title === "funfact"
                          ? TiPuzzleOutline
                          : x.title === "headline"
                          ? TiSortAlphabeticallyOutline
                          : x.title === "review"
                          ? TiThumbsOk
                          : x.title === "story"
                          ? TiMessageTyping
                          : x.title === "podcast"
                          ? TiMessages
                          : x.title === "motivation"
                          ? TiStarFullOutline
                          : x.title === "comparing"
                          ? TiWaves
                          : x.title === "tutorial"
                          ? TiNews
                          : x.title === "caption"
                          ? TiEdit
                          : x.title === "quiz"
                          ? TiHeadphones
                          : TiFeather
                      }
                      color={x.iconColor}
                      boxSize={12}
                      alignSelf="center"
                    />
                  </Stack>
                  <Box align={"center"}>
                    <Text fontWeight="bold" textTransform={"capitalize"}>
                      {x.title}
                    </Text>
                    <Text fontSize={12}>{x.description}</Text>
                  </Box>
                </Stack>
              );
            })}
          </>
        ) : (
          <>
            {searchedData?.map((x, i) => {
              return (
                <Stack
                  _hover={{
                    transform: "scale(1.03)",
                    shadow: "xl",
                  }}
                  transition={"0.2s ease-in-out"}
                  spacing={2}
                  borderTopWidth={3}
                  borderColor="green.500"
                  alignItems="center"
                  justifyContent="start"
                  bgColor="#2B2B2B"
                  key={i}
                  py={4}
                  px={2}
                  borderRadius="md"
                  shadow="md"
                  onClick={() => {
                    if (x.id) {
                      navigate(`/create-aissistant/instagram/${x.title}`);
                    }
                  }}
                  // Tambahkan properti disabled jika id kosong
                  disabled={!x.id}
                  opacity={!x.id ? 0.5 : 1}
                  cursor={!x.id ? "not-allowed" : "pointer"}
                >
                  <Stack p={5}>
                    <Icon
                      as={
                        x.title === "funfact"
                          ? TiPuzzleOutline
                          : x.title === "headline"
                          ? TiSortAlphabeticallyOutline
                          : x.title === "review"
                          ? TiThumbsOk
                          : x.title === "story"
                          ? TiMessageTyping
                          : x.title === "podcast"
                          ? TiMessages
                          : x.title === "motivation"
                          ? TiStarFullOutline
                          : x.title === "comparing"
                          ? TiWaves
                          : x.title === "tutorial"
                          ? TiNews
                          : x.title === "caption"
                          ? TiEdit
                          : x.title === "quiz"
                          ? TiHeadphones
                          : TiFeather
                      }
                      color={x.iconColor}
                      boxSize={12}
                      alignSelf="center"
                    />
                  </Stack>
                  <Box align={"center"}>
                    <Text fontWeight="bold" textTransform={"capitalize"}>
                      {x.title}
                    </Text>
                    <Text fontSize={12}>{x.description}</Text>
                  </Box>
                </Stack>
              );
            })}
          </>
        )}
      </SimpleGrid>
    </Box>
  );
};

export default TemplateTextPage;

import {
  AspectRatio,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Textarea,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  List,
  ListItem,
  Icon,
  Select,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FcPlus } from "react-icons/fc";
import { generateChatCompletion } from "../../Apis/openAi";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  creatomateGetVideo,
  creatomateRenderApi,
  creatomateRenderApiWithAudio,
  pixabayVideoApi,
} from "../../Apis/videoApi";
import VideosSlider from "../../Components/Videos/VideosSlider";
import { CloseIcon } from "@chakra-ui/icons";
import UploadFileToDropbox from "../../Components/Dropbox/UploadVideoToDropbox";
import {
  addDocumentFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from "../../Apis/firebaseApi";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import { TiDelete, TiVideo } from "react-icons/ti";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import BackButtons from "../../Components/Button/BackButtons";
import { decryptToken } from "../../Utils/encryptToken";
import { unblockVideo } from "../../Apis/videoUnblock";

function VideoTextToVideo() {
  const toast = useToast();
  const navigate = useNavigate();
  const [loadingSave, setLoadingSave] = useState(false);
  const [ratio, setRatio] = useState({});
  const [templates, setTemplates] = useState();
  const [saveData, setSaveData] = useState();
  const [videoId, setVideoId] = useState();
  const [file, setFile] = useState();
  const [data, setData] = useState({});
  const [fileAudio, setFileAudio] = useState();
  const [audioName, setAudioName] = useState();
  const [audio, setAudio] = useState();
  const [dataVideo, setDataVideo] = useState();
  const [pickVideo, setPickVideo] = useState([]);
  const [name, setName] = useState();
  const [renderedStatus, setRenderedStatus] = useState();
  const [resultStatus, setResultStatus] = useState();
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [loadingGPT, setLoadingGPT] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [isRendering, setIsRendering] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fileName, setFileName] = useState();
  const globalState = UseAuthStore();
  const {
    isOpen: isOpenUploadModal,
    onOpen: onOpenUploadModal,
    onClose: onCloseUploadModal,
  } = useDisclosure();

  const {
    isOpen: isOpenSaveModal,
    onOpen: onOpenSaveModal,
    onClose: onCloseSaveModal,
  } = useDisclosure();

  const audioModal = useDisclosure();

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  const runPrompt = () => {
    setLoadingGPT(true);
    const newArr = [
      {
        role: "user",
        content:
          //     `I want you to only respond and answer what i request. Straight to the point. DONT ANSWER WITH
          // the friendly sentence like 'Here is the result' or anythying smiliar like that.
          // If i want a json, respond only with json answer with the given text data. \n
          // return in array of object for each segment \n
          // dont forget to use this format as your reference [{text:text, nouns:nouns}]. \n
          // if the text that i request contain a single or double quotation in example like "i'm", spread it out become i am.
          // add the best nouns from each segment inside the json, if the nouns are not english, translate to english \n
          // my request is split this data text into 5 seconds videos \n
          // ${data.text}`,
          `split this text data into a segment for each sentence \n
				${data.text} \n
				return in json format ONLY \n
				format the split result as key 'text' \n
				add one best nouns with only one word that described the sentence topic from each segment inside the json and make sure that nouns are in english \n
				if the nouns are not english, translate to english \n
				use this format as your reference [{text:text, nouns:nouns}]`,
      },
    ];
    generateChatCompletion(newArr)
      .then((x) => {
        const newJson = JSON.parse(x.choices[0]?.message.content);

        setDataVideo(newJson);
        setLoadingGPT(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoadingGPT(false);
      });
  };

  const getVideoTemplate = async () => {
    const conditions = [{ field: "type", operator: "==", value: "video" }, ,];

    try {
      const res = await getCollectionFirebase("templates", conditions);

      setTemplates(res);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const handleRender = async () => {
    if (pickVideo?.length !== 0) {
      setIsRendering(true);
      setIsVideoLoading(true);

      console.log(audio);

      try {
        console.log(ratio);
        if (audio === undefined) {
          const res = await creatomateRenderApi(pickVideo, ratio);
          // console.log(res);
          setRenderedStatus(res.data[0]);

          toast({
            status: "success",
            title: "Deoapp AI",
            description: "Your video is being rendered, please wait",
            duration: 3000,
          });
        } else {
          const res = await creatomateRenderApiWithAudio(
            pickVideo,
            audio?.link?.link,
            ratio
          );
          // console.log(res);
          setRenderedStatus(res.data[0]);
          setIsRendering(false);

          toast({
            status: "success",
            title: "Deoapp AI",
            description: "Your video is being rendered, please wait",
            duration: 3000,
          });
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsRendering(false);
      }
    } else {
      toast({
        status: "success",
        title: "Deoapp AI",
        description: "Your video is being rendered, please wait",
        duration: 3000,
      });
    }
  };

  const checkDataVideo = async () => {
    setIsChecking(true);
    if (pickVideo?.length !== 0) {
      setIsChecking(true);
      try {
        const resultVideo = await creatomateGetVideo(renderedStatus.id);

        setResultStatus(resultVideo?.data);

        if (resultStatus?.status !== "succeed") {
          toast({
            status: "success",
            title: "Deoapp AI",
            description: "Your video is being rendered, please wait",
            duration: 3000,
          });
        } else {
          setIsVideoLoading(false);
        }
        console.log(resultStatus);
        setIsChecking(false);
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsChecking(false);
      }
    } else {
      setIsChecking(false);
      toast({
        status: "success",
        title: "Deoapp AI",
        description: "choose the video first",
        duration: 3000,
      });
    }
  };

  const handleOpenSaveModal = (dataResult) => {
    onOpenSaveModal();
    setSaveData(dataResult);
  };

  const handleAddCollection = async () => {
    setLoadingSave(true);
    try {
      const docID = await addDocumentFirebase(
        "files",
        {
          type: "video_assets",
          category: "video_results",
          createdBy: globalState.uid,
          projectId: globalState.currentProject,
          link: saveData?.url,
          name: name,
          videoId: saveData.id,
        },
        globalState.currentCompany
      );

      toast({
        status: "success",
        title: "Deoapp AI",
        description: "Video saved to collection",
        duration: 2000,
      });

      setVideoId(docID);

      onCloseSaveModal();
      window.open(saveData?.url, "_blank");

      console.log("ID Dokumen Baru:", docID);
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
      toast({
        status: "error",
        title: "Deoapp AI",
        description: `${error}`,
        duration: 2000,
      });
    }
    setLoadingSave(false);
  };

  const handleEdit = async () => {
    const getData = await getSingleDocumentFirebase("files", videoId);
    console.log(getData);

    navigate(`/video/text2video/edit/${getData.id}`);
  };

  const handleUploadVideo = async () => {
    setIsUploading(true);

    try {
      const token = await getSingleDocumentFirebase("token", "dropbox");

      const decryptResult = decryptToken(`${token?.access_token}`);

      const parentPath = `/${globalState.companies[0]?.name}/AI`; // Ganti dengan path folder di Dropbox yang ingin Anda gunakan
      const dropboxLink = await UploadFileToDropbox(
        file,
        parentPath,
        decryptResult
      );

      pickVideo.push({ url: dropboxLink?.link });
      setPickVideo(pickVideo);

      const addToFirestore = await addDocumentFirebase(
        "files",
        {
          type: "video",
          path: parentPath,
          category: "video_assets",
          link: dropboxLink?.link,
          projectId: globalState.currentProject,
          name: fileName,
        },
        globalState.currentCompany
      );

      toast({
        title: "Deoapp AI",
        description: "Upload Success",
        duration: 2000,
        status: "success",
      });

      onCloseUploadModal();

      setIsUploading(false);
    } catch (error) {
      console.log(error);
      toast({
        title: "Deoapp AI",
        description: "Upload Failed",
        duration: 2000,
        status: "error",
      });
    }
  };

  const handleUploadAudio = async () => {
    const token = await getSingleDocumentFirebase("token", "dropbox");

    const decryptResult = decryptToken(`${token?.access_token}`);

    const parentPath = `/${globalState.companies[0]?.name}/AI`; // Ganti dengan path folder di Dropbox yang ingin Anda gunakan
    const dropboxLink = await UploadFileToDropbox(
      fileAudio,
      parentPath,
      decryptResult
    );

    console.log(dropboxLink);

    setAudio({ name: audioName, link: dropboxLink });

    console.log(audio);

    const addToFirestore = await addDocumentFirebase(
      "files",
      {
        type: "audio_assets",
        path: parentPath,
        category: "audio",
        link: dropboxLink?.link,
        name: audioName,
      },
      globalState.currentCompany
    );

    console.log(addToFirestore);
  };

  const handleAspectRatioChange = (event) => {
    const selectedOption = event.target.value;
    switch (selectedOption) {
      case "Standard Definition (640 x 480)":
        setRatio({ width: "640", height: "480" });
        break;
      case "High Definition Landscape (1280 x 720)":
        setRatio({ width: "1280", height: "720" });
        break;
      case "Full HD Landscape (1920 x 1080)":
        setRatio({ width: "1920", height: "1080" });
        break;
      case "High Definition Portrait (720 x 1280)":
        setRatio({ width: "720", height: "1280" });
        break;
      case "Full HD Portrait (1080 x 1920)":
        setRatio({ width: "1080", height: "1920" });
        break;
      case "Square (1080 x 1080)":
        setRatio({ width: "1080", height: "1080" });
        break;
      default:
        // Set default aspect ratio here if needed
        break;
    }
  };

  const handleRemoveVideo = (data) => {
    const index = pickVideo.findIndex((obj) => obj?.url === data?.url);
    if (index !== -1) {
      pickVideo.splice(index, 1);
      setSelectedVideo([...pickVideo]); // Ensure state update after mutation
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(pickVideo);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPickVideo(items);
  };

  useEffect(() => {
    getVideoTemplate();
    // testing();
  }, []);

  return (
    <Stack>
      <BackButtons />
      <HStack my={5}>
        <Heading>Text to Video</Heading>
        <Spacer />
      </HStack>

      <Stack spacing="5">
        <Box p="2">
          <FormControl>
            <FormLabel>Your Video Description</FormLabel>
            <Textarea
              h={150}
              bgColor="white"
              color="black"
              sx={inputStyles}
              placeholder="Describe what do you want in the video or write your script content here. Ex: Replying to thousands of emails can be a very troublesome job. What else if our message box is full in the middle of a pile of other work. But don't worry! now there is an AI deoapp that can help you in compiling emails that must be replied to."
              size="sm"
              onChange={(e) => setData({ ...data, text: e.target.value })}
            />
          </FormControl>

          <HStack my={3}>
            <FormLabel>Video Size</FormLabel>
            <Select w={"fit-content"} onChange={handleAspectRatioChange}>
              <option>Standard Definition {"(640 x 480)"}</option>
              <option>Square {"(1080 x 1080)"}</option>
              <option>High Definition Landscape {"(1280 x 720)"}</option>
              <option>High Definition Portrait {"(720 x 1280)"}</option>
              <option>Full HD Landscape {"(1920 x 1080)"}</option>
              <option>Full HD Portrait {"(1080 x 1920)"}</option>
            </Select>
          </HStack>

          {loadingGPT === true ? (
            <Center>
              <Button colorScheme="green" w={300} isLoading>
                Generate video
              </Button>
            </Center>
          ) : (
            <Center>
              <Button
                mt="2"
                p="2"
                w={300}
                colorScheme="green"
                onClick={() => runPrompt()}
              >
                Generate video
              </Button>
            </Center>
          )}
        </Box>

        <Flex gap={5}>
          <Stack
            w={"50%"}
            p="5"
            height="50vh"
            overflowY={"auto"}
            border={"1px"}
            my={3}
            borderRadius={"md"}
            bg={"blackAlpha.400"}
            sx={{
              "&::-webkit-scrollbar": {
                w: "2",
                h: "1",
              },
              "&::-webkit-scrollbar-track": {
                w: "6",
                h: "3",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10",
                bg: `gray.600`,
              },
            }}
          >
            <Text align={"center"}>Video Preview</Text>
            <Text align={"center"}>
              Choose the video that fit to your content
            </Text>
            {dataVideo?.length > 0 ? (
              <>
                {dataVideo?.map((x, i) => (
                  <>
                    <Box key={i}>
                      <Stack spacing={1} mb={4}>
                        <Text fontWeight={"semibold"}>Segmen {i + 1}</Text>
                        <Text>{x?.text}</Text>
                        <VideosSlider
                          key={i}
                          data={x?.nouns}
                          index={i}
                          pickVideo={pickVideo}
                          setPickVideo={setPickVideo}
                        />
                      </Stack>
                    </Box>
                  </>
                ))}
              </>
            ) : (
              <Center>
                <Text>
                  The Results of the video based on your text input will be
                  displayed here.
                </Text>
              </Center>
            )}
          </Stack>

          <Stack
            align={"center"}
            spacing={4}
            p="5"
            w={"50%"}
            height="50vh"
            overflowY={"auto"}
            border={"1px"}
            my={3}
            borderRadius={"md"}
            bg={"blackAlpha.400"}
          >
            <Text align={"center"}>Selected Video</Text>
            {pickVideo.length > 0 ? (
              <>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="videolist">
                    {(provided) => (
                      <ul
                        style={{ listStyle: "none" }}
                        className="videolist"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {pickVideo.map((x, i) => (
                          <>
                            <Draggable
                              key={x?.url}
                              draggableId={x?.url}
                              index={i}
                            >
                              {(provided) => (
                                <li
                                  style={{
                                    border: "1px",
                                    borderRadius: "md",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <Text>Video for segment {i + 1}</Text>
                                  <Box mt={2}>
                                    <Box>
                                      <iframe
                                        title="video url"
                                        src={x?.url}
                                        allowFullScreen
                                      />
                                    </Box>
                                  </Box>
                                </li>
                              )}
                            </Draggable>
                            <HStack
                              cursor={"pointer"}
                              onClick={() => handleRemoveVideo(x)}
                              mb={4}
                            >
                              <Icon as={TiDelete} cursor={"pointer"} />
                              <Text fontSize={"sm"}>Delete</Text>
                            </HStack>
                          </>
                        ))}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>

                {audio !== undefined ? (
                  <>
                    <Text>Your Audio</Text>
                    <HStack
                      p={2}
                      border={"1px"}
                      onClick={() => console.log(audio.link.link)}
                    >
                      <Text bg={"blackAlpha.200"} px={2} border={"1px"}>
                        Audio Name
                      </Text>
                      <Text>{audio.name}</Text>
                    </HStack>
                  </>
                ) : null}

                <Center>
                  {isRendering === true ? (
                    <Stack>
                      <Button
                        isLoading
                        size="sm"
                        w={200}
                        onClick={() => handleRender()}
                        colorScheme="green"
                      >
                        Render
                      </Button>
                      <Button size="sm" w={200} colorScheme="green">
                        Upload From Your Storage
                      </Button>
                      <Button size="sm" w={200} colorScheme="green">
                        Upload Audio
                      </Button>
                    </Stack>
                  ) : (
                    <Stack>
                      {resultStatus && resultStatus.status === "succeeded" ? (
                        <Button
                          size="sm"
                          w={200}
                          onClick={() => handleRender()}
                          colorScheme="green"
                        >
                          Render
                        </Button>
                      ) : (
                        <Button
                          size="sm"
                          w={200}
                          onClick={() => handleRender()}
                          colorScheme="green"
                        >
                          Render
                        </Button>
                      )}
                      <Button
                        size="sm"
                        w={200}
                        onClick={onOpenUploadModal}
                        colorScheme="green"
                      >
                        Upload From Your Storage
                      </Button>
                      <Button
                        size="sm"
                        w={200}
                        onClick={audioModal.onOpen}
                        // onClick={() => handleRender()}
                        colorScheme="green"
                      >
                        Upload Audio
                      </Button>
                    </Stack>
                  )}
                </Center>
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Flex>
      </Stack>

      <Box border={"1px"} p={5} mb={3}>
        <Center>
          {isChecking === true ? (
            <Button
              isLoading
              mt="2"
              p="2"
              size={"sm"}
              w={200}
              colorScheme="red"
              onClick={() => checkDataVideo()}
            >
              Check Video Result
            </Button>
          ) : (
            <>
              <Button
                mt="2"
                p="2"
                size={"sm"}
                w={200}
                colorScheme="red"
                onClick={() => checkDataVideo()}
              >
                Check Video Result
              </Button>
            </>
          )}
        </Center>

        {resultStatus?.status === "succeeded" ? (
          <>
            <Text fontWeight={"semibold"} align={"center"}>
              Results
            </Text>
            <Box>
              <AspectRatio>
                <iframe src={resultStatus?.url} />
              </AspectRatio>
            </Box>
            {/* <HStack align={"center"} mt={5}>
              <Button
                colorScheme="green"
                onClick={() => handleOpenSaveModal(resultStatus)}
              >
                Save To Collection
              </Button>
              {videoId === undefined ? (
                <Button isDisabled colorScheme="green">
                  Edit Your Video
                </Button>
              ) : (
                <Button colorScheme="green" onClick={handleEdit}>
                  Edit Your Video
                </Button>
              )}
            </HStack> */}
          </>
        ) : (
          <>
            {isVideoLoading === true ? (
              <Center mt={3}>
                <Stack>
                  <Loading />
                  <Text mt={5}>
                    Click Check Video Result Button If it's too long to respond
                  </Text>
                </Stack>
              </Center>
            ) : (
              <Center mt={3}>
                <Text>Click the button to see results</Text>
              </Center>
            )}
          </>
        )}
      </Box>

      <Modal isOpen={isOpenUploadModal} onClose={onCloseUploadModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Your Video Here</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Video Name</FormLabel>
              <Input
                placeholder="Your Video Name"
                onChange={(e) => setFileName(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Video File</FormLabel>
              <Input type="file" onChange={(e) => setFile(e.target.files)} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button size={"sm"} colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            {isUploading === true ? (
              <Button
                isLoading
                size={"sm"}
                colorScheme="green"
                onClick={handleUploadVideo}
              >
                Upload
              </Button>
            ) : (
              <Button
                size={"sm"}
                colorScheme="green"
                onClick={handleUploadVideo}
              >
                Upload
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={audioModal.isOpen} onClose={audioModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Your Audio Here</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Audio Name</FormLabel>
              <Input
                placeholder="Your Audio Name"
                onChange={(e) => setAudioName(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Audio File</FormLabel>
              <Input
                type="file"
                onChange={(e) => setFileAudio(e.target.files)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              size={"sm"}
              colorScheme="blue"
              mr={3}
              onClick={audioModal.onClose}
            >
              Close
            </Button>
            <Button size={"sm"} colorScheme="green" onClick={handleUploadAudio}>
              Upload
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenSaveModal} onClose={onCloseSaveModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Save Your Video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Video Name</FormLabel>
              <Input
                placeholder="Your Video Name"
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              size={"sm"}
              colorScheme="blue"
              mr={3}
              onClick={onCloseSaveModal}
            >
              Close
            </Button>
            {loadingSave === true ? (
              <Button
                isLoading
                size={"sm"}
                colorScheme="green"
                onClick={() => handleAddCollection()}
              >
                Save
              </Button>
            ) : (
              <Button
                size={"sm"}
                colorScheme="green"
                onClick={() => handleAddCollection()}
              >
                Save
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default VideoTextToVideo;

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import {
  addDocumentFirebase,
  setDocumentFirebase,
} from "../../Apis/firebaseApi";
import { data } from "../../Components/Sidebar/DataMenu";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import BackButtons from "../../Components/Button/BackButtons";

const LandingPageTrainingCreate = () => {
  const toast = useToast();
  const param = useParams();
  const navigate = useNavigate();
  const globalState = UseAuthStore();
  const [data, setData] = useState();
  const [content, setContent] = useState();

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  const addTemplate = async () => {
    // const collectionName = `templates/tmGwKieqFWt7ura1LvU4/prompt`;
    const dataTemplate = {
      code: content,
      title: data.title,
    };

    try {
      const docID = await addDocumentFirebase(
        `templates/${param.id}/prompt`,
        dataTemplate,
        globalState.currentCompany
      );
      console.log("ID Dokumen Baru:", docID);
      toast({
        status: "success",
        title: "Deoapp AI",
        description: "Template Created",
        duration: 3000,
      });

      const resultUpdate = await addDocumentFirebase(
        "logs",
        {
          activity: `add template train ai for landing page`,
          uid: globalState.uid,
          projectId: globalState.currentProject,
          details: {
            code: content,
            title: data.title,
          },
        },
        globalState.currentCompany
      );
      console.log(resultUpdate, "logs updated");

      navigate(`/lptrain/${param.id}`);
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
      toast({
        status: "error",
        title: "Deoapp AI",
        description: "Failed to create",
        duration: 3000,
      });
    }
  };

  return (
    <Box>
      <BackButtons />

      <Heading>Create Template</Heading>
      <Flex my={4} gap={5}>
        <Stack w={"50%"}>
          <FormControl>
            <FormLabel>Title</FormLabel>
            <Input
              bgColor="white"
              color="black"
              sx={inputStyles}
              onChange={(e) => setData({ ...data, title: e.target.value })}
              placeholder={`Section category. Ex: Hero, Pricing, etc`}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Prompt Template</FormLabel>
            <Textarea
              bgColor="white"
              color="black"
              sx={inputStyles}
              h={400}
              onChange={(e) => setContent(e.target.value)}
              placeholder={`Your html template`}
            />
          </FormControl>

          <Button colorScheme="green" onClick={addTemplate}>
            Save Template
          </Button>
        </Stack>
        <Stack w={"50%"} bg={"white"}>
          <Text>HTML Results</Text>
          <Box>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Box>
        </Stack>
      </Flex>
    </Box>
  );
};

export default LandingPageTrainingCreate;

import { LockIcon } from '@chakra-ui/icons';
import { Box, Divider, Heading, HStack, Icon, SimpleGrid, Spacer, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BackButtons from '../../Components/Button/BackButtons';
import { aissistantTemplates } from './aisistantDataTemplate';

function AimarketerCreatePage() {
  const navigate = useNavigate();


  return (
    <Stack p={[1, 1, 5]} spacing={4}>
      <HStack>
        <BackButtons />
        <Spacer />
        <Heading>Aimarketer</Heading>
      </HStack>
      <Divider />
      <SimpleGrid my={3} columns="3" gap="3">
        {aissistantTemplates.map((x, i) => {

          return (
            <HStack
              _hover={{
                transform: 'scale(1.05)',
                shadow: 'xl',
              }}
              transition={'0.2s ease-in-out'}
              spacing={2}
              borderTopWidth={3}
              borderColor="green.500"
              alignItems="center"
              justifyContent="start"
              bgColor="#2B2B2B"
              key={i}
              py={4}
              px={2}
              borderRadius="md"
              shadow="md"
              onClick={() => {
                if (x.id) {
                  navigate(x.name.toLowerCase());
                }
              }}
              // Tambahkan properti disabled jika id kosong
              disabled={!x.id}
              opacity={!x.id ? 0.5 : 1}
              cursor={!x.id ? 'not-allowed' : 'pointer'}
            >
              <Stack p={5}>
                <Icon as={x.id ? x.icon : LockIcon} color={x.iconColor} boxSize={12} alignSelf="center" />
              </Stack>
              <Box>
                <Text fontWeight="bold">{x.name}</Text>
                <Text>{x.description}</Text>
              </Box>
            </HStack>
          );
        })}
      </SimpleGrid>
    </Stack>
  );
}

export default AimarketerCreatePage;
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  addDocumentFirebase,
  updateDocumentFirebase,
} from "../../../Apis/firebaseApi";
import {
  generateChatCompletion,
  generateChatCompletionLandingPage,
  openai,
} from "../../../Apis/openAi";
import BackButtons from "../../../Components/Button/BackButtons";
import { UseAuthStore } from "../../../Hooks/Zustand/store";
import Loading from "../../../Components/Loading/Loading";
import { pixabayImageApi } from "../../../Apis/videoApi";
import axios from "axios";

function FunnelCreatePageV2() {
  const [message, setMessage] = useState({});
  const [urlImage, setUrlImage] = useState("");

  const param = useParams();

  const globalState = UseAuthStore();

  const toast = useToast({
    position: "top",
    align: "center",
  });

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  const { state } = useLocation();

  console.log(state);

  const [loading, setLoading] = useState(false);

  const [aiGenerator, setAiGenerator] = useState([
    { title: "Hero", loading: false, htmlContent: null },
    { title: "Key Features", loading: false, htmlContent: null },
    // { title: "Tech Specs", loading: false, htmlContent: null },
    { title: "Testimonials", loading: false, htmlContent: null },
    { title: "FAQ", loading: false, htmlContent: null },
    { title: "CTA", loading: false, htmlContent: null },
    { title: "Footer", loading: false, htmlContent: null },
  ]);

  // const handleAi = async () => {
  //   setLoading(true);

  //   try {
  //     for (let i = 0; i < aiGenerator.length; i++) {
  //       const x = aiGenerator[i];
  //       setAiGenerator((prevState) => {
  //         const updatedGenerator = [...prevState];
  //         updatedGenerator[i] = { ...x, loading: true };
  //         return updatedGenerator;
  //       });

  //       var str = "";
  //       for (const [p, val] of Object.entries(message)) {
  //         str += `${p.replace("_", " ")}:\n -${val}\n`;
  //       }

  //       let prompt = "";
  //       if (x.title === "Hero") prompt = "Headline and subheadline";
  //       else if (x.title === "Key Features")
  //         prompt = "3 to 6 features and or benefits";
  //       // else if (x.title === "Tech Specs") prompt = "3 to 6 technical specs";
  //       else if (x.title === "Testimonials") prompt = "3 to 6 Testimonials";
  //       else if (x.title === "FAQ") prompt = "3 to 6 FAQs";
  //       else if (x.title === "CTA") prompt = "Call to actions";
  //       else if (x.title === "Footer") prompt = "company name";

  //       // const newJSON = `Create a high converting copy for ${x.title} section ${prompt} to be used in a sales page. Please make it simple, unique, short and persuasive with data per below\n\n${str}`;
  //       const newJSON = `Create a high converting copy for ${x.title} section ${prompt} to be used in a sales page. Only answer and make the content with the data given, do not enter words or sentences that have nothing to do with the topic given. Please make it with interesting design UI, unique, short and persuasive with data per below\n\n${str}`;

  //       const requestMessage = [
  //         {
  //           role: "system",
  //           content:
  //             "You’re a site layout creator that responds with information which will be used to configure the sections of a page on a website, based on the user-provided input. All content should be as impressive and exciting as possible. You can only respond with valid JavaScript objects or arrays. Do not respond with any other text or formatting around the JavaScript, you must only respond with raw JavaScript. The current date is Wednesday, July 26, 2023.",
  //         },
  //         { role: "user", content: newJSON },
  //       ];

  //       const chatCompletion = await generateChatCompletion(requestMessage);
  //       const messageData = chatCompletion.choices[0].message;

  //       if (messageData.content) {
  //         const newJSON = `Create a high converting ${x.title} section in html format to be used in a sales page with data per below\n\n${messageData.content}\n\n Only answer and make the content with the data given, do not enter words or sentences that have nothing to do with the topic given. Make the sentence to the point and target the audience. Focus on content and the layout design. Make it simple, unique, short and persuasive for return in HTML inline CSS format <section> your result </section>`;
  //         const requestMessage = [
  //           {
  //             role: "system",
  //             content:
  //               "You’re a site layout creator that responds with information which will be used to configure the sections of a page on a website, based on the user-provided input. All content should be as impressive and exciting as possible. You can only respond with valid JavaScript objects or arrays. Do not respond with any other text or formatting around the JavaScript, you must only respond with raw JavaScript. The current date is Wednesday, July 26, 2023.",
  //           },
  //           { role: "user", content: newJSON },
  //         ];
  //         const chatCompletion = await generateChatCompletion(requestMessage);

  //         const htmlData = chatCompletion?.choices[0]?.message;
  //         const htmlContent = htmlData.content;

  //         const resultUpdate = await addDocumentFirebase(
  //           "logs",
  //           {
  //             activity: `generate ai landing page for result`,
  //             uid: globalState.uid,
  //             tokenUsage: chatCompletion?.usage?.total_tokens,
  //             projectId: globalState.currentProject,
  //             details: {
  //               answerMessage: chatCompletion?.choices[0]?.message,
  //             },
  //           },
  //           globalState.currentCompany
  //         );

  //         console.log(resultUpdate, "ini results");

  //         setAiGenerator((prevState) => {
  //           const updatedGenerator = [...prevState];
  //           updatedGenerator[i] = { ...x, loading: false, htmlContent };
  //           return updatedGenerator;
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }

  //   setLoading(false);
  // };

  const handleAi = async () => {
    setLoading(true);

    try {
      const aiGenerationPromises = aiGenerator.map(async (x) => {
        const str = Object.entries(message)
          .map(([p, val]) => `${p.replace("_", " ")}:\n -${val}\n`)
          .join("");

        let prompt = "";
        if (x.title === "Hero") prompt = "Headline and subheadline";
        else if (x.title === "Key Features")
          prompt = "3 to 6 features and or benefits";
        // else if (x.title === "Tech Specs") prompt = "3 to 6 technical specs";
        else if (x.title === "Testimonials") prompt = "3 to 6 Testimonials";
        else if (x.title === "FAQ") prompt = "3 to 6 FAQs";
        else if (x.title === "CTA") prompt = "Call to actions";
        else if (x.title === "Footer") prompt = "company name";

        //generate picture for used in landing page
        const imagePrompt = `give me one best noun word that describe this sentence \n\n${message.topic}\n\n answer only what i want, one word. Don't use any sentence when you answer. Give noun result in english`;

        const promptNouns = [
          {
            role: "user",
            content: imagePrompt,
          },
        ];

        const wordCompletion = await generateChatCompletion(promptNouns);

        console.log(wordCompletion.choices[0]?.message.content, "ini keyword");

        //masukin hasil ke pixabay
        const imageUrl = await pixabayImageApi(
          wordCompletion.choices[0]?.message?.content
        );
        // const imageUrl = `https://pixabay.com/api/?key=${process.env.REACT_APP_PIXABAY}&q=${wordCompletion.choices[0]?.message.content}`;
        // const resultImage = await axios.get(imageUrl);
        setUrlImage(imageUrl.data.hits[0]?.webformatURL);

        //generate landing page

        // const newJSON = `Create a high converting copy for ${x.title} section ${prompt} to be used in a sales page. Only answer and make the content with the data given, do not enter words or sentences that have nothing to do with the topic given. Respond only with code. Please make it with interesting design UI, unique, short and persuasive with data per below\n\n${str}`;
        const newJSON = `Create a high converting copy for ${x.title} section ${prompt} to be used in a sales page. Respond with html and inline css code. Please make it with beautiful and interesting design UI, use background color and color that suits with the topic if its necessary. Use this link ${imageUrl.data.hits[0]?.webformatURL} for all the image url source. Make it unique, short and persuasive with data per below\n\n${str}`;

        const requestMessage = [
          {
            role: "system",
            content:
              "You’re a site layout creator that responds with information which will be used to configure the sections of a page on a website, based on the user-provided input. All content should be as impressive and exciting as possible. You can only respond with valid html and JavaScript objects or arrays. Do not respond with any other text or formatting around the JavaScript.",
          },
          { role: "user", content: newJSON },
        ];

        const chatCompletion = await generateChatCompletionLandingPage(
          requestMessage
        );

        const messageData = chatCompletion.choices[0].message;

        if (messageData.content) {
          // const newHTMLJSON = `Create a high converting ${x.title} section in html format to be used in a sales page with data per below\n\n${messageData.content}\n\n Only answer and make the content with the data given, do not enter words or sentences that have nothing to do with the topic given. Respond with code. Make the sentence to the point and target the audience. Focus on content and the layout design. Make it simple, unique, short and persuasive for return in HTML inline CSS format`;
          const newHTMLJSON = `You are a site layout creator. I want you to create a high converting copy for ${x.title} section ${prompt} to be used in a sales page. Respond with html and inline css code. If you using unordered list, make it unstyled without dots. Please make it with interesting design UI, use background color with gradient style if its necessary. Use this link ${imageUrl.data.hits[0]?.webformatURL} for the image url source. Only include the data that i provide. Make it unique, short and persuasive with data per below\n\n${str}`;
          const htmlRequestMessage = [
            {
              role: "system",
              content:
                "You’re a site layout creator that responds with information which will be used to configure the sections of a page on a website, based on the user-provided input. All content should be as impressive and exciting as possible. You can only respond with code valid html inline css. Do not respond with any other text or formatting.",
            },
            { role: "user", content: newHTMLJSON },
          ];
          const htmlChatCompletion = await generateChatCompletion(
            htmlRequestMessage
          );

          const htmlData = htmlChatCompletion?.choices[0]?.message;
          const htmlContent = htmlData.content;

          await addDocumentFirebase(
            "logs",
            {
              type: "landingpage",
              activity: `generate ai landing page for result`,
              uid: globalState.uid,
              tokenUsage: htmlChatCompletion?.usage?.total_tokens,
              projectId: globalState.currentProject,
              details: {
                answerMessage: htmlChatCompletion?.choices[0]?.message,
              },
            },
            globalState.currentCompany
          );

          return { ...x, loading: false, htmlContent };
        }

        return { ...x, loading: false };
      });

      const results = await Promise.all(aiGenerationPromises);
      setAiGenerator(results);
    } catch (error) {
      console.log(error.message);
    }

    setLoading(false);
  };

  const handleSave = async () => {
    const collectionName = `funnels/${param.id}/page`;
    const docName = state.id;
    const data = {
      message: aiGenerator,
    };

    try {
      const result = await updateDocumentFirebase(
        collectionName,
        docName,
        data
      );

      toast({
        title: "Success",
        description: `Success save funnel`,
        status: "success",
        duration: 10000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
    }
  };

  return (
    <Box>
      <HStack my={5}>
        <BackButtons />
        <Spacer />
        <Heading>
          {state.title} - {state.title_page}
        </Heading>
      </HStack>
      <Flex gap={5}>
        <Flex w={"35%"}>
          <Container m="2" p="2" shadow="base">
            <Heading fontSize="md" mb={2}>
              Form Input
            </Heading>

            <FormControl>
              <FormLabel>Heading</FormLabel>
              <Input
                bgColor="white"
                color="black"
                sx={inputStyles}
                type="text"
                disabled={loading}
                placeholder="Say Goodbye to Financial Worries: Earn a Whopping 200k/year as a Full Stack Programmer!"
                onChange={(e) =>
                  setMessage({ ...message, topic: e.target.value })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Unique Selling Propotition</FormLabel>
              <Textarea
                bgColor="white"
                color="black"
                sx={inputStyles}
                onChange={(e) =>
                  setMessage({ ...message, usp: e.target.value })
                }
                placeholder={`Our unique program equips you with the in-demand skills of a full stack programmer, unlocking a world of lucrative opportunities. Join our comprehensive training and fast-track your career to earn an impressive annual income of $200k or more. Say goodbye to financial worries and embrace a future filled with financial stability, job satisfaction, and limitless growth potential. Don't just dream of success, make it a reality with our proven pathway to a high-earning future in the dynamic world of technology.`}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Benefits</FormLabel>
              <Textarea
                bgColor="white"
                color="black"
                sx={inputStyles}
                onChange={(e) =>
                  setMessage({ ...message, benefits: e.target.value })
                }
                placeholder={`Financial Security \nLucrative Career Opportunities \nPrestige and Recognition`}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Fears</FormLabel>
              <Textarea
                bgColor="white"
                color="black"
                sx={inputStyles}
                onChange={(e) =>
                  setMessage({ ...message, fears: e.target.value })
                }
                placeholder={`Stagnant Income \nCompetitive Job Market \nLimited Career Options`}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Social Proof</FormLabel>
              <Textarea
                bgColor="white"
                color="black"
                sx={inputStyles}
                onChange={(e) =>
                  setMessage({ ...message, social_proof: e.target.value })
                }
                placeholder={`Alumni Success Stories:"Thanks to the program, I secured a position at a leading tech company and doubled my previous salary within six months. The support from the alumni community has been invaluable in my professional growth." - Sarah Johnson, Program Graduate.`}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Brand Color</FormLabel>
              <Input
                bgColor="white"
                color="black"
                sx={inputStyles}
                type="text"
                onChange={(e) =>
                  setMessage({ ...message, brand_color: e.target.value })
                }
                placeholder={`#ffd600`}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Language</FormLabel>
              <Select
                placeholder="Language"
                onChange={(e) =>
                  setMessage({ ...message, language: e.target.value })
                }
              >
                <option value="use english for page content">English</option>
                <option value="use bahasa indonesia for page content">
                  Indonesia
                </option>
              </Select>
            </FormControl>
            {loading ? (
              <Button isLoading mt="5" w="full" colorScheme="green">
                Generating landing page with AI
              </Button>
            ) : (
              <Button
                onClick={() => handleAi()}
                mt="5"
                w="full"
                colorScheme="green"
              >
                Generate landing page with AI
              </Button>
            )}
            <Button
              onClick={() => handleSave()}
              mt="5"
              w="full"
              colorScheme="green"
            >
              Save
            </Button>
            {/* <Button
              onClick={() => console.log(aiGenerator)}
              mt="5"
              w="full"
              colorScheme="green"
            >
              Console
            </Button> */}
          </Container>
        </Flex>

        <Stack border={"1px"} borderColor={"green.300"} w={"65%"}>
          <Box>
            {aiGenerator.length > 0 && (
              <Stack>
                {loading ? (
                  <Loading />
                ) : (
                  <Text
                    mt={5}
                    alignItems={"center"}
                    justifyContent={"center"}
                    align={"center"}
                  >
                    Your AI result will be shown here
                  </Text>
                )}
                {aiGenerator.map((x, index) => (
                  <Stack key={index}>
                    {x.loading ? (
                      <Stack>
                        <Spinner />
                      </Stack>
                    ) : (
                      <Stack bgColor={"white"} color="black">
                        {x.htmlContent && (
                          <div
                            dangerouslySetInnerHTML={{ __html: x.htmlContent }}
                            //  style={{ background: "white", color:'black' }}
                          />
                        )}
                      </Stack>
                    )}
                  </Stack>
                ))}
              </Stack>
            )}
          </Box>
        </Stack>
      </Flex>
    </Box>
  );
}

export default FunnelCreatePageV2;

import React from "react";
import PricingDetailPage from "../Pages/Pricing/PricingDetailPage";
import PricingPage from "../Pages/Pricing/PricingPage";

const FrontRouter = [
  {
    path: "/pricing",
    element: <PricingPage />,
  },

  {
    path: "/pricing/detail",
    element: <PricingDetailPage />,
  },

];

export default FrontRouter;

import { Container, Flex } from "@chakra-ui/react";
import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import SidebarComponentV3 from "../Components/Sidebar/SidebarComponentV3";
import themeConfig from "../Config/themeConfig";

function Layout({ children }) {
  let contentWidth =
    themeConfig.contentWidth === "full" ? "full" : "container.xl";

    const location = useLocation();

    const isFunnelEditPage = location.pathname.includes("/funnel/view/") && location.pathname.includes("/edit");

  return (
    <Fragment>
      <Flex height="100vh" w={"full"} bgColor={themeConfig.color.colorSecond}>
        {/* Tampilkan SidebarComponentV3 hanya jika ada kecocokan dengan pola path */}
        {!isFunnelEditPage &&<SidebarComponentV3 layout={themeConfig.layout} />}

        {/* <SidebarComponentV3 layout={themeConfig.layout} /> */}

        <Container maxW={"full"} overflowY={"scroll"} pt={"4"}>
          <Container minH={"95vh"} maxW={contentWidth}>
            {children}
          </Container>

          {/* <FooterComponent /> */}
        </Container>
      </Flex>
    </Fragment>
  );
}

export default Layout;

import VideoAssetsAddPage from "../Pages/Viedos/VideoAssetsAddPage";
import VideoAssetsPage from "../Pages/Viedos/VideoAssetsPage";
import VideoTemplateEditPage from "../Pages/Viedos/VideoTemplateEditPage";
import VideoTextToVideo from "../Pages/Viedos/VideoTextToVideo";
import VideoTextToVideoWithTemplate from "../Pages/Viedos/VideoTextToVideoWithTemplate";
import VideoTextToVideoCreatePage from "../Pages/Viedos/VideoTextToVideoCreatePage";
import VideoCreateWithTemplate from "../Pages/Viedos/VideoCreateWithTemplate";
import VideoCreateEditPage from "../Pages/Viedos/VideoCreateEditPage";

const VideosRouter = [
  {
    path: "/video/assets",
    element: <VideoAssetsPage />,
  },
  {
    path: "/video/templates",
    element: <VideoCreateWithTemplate />,
  },
  {
    path: "/video/assets/upload",
    element: <VideoAssetsAddPage />,
  },
  {
    path: "/video/text2video",
    element: <VideoTextToVideoCreatePage />,
  },
  {
    path: "/video/text2video/edit/:id",
    element: <VideoCreateEditPage />,
  },
  {
    path: "/video/text2video/template/:id",
    element: <VideoTextToVideoWithTemplate />,
  },
  {
    path: "/video/templates/:id/edit/:templateId",
    element: <VideoTemplateEditPage />,
  },
];

export default VideosRouter;

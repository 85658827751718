import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import {
  addDocumentFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
  updateDocumentFirebase,
} from "../../Apis/firebaseApi";
import { data } from "../../Components/Sidebar/DataMenu";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import BackButtons from "../../Components/Button/BackButtons";

const LandingPageTrainingEditPage = () => {
  const param = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const globalState = UseAuthStore();
  const [title, setTitle] = useState();
  const [data, setData] = useState();
  const [content, setContent] = useState("");

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  function replaceWithBr(data) {
    if (data) {
      return data.replace(/\n/g, "<br />");
    }
  }

  const addTemplate = async () => {
    const dataTemplate = {
      code: content,
      title: title,
    };

    try {
      const docID = await updateDocumentFirebase(
        `templates/${param.id}/prompt`,
        param.content,
        { code: content }
      );

      toast({
        status: "success",
        title: "Deoapp AI",
        description: "Template Edited",
        duration: 3000,
      });

      const resultUpdate = await addDocumentFirebase(
        "logs",
        {
          activity: `edit section template ${data.name}`,
          uid: globalState.uid,
          projectId: globalState.currentProject,
          details: {
            code: content,
            title: data.title,
          },
        },
        globalState.currentCompany
      );
      console.log(resultUpdate, "logs update");

      navigate(`/lptrain/${param.id}`);
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
      toast({
        status: "error",
        title: "Deoapp AI",
        description: "Failed to create",
        duration: 3000,
      });
    }
  };

  const getDataTemplate = async () => {
    try {
      const result = await getSingleDocumentFirebase(
        `templates/${param.id}/prompt`,
        `${param.content}`
      );

      setData(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataTemplate();

    return () => {};
  }, []);

  return (
    <Box>
      <BackButtons />

      <Heading>Edit Template</Heading>
      <Flex my={4} gap={5}>
        <Stack w={"40%"}>
          <FormControl>
            <FormLabel>Title</FormLabel>
            <Input
              bgColor="white"
              color="black"
              sx={inputStyles}
              defaultValue={data?.title}
              resize="none"
              onChange={(e) => setTitle(e.target.value)}
              placeholder={`Title`}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Prompt Template</FormLabel>
            <Textarea
              bgColor="white"
              color="black"
              sx={inputStyles}
              defaultValue={data?.code}
              height={300}
              onChange={(e) => setContent(e.target.value)}
              placeholder={`Your html template`}
            />
          </FormControl>

          <Button colorScheme="green" onClick={addTemplate}>
            Save Template
          </Button>
        </Stack>
        <Stack w={"60%"} bg={"white"}>
          <Text>HTML Results</Text>
          <Box>
            {content === "" ? (
              <div
                dangerouslySetInnerHTML={{ __html: replaceWithBr(data?.code) }}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: replaceWithBr(content) }}
              />
            )}
          </Box>
        </Stack>
      </Flex>
    </Box>
  );
};

export default LandingPageTrainingEditPage;

export const transition = [
  {
    name: "Scale Transition",
    effect: "scale",
  },
  {
    name: "Fade Transition",
    effect: "fade",
  },
  {
    name: "Slide Transition",
    effect: "slid",
  },
  {
    name: "Rotate Slide Transition",
    effect: "rotate-slide",
  },
  {
    name: "Wipe Transition",
    effect: "wipe",
  },
  {
    name: "Circular Wipe Transition",
    effect: "circular-wipe",
  },
  {
    name: "Color Wipe Transition",
    effect: "color-wipe",
  },
  {
    name: "Squash Transition",
    effect: "squash",
  },
  {
    name: "Spin Transition",
    effect: "spin",
  },
  {
    name: "Film Roll Transition",
    effect: "film-roll",
  },
];

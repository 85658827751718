import React from "react";
import DashboardPage from "../Pages/Dashboard/DashboardPage";
import Layout from "../Layouts";
import ImageCreationPage from "../Pages/Image/ImageCreationPage";
import ImageCollection from "../Pages/Image/ImageCollection";

const ImageRouter = [
  {
    path: "/image-generator",
    element: <ImageCreationPage />,
  },
  {
    path: "/image-collection",
    element: <ImageCollection />,
  },
];

export default ImageRouter;

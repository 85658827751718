import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import { db } from "../../Config/firebase";
import themeConfig from "../../Config/themeConfig";
import { updateDocumentFirebase } from "../../Apis/firebaseApi";

function PaymentStripePage() {
  const toast = useToast();
  const navigate = useNavigate();
  const globalState = UseAuthStore();

  const [productsArr, setProductsArr] = useState([]);

  const getDataProducts = async () => {
    let products = {};
    const col = collection(db, "products");
    const q = query(col, where("active", "==", true));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      products[doc.id] = doc.data();

      // for get price
      const priceSnapshot = await getDocs(
        collection(db, `products/${doc.id}/prices`)
      );
      priceSnapshot.forEach((priceDoc) => {
        products[doc.id].price = {
          priceId: priceDoc.id,
          priceData: priceDoc.data(),
        };
      });
    });

    setProductsArr(products);
  };

  const getPrice = (name) => {
    if (name === "Professional Package") {
      return 27;
    } else if (name === "Unlimited Package") {
      return 97;
    } else if (name === "Enterprise Package") {
      return 297;
    }
    return 0;
  };

  useEffect(() => {
    getDataProducts();

    return () => {};
  }, []);

  const checkOut = async (priceId, uid) => {
    // const updatePayment = await updateDocumentFirebase("customers", uid, {
    //   paymentStatus: "stripe",
    //   createdBy: uid,
    // });

    // console.log(updatePayment);

    const docref = await addDoc(
      collection(db, "customers", uid, "checkout_sessions"),
      {
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      }
    );

    onSnapshot(
      doc(db, "customers", uid, "checkout_sessions", docref.id),
      async (doc) => {
        const { sessionId } = doc.data();
        try {
          if (sessionId) {
            const stripe = await loadStripe(
              process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
            ); // Menggunakan kunci publik Stripe
            stripe.redirectToCheckout({ sessionId });
          }
        } catch (error) {
          console.log(error.message);
        }
      }
    );
  };

  return (
    <Stack>
      <Stack>
        <SimpleGrid columns={[1, null, 3]} gap={6} p={6}>
          {Object.entries(productsArr) &&
            Object.entries(productsArr).map(([productId, productData]) => {
              const price = getPrice(productData.name);

              return (
                <VStack
                  key={productId}
                  borderColor="green.500"
                  borderTopWidth={4}
                  bgColor={themeConfig.color.colorSecond}
                  shadow={"base"}
                  borderRadius="lg"
                  spacing={5}
                  p={3}
                >
                  <Stack
                    alignItems={"flex-start"}
                    justifyContent="flex-start"
                    w={"100%"}
                    spacing={3}
                  >
                    <Image
                      w={"30px"}
                      src="https://buildfire.com/wp-content/themes/buildfire/assets/images/plan2@3x.png"
                      alt="https://buildfire.com/wp-content/themes/buildfire/assets/images/plan1@3x.png"
                    />
                    <Text fontSize={"lg"} fontWeight="bold">
                      {productData.name}
                    </Text>
                    <Text fontSize={"sm"}>{productData.description}</Text>
                    <Spacer />
                    <HStack>
                      <Text alignSelf={"flex-start"} fontWeight="bold">
                        $
                      </Text>
                      <Heading size="3xl">{price}</Heading>
                      <Text alignSelf={"flex-end"} fontWeight="bold">
                        / mo
                      </Text>
                    </HStack>
                    <Text fontSize={"sm"}>Per month billed quarterly.</Text>
                  </Stack>
                  <Spacer />
                  <Stack w={"full"}>
                    <Button
                      fontSize="sm"
                      fontWeight="bold"
                      size={"sm"}
                      // disabled={isCurrentPlan}
                      // onClick={() => checkOut(productData.price.priceId)}
                      // onClick={currentUser !== null ? (() => checkOut(productData.price.priceId, currentUser?.uid)) : (() => handleModal(productData.price.priceId))}
                      onClick={() =>
                        checkOut(productData?.price?.priceId, globalState?.uid)
                      }
                      colorScheme="green"
                    >
                      <Text>Buy now !</Text>
                    </Button>
                  </Stack>
                </VStack>
              );
            })}
        </SimpleGrid>
      </Stack>
    </Stack>
  );
}

export default PaymentStripePage;

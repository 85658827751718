import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from "../../Apis/firebaseApi";
import {
  Box,
  Button,
  HStack,
  Heading,
  Input,
  Spacer,
  Stack,
  Text,
  Textarea,
  Link,
} from "@chakra-ui/react";
import BackButtons from "../../Components/Button/BackButtons";

const LandingPageTrainingView = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [codeContent, setCodeContent] = useState(data?.code);
  const [template, setTemplate] = useState();

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  const getDataTemplate = async () => {
    try {
      const result = await getSingleDocumentFirebase(
        `templates/${param.id}/prompt`,
        `${param.content}`
      );

      setData(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getTemplate = async () => {
    try {
      const result = await getSingleDocumentFirebase(
        `templates/${param.id}/prompt`,
        `${param.content}`
      );

      setTemplate(result);
    } catch (error) {
      console.log(error);
    }
  };

  const updateCode = async () => {
    const collectionName = `templates/${param.templateId}/prompt`;
    const docName = param.id;
    const data = {
      code: codeContent,
    };

    try {
      const result = await updateDocumentFirebase(
        collectionName,
        docName,
        data
      );
      console.log(result); // Pesan toast yang berhasil
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
    }
  };

  useEffect(() => {
    getDataTemplate();
    getTemplate();

    return () => {};
  }, []);

  return (
    <Box>
      <BackButtons />
      <HStack my={8}>
        <Heading>{template?.title} Template</Heading>
      </HStack>

      <Stack spacing={5} mb={5}>
        <HStack mb={4}>
          <Text>Current Section Results</Text>
          <Spacer />
          <Button
            onClick={() =>
              navigate(`/lptrain/${param.id}/view/${param.content}/edit`)
            }
            size={"sm"}
            colorScheme="green"
          >
            Modify Code
          </Button>
        </HStack>
        <Box bg={"white"}>
          <div>
            <div dangerouslySetInnerHTML={{ __html: data?.code }} />
          </div>
        </Box>
      </Stack>

      {/* <Stack>
        <Text>Modify code</Text>
        <Textarea
          bgColor="white"
          color="black"
          sx={inputStyles}
          defaultValue={data?.code}
          onChange={(e) => setCodeContent(e.target.value)}
        />
        <Button colorScheme="green" onClick={() => updateCode}>
          Update Code
        </Button>
      </Stack>

      <Stack my={5}>
        <Text>Modified Code</Text>
        <Box bg={"white"}>
          <div>
            <div dangerouslySetInnerHTML={{ __html: codeContent }} />
          </div>
        </Box>
      </Stack> */}
    </Box>
  );
};

export default LandingPageTrainingView;

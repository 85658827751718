import React, { useEffect, useRef, useState } from "react";
import { useWindowWidth } from "../../Components/Videos/PreviewUtility";
import { Preview } from "@creatomate/preview";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { Center } from "@chakra-ui/react";
import { SettingsPanel } from "../../Components/Videos/SettingPanel";
import BackButtons from "../../Components/Button/BackButtons";
import { getSingleDocumentFirebase } from "../../Apis/firebaseApi";
import { useParams } from "react-router";

const VideoEditPage = () => {
  const param = useParams();
  const previewRef = useRef();
  const windowWidth = useWindowWidth();
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentState, setCurrentState] = useState();
  const [videoAspectRatio, setVideoAspectRatio] = useState();

  const [dataTemplate, setDataTemplate] = useState();

  const getDataTemplate = async () => {
    try {
      const result = await getSingleDocumentFirebase("templates", param.id);
      setDataTemplate(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataTemplate();
  }, []);

  const setupPreview = (htmlElement) => {
    if (previewRef.current) {
      previewRef.current.dispose();
      previewRef.current = undefined;
    }

    const preview = new Preview(
      htmlElement,
      "player",
      process.env.REACT_APP_CREATOMATE_VIDEO_PUBLIC_TOKEN
    );

    preview.onReady = async () => {
      await preview.loadTemplate(param.templateId);
      setIsReady(true);
    };

    preview.onLoad = () => {
      setIsLoading(true);
    };

    preview.onLoadComplete = () => {
      setIsLoading(false);
    };

    preview.onStateChange = (state) => {
      setCurrentState(state);
      setVideoAspectRatio(state.width / state.height);
    };

    previewRef.current = preview;
  };

  return (
    <Box>
      <BackButtons />

      <Center p={5}>
        <Flex h={"100vh"} gap={5} w={"100vw"}>
          {isLoading && (
            <Box
              // pos={"fixed"}
              // top={"20px"}
              // left={"50%"}
              px={3}
              py={5}
              // bg={"white"}
              boxShadow={"md"}
              borderRadius={"md"}
              fontWeight={"semibold"}
            >
              Loading...
            </Box>
          )}
          <Flex h={"80vh"} w={"50%"} justifyContent={"center"} mt={10}>
            <Box
              ref={(htmlElement) => {
                if (
                  htmlElement &&
                  htmlElement !== previewRef.current?.element
                ) {
                  setupPreview(htmlElement);
                }
              }}
              style={{
                height:
                  videoAspectRatio && windowWidth && windowWidth < 768
                    ? window.innerWidth / videoAspectRatio
                    : undefined,
              }}
            />
          </Flex>

          <Flex
            // pos={"relative"}
            // bg={"white"}
            boxShadow={"md"}
            w={"50%"}
          >
            {isReady && (
              <Box
                py={3}
                // pos={"absolute"}
                // top={0}
                // left={0}
                border={"1px"}
                borderRadius={"md"}
                p={5}
                w={"100%"}
                h={"100%"}
                overflow={"auto"}
                sx={{
                  "&::-webkit-scrollbar": {
                    w: "2",
                    h: "1",
                  },
                  "&::-webkit-scrollbar-track": {
                    w: "6",
                    h: "5",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "10",
                    bg: `gray.600`,
                  },
                }}
                id="panel"
              >
                <SettingsPanel
                  preview={previewRef.current}
                  currentState={currentState}
                />
              </Box>
            )}
          </Flex>
        </Flex>
      </Center>
    </Box>
  );
};

export default VideoEditPage;

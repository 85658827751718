import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  addDocumentFirebase,
  deleteDocumentFirebase,
  getCollectionWithSnapshotFirebase,
} from "../../Apis/firebaseApi";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../Config/firebase";
import {
  TiDelete,
  TiEdit,
  TiFeather,
  TiHeadphones,
  TiMessageTyping,
  TiMessages,
  TiNews,
  TiPuzzleOutline,
  TiSortAlphabeticallyOutline,
  TiStarFullOutline,
  TiThumbsOk,
  TiTrash,
  TiWaves,
} from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { pixabayImageApi } from "../../Apis/videoApi";

function TextTraining() {
  const toast = useToast();
  const navigate = useNavigate();
  const [dataTemplate, setDataTemplate] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState();
  const globalState = UseAuthStore();
  const [template, setTemplate] = useState([]);

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  const addTemplate = async () => {
    try {
      const docID = await addDocumentFirebase(
        "templates",
        {
          ...data,
          iconColor: "green.300",
          type: "openai-custom",
          title: data.name,
          category: "aissistant-copywriting",
        },
        globalState.currentCompany
      );
      console.log("ID Dokumen Baru:", docID);

      const resultUpdate = await addDocumentFirebase(
        "logs",
        {
          activity: `add template train ai for copywriting`,
          uid: globalState.uid,
          projectId: globalState.currentProject,
          details: {
            type: "openai-custom",
            title: data.name,
            category: "aissistant-copywriting",
          },
        },
        globalState.currentCompany
      );
      console.log(resultUpdate, "logs updated");

      toast({
        status: "success",
        title: "Deoapp AI",
        description: "Template Added",
        duration: 3000,
      });
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
      toast({
        status: "error",
        title: "Deoapp AI",
        description: `Error added template ${error}`,
        duration: 3000,
      });
    }
  };

  const handleDeleteTemplate = async () => {
    try {
      const result = await deleteDocumentFirebase("templates", dataTemplate.id);

      toast({
        status: "success",
        title: "Deoapp AI",
        description: "Template Deleted",
        duration: 1000,
      });

      onClose();
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
    }
  };

  const handleOpenModal = (template) => {
    onOpen();
    setDataTemplate(template);
  };

  const getuserTemplates = async () => {
    const q = query(
      collection(db, "templates"),
      where("type", "==", "openai-custom"),
      where("companyId", "==", globalState.currentCompany)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const templateArr = [];
      querySnapshot.forEach((doc) => {
        templateArr.push({ ...doc.data(), id: doc.id });
      });
      setTemplate(templateArr);
    });
  };

  const generateImage = async () => {
    const res = await pixabayImageApi(
      "You’re a site layout creator that responds with information which will be used to configure the sections of a page on a website, based on the user-provided input. All content should be as impressive and exciting as possible. You can only respond with code valid html inline css. Do not respond with any other text or formatting."
    );

    console.log(res.data.hits[0], "ppppp");
  };

  // const getuserTemplates = async () => {
  //   const q = query(
  //     collection(db, "projects"),
  //     where("users", "array-contains", globalState.uid)
  //   );

  //   const unsubscribe = onSnapshot(q, (snapshot) => {
  //     const promises = snapshot.docs.map(async (doc) => {
  //       const templateData = { id: doc.id, ...doc.data() };

  //       if (templateData) {
  //         const userSnapshot = await getDocs(
  //           collection(db, `templates`),
  //           where("createdBy", "==", globalState.uid)
  //         );
  //         const templateData = userSnapshot.docs.map((doc) => ({
  //           id: doc.id,
  //           ...doc.data(),
  //         }));
  //         templateData.userstemplateData = templateData;
  //         return templateData;
  //       }

  //       return null;
  //     });

  //     Promise.all(promises).then((resolvedTemplates) => {
  //       const templateData = resolvedTemplates.filter(
  //         (template) => template !== null
  //       );
  //       setTemplate(templateData);
  //     });
  //   });

  //   // Unsubscribe from the snapshot listener when the component unmounts
  //   return () => unsubscribe();
  // };

  useEffect(() => {
    getuserTemplates();

    generateImage();

    return () => {};
  }, [globalState.currentCompany]);

  return (
    <Box>
      <Heading>Train Your AI for Text Copywriting</Heading>
      <Flex my={4} gap={5}>
        <Stack w={"50%"}>
          <FormControl>
            <FormLabel>Title</FormLabel>
            <Input
              bgColor="white"
              color="black"
              sx={inputStyles}
              onChange={(e) => setData({ ...data, name: e.target.value })}
              placeholder={`Your Prompt Title. Ex: Script Content Generator`}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Prompt Template</FormLabel>
            <Textarea
              bgColor="white"
              color="black"
              sx={inputStyles}
              resize="none"
              onChange={(e) => setData({ ...data, message: e.target.value })}
              placeholder={`Your template prompt. Ex: Write a short script with more than 1000 character with topic about {topic} with {language} language in {languageStyle} tone`}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Textarea
              bgColor="white"
              color="black"
              sx={inputStyles}
              resize="none"
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
              placeholder={`Your template description`}
            />
          </FormControl>
        </Stack>
        <Stack w={"50%"}>
          <FormControl>
            <FormLabel>Topic</FormLabel>
            <Input
              bgColor="white"
              color="black"
              sx={inputStyles}
              resize="none"
              onChange={(e) => setData({ ...data, topic: e.target.value })}
              placeholder={`Your main topic about this template. Ex: Copywriting, Script, Marketing`}
            />
          </FormControl>
          <Button my={3} colorScheme="green" onClick={addTemplate}>
            Make Your Template
          </Button>
        </Stack>
      </Flex>

      <Stack my={5}>
        <Text fontSize={15} fontWeight={"semibold"}>
          Template Collections
        </Text>
        <SimpleGrid my={3} columns="4" gap="3">
          {template.length > 0 ? (
            <>
              {template?.map((x, i) => {
                return (
                  <Stack
                    _hover={{
                      transform: "scale(1.03)",
                      shadow: "xl",
                    }}
                    transition={"0.2s ease-in-out"}
                    spacing={2}
                    borderTopWidth={3}
                    borderColor="green.500"
                    alignItems="center"
                    justifyContent="start"
                    bgColor="#2B2B2B"
                    key={i}
                    py={4}
                    px={2}
                    borderRadius="md"
                    shadow="md"
                    // Tambahkan properti disabled jika id kosong
                    disabled={!x.id}
                    opacity={!x.id ? 0.5 : 1}
                    cursor={!x.id ? "not-allowed" : "pointer"}
                  >
                    <Stack
                      p={5}
                      onClick={() => {
                        if (x.id) {
                          navigate(`/create-aissistant/instagram/${x.title}`);
                        }
                      }}
                    >
                      <Icon
                        as={TiFeather}
                        color={x.iconColor}
                        boxSize={12}
                        alignSelf="center"
                      />
                      <Box align={"center"}>
                        <Text fontWeight="bold" textTransform={"capitalize"}>
                          {x.title}
                        </Text>
                        <Text fontSize={12}>{x.description}</Text>
                      </Box>
                    </Stack>
                    <Spacer />
                    <Box w={"full"}>
                      <HStack fontSize={"md"}>
                        <Text fontSize="2xs">
                          {moment
                            .unix(
                              x?.lastUpdated?.seconds ?? x?.createdAt?.seconds
                            )
                            .fromNow()}
                        </Text>
                        <Spacer />
                        <Icon
                          size={"md"}
                          as={TiEdit}
                          onClick={() => navigate(`/texttrain/${x.id}/edit`)}
                        />
                        <Icon as={TiTrash} onClick={() => handleOpenModal(x)} />
                      </HStack>
                    </Box>
                  </Stack>
                );
              })}
            </>
          ) : (
            <Box>
              <Text>No Data Template, Please Create One</Text>
            </Box>
          )}
        </SimpleGrid>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{template.title} Template</ModalHeader>
          <ModalBody>Template will be deleted, are you sure?</ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                size={"sm"}
                colorScheme="green"
                onClick={handleDeleteTemplate}
              >
                Yes
              </Button>
              <Button size={"sm"} colorScheme="red" onClick={onClose}>
                No
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default TextTraining;

import React, { useState, useEffect } from "react";
import { Select, Box, FormLabel } from "@chakra-ui/react";
import axios from "axios";

const LanguageSelect = ({ onChange }) => {
  //   const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const languages = [
    "English",
    "Spain",
    "France",
    "Chinese",
    "Arabic",
    "Russia",
    "Portuguese",
    "Germany",
    "Japanese",
    "Bahasa Indonesia",
  ];

  const handleLanguageChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedLanguage(selectedValue);
    onChange(selectedValue);
  };

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  return (
    <Box>
      <FormLabel>Language</FormLabel>
      <Select
        bgColor="white"
        color="black"
        sx={inputStyles}
        placeholder="Select language"
        value={selectedLanguage}
        onChange={handleLanguageChange}
      >
        {languages.map((language, index) => (
          <option key={index} value={language}>
            {language}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default LanguageSelect;

import React from "react";
import DashboardPage from "../Pages/Dashboard/DashboardPage";
import Layout from "../Layouts";
import TemplateTextPage from "../Pages/Templates/TemplateTextPage";
import TemplateTextCreatePage from "../Pages/Templates/TemplateTextCreatePage";
import TemplateWebPage from "../Pages/Templates/TemplateWebPage";
import TemplateWebViewPage from "../Pages/Templates/TemplateWebViewPage";
import TemplateWebCreatePage from "../Pages/Templates/TemplateWebCreatePage";
import TemplateWebEditPage from "../Pages/Templates/TemplateWebEditPage";

const TemplateRouter = [
  {
    path: "/template-web",
    element: <TemplateWebPage />,
  },
  {
    path: "/template-web/create",
    element: <TemplateWebCreatePage />,
  },
  {
    path: "/template-web/:templateId/view/:id",
    element: <TemplateWebViewPage />,
  },
  {
    path: "/template-web/:templateId/view/:id/edit",
    element: <TemplateWebEditPage />,
  },
  {
    path: "/template-text",
    element: <TemplateTextPage />,
  },
  {
    path: "/template-text/create",
    element: <TemplateTextCreatePage />,
  },
];

export default TemplateRouter;

import { useEffect, useState } from "react";

export function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState();
  useEffect(() => {
    setWindowWidth(window.innerWidth);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
}

export function deepClone(value) {
  if (typeof value !== "object" || value === null) {
    return value;
  }

  const target = Array.isArray(value) ? [] : {};

  for (const key in value) {
    target[key] = deepClone(value[key]);
  }

  return target;
}

export function groupBy(items, iteratee) {
  return items.reduce((map, value) => {
    const key = iteratee(value);

    let array = map.get(key);
    if (!array) {
      array = [];
      map.set(key, array);
    }

    array.push(value);

    return map;
  }, new Map());
}

import {
  Box,
  Button,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  addDocumentFirebase,
  deleteDocumentFirebase,
  getCollectionFirebase,
} from "../../../Apis/firebaseApi";
import BackButtons from "../../../Components/Button/BackButtons";
import {
  deleteDataApiBearer,
  postDataApiBearer,
} from "../../../Apis/axiosWithBarier";
import { UseAuthStore } from "../../../Hooks/Zustand/store";
import { addDomainCustom } from "../../../Apis/vercelAPI";

const DomainCreatePage = () => {
  const globalState = UseAuthStore();
  const navigate = useNavigate();
  const [allDomain, setAllDomain] = useState();
  const [data, setData] = useState({});
  const toast = useToast();

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  const handleAddDomain = async () => {
    const requestData = {
      domain_name: data.name,
      projectName: "domainview-react",
    };

    const matchingData = allDomain.find((x) => x.name === data.name);
    if (matchingData === undefined) {
      try {
        const response = await postDataApiBearer(
          `https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/vercelCreateDomain`,
          requestData
        );
        console.log(response);

        if (response.message === "Success") {
          const docID = await addDocumentFirebase(
            "domains",
            {
              vercelData: response.data,
              domain: [data.name],
              name: data.name,
              uid: globalState.uid,
              companyId: globalState.currentCompany,
              projectId: globalState.currentProject,
              type: "funnel",
              createdAt: new Date(),
            },
            globalState.currentCompany
          );

          toast({
            status: "success",
            title: "Deoapp AI",
            description: "Domain Added",
            duration: 2000,
          });

          const logRes = addDocumentFirebase(
            "logs",
            {
              activity: `create custom domain to vercel`,
              detail: { domainName: data.name },
              projectId: globalState.currentProject,
              uid: globalState.uid,
            },
            globalState.currentCompany
          );
          console.log(logRes, "log added");
        } else {
          toast({
            status: "error",
            title: "Deoapp AI",
            // description: `Error creating domain ${response.message}`,
            duration: 2000,
          });
        }
        // Lakukan sesuatu dengan data response
      } catch (error) {
        console.log(error);
        // Tangani error dengan cara yang sesuai
      }
    } else {
      toast({
        status: "error",
        title: "Deoapp AI",
        description: `Error creating domain, domain already added`,
        duration: 2000,
        position: "top",
      });
    }
  };

  const getAllDomain = async () => {
    try {
      const res = await getCollectionFirebase("domains");
      setAllDomain(res);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  useEffect(() => {
    getAllDomain();

    return () => {};
  }, []);

  return (
    <>
      <BackButtons />

      <Heading mb={5}>Add Domain</Heading>
      <Stack spacing={5}>
        <Stack>
          <Text>Add Your Own Domain</Text>
          <Input
            bgColor="white"
            color="black"
            sx={inputStyles}
            resize="none"
            placeholder={"example.website.com"}
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
        </Stack>
        {/* <Stack>
          <Text>Additional Code for your domain</Text>
          <Input
            bgColor="white"
            color="black"
            sx={inputStyles}
            resize="none"
            as={Textarea}
            placeholder={"www.website.com"}
            onChange={(e) => setData(...data, additionalCode: e.target.value)}
          />
        </Stack> */}

        <Button colorScheme="green" onClick={handleAddDomain}>
          Save
        </Button>
      </Stack>
    </>
  );
};

export default DomainCreatePage;

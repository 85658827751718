import {
  Box,
  Button,
  CloseButton,
  Container,
  Divider,
  Flex,
  HStack,
  Icon,
  Image,
  Input,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../Apis/axiosWithBarier";
import { formatFrice } from "../../Utils/numberUtil";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import { auth, db } from "../../Config/firebase";
import {
  addDocumentFirebase,
  deleteDocumentFirebase,
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from "../../Apis/firebaseApi";
import axios from "axios";
import CopyToClipboardUtil from "../../Utils/CopyToClipboardUtil";
import Loading from "../../Components/Loading/Loading";
import { addDoc, collection } from "firebase/firestore";
import moment from "moment";
import { signOut } from "firebase/auth";
import store from "store";
import { useNavigate } from "react-router-dom";

const PaymentSinglePage = () => {
  const [payment, setPayment] = useState({
    bank: null,
    button: false,
    data: null,
  });
  const [orderList, setOrderList] = useState([]);
  const [loadingPay, setLoadingPay] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [paymentVA, setPaymentVA] = useState("");

  const toast = useToast();

  const banks = [
    {
      name: "BNI",
      uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-bni.png",
    },
    {
      name: "MANDIRI",
      uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-mandiri.png",
    },
    {
      name: "PERMATA",
      uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-permatabank.png",
    },
    {
      name: "BRI",
      uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-bri.png",
    },
  ];

  const [orderId, setOrderId] = useState("");

  const globalState = UseAuthStore();

  const [packageActive, setPackageActive] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [selectedPaymentMethodImage, setSelectedPaymentMethodImage] =
    useState("");

  const [paymentActive, setPaymentActive] = useState("");

  const getMembership = async () => {
    try {
      const res = await axiosInstance.get("/membershipList");
      if (res.status === true) {
        setOrderList(res.message);
      } else {
        console.log(res.message);
      }
    } catch (error) {
      console.log(error, "ini eror");
    }
  };

  useEffect(() => {
    getMembership();

    return () => {};
  }, []);

  const handlePackageActive = (value) => {
    setPackageActive(value);
  };

  const handlePay = async () => {
    const data = {
      is_production: true,
      package_code: packageActive.package_code,
      company_name: companyName,
      user_name: auth.currentUser.displayName || globalState.name,
      user_email: auth.currentUser.email || globalState.email,
      user_phone: phoneNumber || auth.currentUser.phoneNumber,
      redirect_url: `https://ai.deoapp.com/account`,
      module: "ai",
      paymentMethod: "transfer_bank",
      paymentStatus: "Subscribtion",
      package_amount: packageActive.package_amount,
      expired_at: moment().add(1, "months").toISOString(),
    };

    addDocumentFirebase("orders", data, "8NCG4Qw0xVbNR6JCcJw1").then((x) => {
      return handlePaymentTransfer(x, data, packageActive.package_amount);
    });
  };

  const handlePaymentMethodSelect = (method) => {
    setSelectedPaymentMethod(method);
    const imageFind = banks.find((x) => x.name === method);
    setSelectedPaymentMethodImage(imageFind.uri);
  };

  const handlePaymentTransfer = async (id, updateData, price) => {
    setOrderId(id);
    setPaymentActive(updateData);
    setLoadingPay(true);

    const baseUrl =
      "https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/";

    const data = {
      xenditId: "6479f64913999eb3b3fe7283",
      orderId: id,
      amount: price,
      bankCode: selectedPaymentMethod,
      name: updateData.company_name,
      companyId: "8NCG4Qw0xVbNR6JCcJw1",
      outletId: "DeoappTeam",
      projectId: "D7i1gPJWZkZXV1z4w9ca",
      module: "ai",
      userId: "rifqyganteng",
      feeRule: true,
    };

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_PAYMENT_KEY,
      },
    };

    try {
      const res = await axios.post(`${baseUrl}/paymentCreateVA`, data, options);
      if (res.data.status === true) {
        setPaymentVA(res.data.data);
        setLoadingPay(false);
      } else {
        toast({
          title: "Warning!",
          description: "Terjadi Kesalah Generate VA.",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
        setPaymentActive("");
        setLoadingPay(false);
      }
    } catch (error) {
      console.log(error, "ini error");

      toast({
        title: "Error!",
        description: "Something Wrong !.",
        status: error,
        duration: 9000,
        isClosable: true,
      });

      setLoadingPay(false);
    }
    setLoadingPay(false);
  };

  const handleInputPayment = async () => {
    const data = {
      name: paymentActive.company_name,
      owner: [globalState.uid],
      users: [globalState.uid],
      expired_at: moment().add(1, "months").toISOString(),
    };
    try {
      const result = await getSingleDocumentFirebase("payments", orderId);
      if (result.status === "PENDING") {
        toast({
          title: "Deoapp Indonesia",
          description: "You dont have payment active now, please contact us!.",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      }
      if (result.status === "PAID") {
        updateDocumentFirebase("orders", orderId, {
          paymentStatus: "complete",
          updated_bill: new Date(),
        }).then(async (res) => {
          console.log(res, "ini ress");
          if (res) {
            const docRef = await addDoc(collection(db, "companies"), data);

            // Kembalikan ID dokumen yang baru dibuat
            return handleNewProject(docRef.id);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNewProject = (id) => {
    toast({
      title: "Deoapp Indonesia",
      description: `You have to create your company, please create your project now ! ID : ${id} , please logout and set your team.`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    setPaymentActive("");

    signOut(auth)
      .then(() => {
        // Sign-out successful.
        toast({
          status: "success",
          description: "Logged out success",
          duration: 2000,
        });

        globalState.setIsLoggedIn(false);
        navigate("/login");
        store.clearAll();
      })
      .catch((error) => {
        console.log(error, "ini error");
      });
  };

  // const handlePayPayment = async () => {
  //     setLoadingPay(true)

  //     const data = {
  //         order_id: paymentActive.order_id
  //     }
  //     try {
  //         const res = await axiosInstance.post('/membershipPay', data)
  //         if (res.status === true) {
  //             window.open(res.message.link, '_blank');

  //         }
  //         setLoadingPay(false)
  //     } catch (error) {
  //         console.log(error, 'ini error')
  //         setLoadingPay(false)

  //     }
  //     finally {
  //         setLoadingPay(false)
  //     }

  // }

  const handleCopy = (id) => {
    navigator.clipboard.writeText(id);
    toast({
      title: "Deoapp",
      description: "Copy to clipboard.",
      status: "success",
    });
  };

  const handleCancelPayment = async () => {
    try {
      const result = await deleteDocumentFirebase("orders", orderId);
      if (result) {
        setOrderId("");
        setPaymentVA("");
        setPaymentActive("");
      }
      //   console.log(result); // Pesan toast yang berhasil
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  useEffect(() => {
    return () => {
      setPayment({ bank: null, button: false, data: null });
    };
  }, []);

  return (
    <Box
      as="section"
      py={{
        base: "4",
        md: "8",
      }}
    >
      <Stack spacing="5">
        <Stack
          spacing="4"
          direction={{
            base: "column",
            sm: "row",
          }}
          justify="space-between"
        >
          <Box>
            <Text fontSize="lg" fontWeight="medium">
              Payment
            </Text>
            {globalState.companies.length > 0 ? (
              <Text color="green" fontWeight={500} fontSize="sm">
                You have a company that is active in payments
              </Text>
            ) : (
              <Text color="muted" fontSize="sm">
                Check your order list and choose your payment method
              </Text>
            )}
          </Box>

          {/* <Button alignSelf="start" onClick={() => handleUpdate()}>
                                Save
                           </Button> */}
        </Stack>
        <Divider />
        <Stack spacing="5" divider={<StackDivider />}>
          {paymentActive ? (
            <Stack spacing={5}>
              <Stack>
                <Text textTransform={"uppercase"}>
                  {paymentActive.company_name} - {paymentActive.user_name}
                </Text>
                <Text>{paymentActive.order_id}</Text>
              </Stack>
              {paymentVA !== "" ? (
                <Stack>
                  <HStack w="full" px={5}>
                    <Image
                      src={selectedPaymentMethodImage}
                      alt={paymentVA?.bank_code}
                      w="80px"
                      borderRadius="xl"
                    />

                    <Spacer />

                    <Text fontSize="sm" textTransform="uppercase">
                      {paymentVA?.status}
                    </Text>
                  </HStack>

                  <Box px={5}>
                    <Text>No. Virtual Account : </Text>
                    <Divider my={2} />
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Text fontSize={20} color="gray.500">
                        {paymentVA ? paymentVA?.account_number : "none"}
                      </Text>
                      <Text
                        color="blue.600"
                        cursor="pointer"
                        onClick={() => handleCopy(paymentVA?.account_number)}
                      >
                        SALIN
                      </Text>
                    </Box>
                    <Divider my={2} />
                    <HStack py={1}>
                      <Text fontSize="sm" textTransform={"uppercase"}>
                        PT. Edrus Strategy Digital
                      </Text>
                      <Spacer />
                      <Text fontSize="sm">
                        Rp. {formatFrice(paymentVA?.expected_amount)}
                      </Text>
                      <CopyToClipboardUtil text={paymentVA?.expected_amount} />
                    </HStack>
                    <Divider my={2} />

                    <Text fontSize={10} color="gray.600">
                      Proses verifikasi otomatis kurang dari 10 menit setelah
                      pembayaran berhasil
                    </Text>
                    <Spacer />
                    <Text fontSize={10} color="gray.600">
                      Bayar ke Virtual Account di atas sebelum membuat donasi
                      baru dengan Virtual account agar nomor tetap sama.
                    </Text>
                  </Box>
                  <Box p={5}>
                    <Text fontSize={10} color="gray.600">
                      Petunjuk Transfer mBanking :
                    </Text>
                    <Divider />
                    <Text fontSize={10} color="gray.600">
                      1. Login ke mBanking-mu, pilih Transaksi, kemudian cari{" "}
                      {paymentVA.bank_code} Virtual Account
                    </Text>
                    <Text fontSize={10} color="gray.600">
                      2. Masukkan nomor Virtual Account
                    </Text>
                    <Text fontSize={10} color="gray.600">
                      3. Pastikan nama dan nominal bayar benar
                    </Text>
                  </Box>

                  <HStack alignItems="center" justifyContent="center">
                    <Box>
                      <Button
                        colorScheme="red"
                        size="sm"
                        onClick={() => handleCancelPayment()}
                      >
                        Cancel payment
                      </Button>
                    </Box>

                    <Box>
                      <Button
                        colorScheme="green"
                        size="sm"
                        onClick={() => handleInputPayment()}
                      >
                        Done
                      </Button>
                    </Box>
                  </HStack>
                </Stack>
              ) : (
                <Stack w={"full"}>
                  <Loading />
                </Stack>
              )}

              <Spacer />

              <Stack>
                <Text fontSize={"2xl"} fontWeight={"bold"}>
                  Rp.{formatFrice(paymentActive.package_amount)}
                </Text>
              </Stack>
            </Stack>
          ) : (
            <Stack spacing={5}>
              {orderList?.length > 0 &&
                orderList?.map((x, i) => {
                  return (
                    <Stack
                      key={i}
                      _hover={{
                        transform: "scale(1.05)",
                        shadow: "xl",
                      }}
                      transition="0.2s ease-in-out"
                      spacing={2}
                      borderStartWidth={5}
                      borderEndWidth={5}
                      borderColor={
                        packageActive.package_name === x.package_name
                          ? "green.500"
                          : "transparent"
                      }
                      bgColor="#2B2B2B"
                      py={2}
                      px={2}
                      borderRadius="md"
                      shadow="md"
                      width="100%"
                      onClick={() => handlePackageActive(x)}
                    >
                      <HStack spacing={2} key={i} width="100%">
                        <Stack p={5}>
                          <Icon as={x.icon} boxSize={12} />
                        </Stack>
                        <Box>
                          <Text
                            textTransform="capitalize"
                            fontSize={"md"}
                            fontWeight={500}
                          >
                            {x.package_name}
                          </Text>
                          <Text fontSize="xs">{x.description}</Text>
                        </Box>
                        <Spacer />
                        <Box px={5}>
                          <Text>Rp. {formatFrice(x.package_amount)}</Text>
                        </Box>
                      </HStack>
                    </Stack>
                  );
                })}

              <Text>Company Name</Text>
              <Input
                placeholder="Company Name"
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <Text>Phone Number</Text>
              <Input
                placeholder="Phone Number"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />

              <Stack>
                <Text mt="4">Pilih metode pembayaran :</Text>
                <RadioGroup
                  value={selectedPaymentMethod}
                  onChange={handlePaymentMethodSelect}
                  mt="2"
                >
                  <SimpleGrid py={2} columns={[2, null, 4]} align="start">
                    {banks?.map((x, index) => (
                      <Radio key={index} value={x.name}>
                        <Image src={x.uri} w="70px" />
                      </Radio>
                    ))}
                  </SimpleGrid>
                </RadioGroup>

                {!loading ? (
                  <Button colorScheme="green" onClick={() => handlePay()}>
                    Create
                  </Button>
                ) : (
                  <Button
                    isLoading
                    colorScheme="green"
                    onClick={() => handlePay()}
                  >
                    Create
                  </Button>
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default PaymentSinglePage;

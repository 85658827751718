import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Img,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { generateDalleImage } from "../../Apis/openAi";
import { set } from "store";
import Loading from "../../Components/Loading/Loading";
import UploadUrlToDropbox from "../../Components/Dropbox/UploadUrlToDropbox";
import {
  addDocumentFirebase,
  getSingleDocumentFirebase,
} from "../../Apis/firebaseApi";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import { decryptToken } from "../../Utils/encryptToken";
import BackButtons from "../../Components/Button/BackButtons";

const ImageCreationPage = () => {
  const toast = useToast();
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [dataImage, setDataImage] = useState();
  const [loading, setLoading] = useState(false);
  const [generatedImage, setGeneratedImage] = useState(null);
  const globalState = UseAuthStore();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleGenerateImage = async () => {
    setLoading(true);
    try {
      const imageGenerate = await generateDalleImage(
        data.message,
        parseInt(data.quantity)
      );

      if (imageGenerate || imageGenerate.status === 200) {
        setGeneratedImage(imageGenerate.data.data);
      } else {
        toast({
          status: "error",
          title: "Deoapp AI",
          description: `Error Generating Data, check your text if it contains forbidden words`,
          duration: 2000,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // console.log(generatedImage);
  // const mapped = generatedImage.map((x) => x.b64_json);
  // console.log(mapped);
  // const imageBlob = new Blob([Uint8Array?.from(generatedImage[0]?.b64_json)], {
  //   type: "image/png",
  // });
  // console.log(imageBlob);

  // console.log(atob(generatedImage[0]?.b64_json));
  // console.log(JSON.stringify(atob(generatedImage[0]?.b64_json)));
  // console.log(JSON.stringify(atob(generatedImage[0]?.b64_json)));

  // const imageBlob = new Blob([Uint8Array.from(JSON.parse(atob(generatedImage[0].b64_json)))], {
  //   type: imageObject.content_type,
  // });

  const handleSaveImage = async () => {
    // setLoading(true);
    const token = await getSingleDocumentFirebase("token", "dropbox");

    const decryptResult = decryptToken(`${token?.access_token}`);
    console.log(decryptResult, "ini token");
    window.open(dataImage?.url);

    try {
      const parentPath = `/${globalState.companies[0]?.name}/AI`; // Ganti dengan path folder di Dropbox yang ingin Anda gunakan
      const dropboxLink = await UploadUrlToDropbox(
        dataImage.url,
        parentPath,
        decryptResult
      );

      console.log(dropboxLink);
      console.log(dataImage.url);

      // const imageData = await dataImage.url.blob();
      // console.log(imageData);

      const docID = await addDocumentFirebase(
        "files",
        {
          type: "image",
          catgeory: "image-creation",
          link: dataImage.url,
          name: name,
        },
        globalState.currentCompany
      );

      toast({
        title: "Deoapp AI",
        description: "Image saved to your collection",
        status: "success",
        duration: 2000,
      });
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
      toast({
        title: "Deoapp AI",
        description: `${error}`,
        status: "error",
        duration: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenSaveModal = (detail) => {
    onOpen();

    setDataImage(detail);
  };

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };
  return (
    <Box>
      <BackButtons />
      <Heading mt={5}>Generate Image With AI</Heading>
      <Flex my={5} gap={5}>
        <Stack w={"40%"}>
          <FormControl>
            <FormLabel>What Image do you want?</FormLabel>
            <Textarea
              placeholder="Describe an image as detailed as possible . Example: A Koala sitting in a branch with sunglasses, cartoon style"
              onChange={(e) => setData({ ...data, message: e.target.value })}
              bgColor="white"
              color="black"
              sx={inputStyles}
            />
          </FormControl>
          <FormControl>
            <FormLabel>How many image do you want?</FormLabel>
            <Input
              type="number"
              placeholder="How many image do you want"
              onChange={(e) => setData({ ...data, quantity: e.target.value })}
              bgColor="white"
              color="black"
              sx={inputStyles}
            />
          </FormControl>

          {loading ? (
            <Button colorScheme="green" onClick={handleGenerateImage} isLoading>
              Generate
            </Button>
          ) : (
            <Button colorScheme="green" onClick={handleGenerateImage}>
              Generate
            </Button>
          )}
        </Stack>

        <Stack
          w={"60%"}
          border={"1px"}
          bg={"blackAlpha.400"}
          borderRadius={"md"}
        >
          {loading ? (
            <>
              <Loading />
            </>
          ) : (
            <>
              {generatedImage ? (
                <Center p={5}>
                  <Box>
                    <Text mb={5} align={"center"}>
                      Results Image
                    </Text>
                    {generatedImage.length > 0 ? (
                      <SimpleGrid columns={[2, null, 2]} spacing={3}>
                        {generatedImage.map((image) => (
                          <Stack>
                            <Box>
                              <Img src={image.url} />
                              {/* <Img src={image.b64_json} /> */}
                            </Box>
                            <Box align={"center"}>
                              <Button
                                w={"50%"}
                                size={"sm"}
                                colorScheme="green"
                                onClick={() => handleOpenSaveModal(image)}
                              >
                                Save Image
                              </Button>
                            </Box>
                          </Stack>
                        ))}
                      </SimpleGrid>
                    ) : (
                      <Text>Fill</Text>
                    )}
                  </Box>
                </Center>
              ) : (
                <Center p={2}>
                  <Text>The result will be display here</Text>
                </Center>
              )}
            </>
          )}
        </Stack>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Save Your Video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Image Name</FormLabel>
              <Input
                placeholder="Your Video Name"
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button size={"sm"} colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>

            {loading ? (
              <Button
                size={"sm"}
                colorScheme="green"
                onClick={handleSaveImage}
                isLoading
              >
                Save
              </Button>
            ) : (
              <Button size={"sm"} colorScheme="green" onClick={handleSaveImage}>
                Save
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ImageCreationPage;

// import { Icon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Icon,
  Image,
  Select,
  Spacer,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { FiHelpCircle, FiLogOut, FiSettings, FiUsers } from "react-icons/fi";
import store from "store";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

import themeConfig from "../../Config/themeConfig";
import { NavButton } from "./NavButton";
import { UserProfile } from "./UserProfile";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { auth, db } from "../../Config/firebase";
import { data, dataEdrus } from "./DataMenu";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import { signOut } from "firebase/auth";
import { logoutUserWithIp } from "../../Hooks/Middleware/sessionMiddleWare";
import { removeSymbols } from "../../Utils/nameUtil";
import { updateDocumentFirebase } from "../../Apis/firebaseApi";

// ** Theme Configuration

function SidebarComponentV2({ layout }) {
  const [desktopShow, setDesktopShow] = useState(true);

  const globalState = UseAuthStore();
  const [companyId, setCompanyId] = useState(
    localStorage.getItem("currentCompany") || ""
  );
  const [projectId, setProjectId] = useState(
    localStorage.getItem("currentProject") || ""
  );
  // const [dataToUse, setDataToUse] = useState(data);

  const toast = useToast();
  const navigate = useNavigate();

  const isDesktop = useBreakpointValue({ base: false, lg: desktopShow });

  useEffect(() => {
    const storedCompanyId = localStorage.getItem("currentCompany");
    setCompanyId(storedCompanyId || "");

    return () => {};
  }, [globalState.currentCompany]);

  useEffect(() => {
    const storedProjectId = localStorage.getItem("currentProject");
    setProjectId(storedProjectId || "");

    return () => {};
  }, [globalState.currentProject]);

  const logout = async () => {
    const email = globalState.email;
    const pathLink = "ai";
    await logoutUserWithIp(window.location.hostname, email, pathLink);

    signOut(auth)
      .then(() => {
        // Sign-out successful.
        toast({
          status: "success",
          description: "Logged out success",
          duration: 2000,
        });

        globalState.setIsLoggedIn(false);
        localStorage.removeItem("currentCompany");
        localStorage.removeItem("currentProject");
        navigate("/");
        store.clearAll();
      })
      .catch((error) => {
        console.log(error, "ini error");
      });
  };

  const handleClick = () => {
    setDesktopShow(!desktopShow);
  };

  const handleCompanySelect = (e) => {
    const dataCompany = globalState.companies;

    const findCompany = dataCompany.find((x) => x.id === e);

    localStorage.setItem("currentCompany", findCompany.id);
    // localStorage.setItem("currentProject", findProject.id);
    globalState.setCurrentCompany(findCompany.id || e);
    globalState.setCurrentXenditId(findCompany?.xenditId);

    if (findCompany.owner && findCompany.owner.includes(e)) {
      // Jika iya, tambahkan field "owner" ke dalam objek data[0]
      globalState.setRoleProject("owner");
    } else if (findCompany.managers && findCompany.managers.includes(e)) {
      globalState.setRoleProject("managers");
    } else {
      globalState.setRoleProject("user");
    }

    const updateCompany = updateDocumentFirebase("users", globalState.uid, {
      currentCompany: findCompany.id,
    });
  };

  const handleProjectSelect = (e) => {
    const dataProject = globalState.projects;

    const findProject = dataProject.find((x) => x.id === e);
    localStorage.setItem("currentProject", findProject.id);
    globalState.setCurrentProject(findProject.id || e);

    if (findProject.owner && findProject.owner.includes(e)) {
      // Jika iya, tambahkan field "owner" ke dalam objek data[0]
      globalState.setRoleProject("owner");
    } else if (findProject.managers && findProject.managers.includes(e)) {
      globalState.setRoleProject("managers");
    } else {
      globalState.setRoleProject("user");
    }

    const updateProject = updateDocumentFirebase("users", globalState.uid, {
      currentProject: findProject.id,
    });
  };

  if (layout.type === "vertical" || layout.type === "vertical-horizontal")
    return (
      <Box
        height="full"
        width={isDesktop ? "auto" : "150px"}
        transition={"0.2s ease-in-out"}
        display={"initial"}
        overflowY="auto"
        shadow={"xl"}
        overflow="wrap"
        roundedBottomRight={"lg"}
        roundedTopRight={"lg"}
        bg={themeConfig.color.colorFirst}
      >
        <Box position="sticky" overflowY="auto">
          <Stack
            position={"absolute"}
            right={0}
            cursor={"pointer"}
            onClick={handleClick}
            alignItems="flex-end"
            justifyContent={"flex-end"}
            p={2}
          >
            {desktopShow ? (
              <IoIosArrowBack size={20} />
            ) : (
              <IoIosArrowForward size={20} />
            )}
          </Stack>
          <Flex as="section" minH="100vh">
            <Flex
              flex="1"
              bg="bg-surface"
              boxShadow="sm"
              maxW={{
                base: "full",
                sm: "xs",
              }}
              py={{
                base: "4",
                sm: "6",
              }}
              px={{
                base: "4",
                sm: "6",
              }}
            >
              <Stack justify="space-between" spacing="1">
                <Stack spacing={4} shouldWrapChildren>
                  {isDesktop ? (
                    <Center>
                      <Image src={themeConfig.logo} maxH={100} />
                    </Center>
                  ) : (
                    <Center>
                      <Image
                        src={themeConfig.logokotak}
                        borderRadius="full"
                        maxH={50}
                      />
                    </Center>
                  )}

                  <Stack alignItems={"center"}>
                    <Select
                      w={["100%", "100%", "100%"]}
                      size={"sm"}
                      value={globalState.currentCompany}
                      // defaultValue={companyId}
                      // defaultValue={companyId}
                      onChange={(e) => {
                        handleCompanySelect(e.target.value);
                      }}
                    >
                      {globalState.companies?.map((select, i) => (
                        <option
                          // selected={companyId}
                          // defaultValue={companyId}
                          key={i}
                          value={select?.id}
                        >
                          <Text textTransform={"capitalize"}>
                            {select?.name}
                          </Text>
                        </option>
                      ))}
                    </Select>
                  </Stack>

                  <Stack alignItems={"center"}>
                    <Select
                      w={["100%", "100%", "100%"]}
                      size={"sm"}
                      value={globalState.currentProject}
                      // defaultValue={projectId}
                      // defaultValue={projectId}
                      onChange={(e) => {
                        handleProjectSelect(e.target.value);
                      }}
                    >
                      {globalState?.projects?.map((select, i) => (
                        <option
                          // selected={globalState.currentProject}
                          // defaultValue={projectId}
                          key={i}
                          value={select?.id}
                        >
                          <Text textTransform={"capitalize"}>
                            {select?.name}
                          </Text>
                        </option>
                      ))}
                    </Select>
                  </Stack>

                  <Stack alignItems={"center"}>
                    <Accordion allowToggle>
                      {data?.map((x, index) => {
                        if (
                          globalState.currentCompany !==
                            "8NCG4Qw0xVbNR6JCcJw1" &&
                          x.name === "Templates"
                        ) {
                          return null;
                        }

                        return (
                          <AccordionItem
                            key={index}
                            isDisabled={
                              // x.name === "Image" ||
                              x.name === "Audio" ||
                              // x.name === "Video" ||
                              x.name === "Marketer"
                                ? true
                                : false
                            }
                          >
                            <h2>
                              <AccordionButton>
                                <HStack spacing={2}>
                                  <Icon
                                    as={x.icon}
                                    boxSize={isDesktop ? 5 : 7}
                                  />
                                  {isDesktop ? (
                                    <>
                                      <Text
                                        fontWeight={500}
                                        fontSize="sm"
                                        noOfLines={1}
                                      >
                                        {x.name}
                                      </Text>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </HStack>
                                <Spacer />

                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            {x.submenu ? (
                              <>
                                <AccordionPanel>
                                  <Stack>
                                    {x.submenu?.map((subitem, i) => (
                                      <Link
                                        key={i}
                                        to={
                                          x.name === "Page Builder" ||
                                          x.name === "Train Your AI" ||
                                          x.name === "Video" ||
                                          x.name === "Image" ||
                                          x.name === "Templates"
                                            ? subitem.link
                                            : `folder-aissistant/${subitem.name}`
                                        }
                                      >
                                        <HStack spacing="3">
                                          <Icon as={subitem.icon} boxSize={5} />
                                          {isDesktop ? (
                                            <>
                                              <Text
                                                fontWeight={500}
                                                fontSize="sm"
                                                noOfLines={1}
                                                textTransform="capitalize"
                                              >
                                                {subitem.name}
                                              </Text>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </HStack>
                                      </Link>
                                    ))}
                                  </Stack>
                                </AccordionPanel>
                              </>
                            ) : (
                              <>{null}</>
                            )}
                          </AccordionItem>
                        );
                      })}
                    </Accordion>
                  </Stack>
                </Stack>

                <Stack
                  spacing={{
                    base: "5",
                    sm: "6",
                  }}
                >
                  {isDesktop ? (
                    <Stack spacing="1">
                      <Button
                        as={Link}
                        to={"/account"}
                        variant="ghost"
                        justifyContent="start"
                        size={"sm"}
                      >
                        <HStack spacing="3">
                          <Icon as={FiSettings} boxSize="4" color="subtle" />
                          <Text fontSize={"sm"}>Settings</Text>
                        </HStack>
                      </Button>
                      <NavButton label="Help" icon={FiHelpCircle} />
                      {/* <NavButton label="Settings" icon={FiSettings} /> */}

                      <Button
                        icon={FiLogOut}
                        onClick={() => logout()}
                        size="sm"
                        colorScheme={"red"}
                      >
                        Logout
                      </Button>
                    </Stack>
                  ) : (
                    <Stack spacing="1" alignItems={"center"}>
                      <Icon
                        as={FiHelpCircle}
                        aria-current="page"
                        boxSize={"50px"}
                        p={3}
                        borderRadius="md"
                        cursor={"pointer"}
                        shadow="inherit"
                      />
                      <Icon
                        as={FiSettings}
                        aria-current="page"
                        boxSize={"50px"}
                        p={3}
                        borderRadius="md"
                        cursor={"pointer"}
                        shadow="inherit"
                      />

                      {isDesktop ? (
                        <Button
                          icon={FiLogOut}
                          onClick={() => logout()}
                          size="sm"
                          colorScheme={"red"}
                        >
                          Logout
                        </Button>
                      ) : (
                        <Icon
                          as={FiLogOut}
                          aria-current="page"
                          boxSize={"40px"}
                          bgColor="red.400"
                          p={3}
                          borderRadius="md"
                          cursor={"pointer"}
                          shadow="inherit"
                          color={"white"}
                          onClick={() => logout()}
                        />
                      )}
                    </Stack>
                  )}

                  {/* <Button onClick={() => console.log(globalState)}>Check</Button> */}

                  {isDesktop ? (
                    layout.type === "vertical-horizontal" &&
                    layout.userProfile === "sidebar" ? (
                      <>
                        <Divider />

                        <UserProfile
                          name={globalState.name}
                          image={
                            globalState.photoURL === null
                              ? "https://tinyurl.com/yhkm2ek8"
                              : globalState.photoURL
                          }
                          email={globalState.email}
                          onClick={() => console.log(globalState, "xxx")}
                        />
                      </>
                    ) : layout.type === "vertical" ? (
                      <>
                        <Divider />

                        <UserProfile
                          name={globalState.name}
                          image={
                            globalState.photoURL === null
                              ? "https://tinyurl.com/yhkm2ek8"
                              : globalState.photoURL
                          }
                          email={globalState.email}
                          onClick={() => console.log(globalState, "xxx")}
                        />
                      </>
                    ) : (
                      <></>
                    )
                  ) : layout.type === "vertical-horizontal" &&
                    layout.userProfile === "sidebar" ? (
                    <Stack alignItems={"center"}>
                      <Divider />

                      <UserProfile
                        name={globalState.name}
                        image={
                          globalState.photoURL === null
                            ? "https://tinyurl.com/yhkm2ek8"
                            : globalState.photoURL
                        }
                        onClick={() => console.log(globalState, "xxx")}
                      />
                    </Stack>
                  ) : layout.type === "vertical" ? (
                    <Stack alignItems={"center"}>
                      <Divider />

                      <UserProfile
                        onClick={() => console.log(globalState, "xxx")}
                        image={
                          globalState.photoURL === null
                            ? "https://tinyurl.com/yhkm2ek8"
                            : globalState.photoURL
                        }
                      />
                    </Stack>
                  ) : (
                    <></>
                  )}
                </Stack>
              </Stack>
            </Flex>
          </Flex>
        </Box>
      </Box>
    );

  return <></>;
}

export default SidebarComponentV2;

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React from "react";

const DomainSetupPage = () => {
  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };
  return (
    <Stack spacing={3}>
      <Box>
        <Heading>Domain Setup</Heading>
      </Box>
      <SimpleGrid columns={[1, null, 2]} spacing={5}>
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input
            bgColor="white"
            color="black"
            sx={inputStyles}
            resize="none"
            placeholder="name"
          />
        </FormControl>
        <FormControl>
          <FormLabel>Domain</FormLabel>
          <Select bgColor="white" color="black" sx={inputStyles} resize="none">
            <option>your web</option>
            <option>your web 2</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Path</FormLabel>
          <Input
            bgColor="white"
            color="black"
            sx={inputStyles}
            resize="none"
            placeholder="name"
          />
        </FormControl>
        <FormControl>
          <FormLabel>Url</FormLabel>
          <Input
            bgColor="white"
            color="black"
            sx={inputStyles}
            resize="none"
            placeholder="www.website.com"
          />
          <Text size={"sm"}>
            Add a url to your .png. For all your pages inside your funnel
          </Text>
        </FormControl>
        <FormControl>
          <FormLabel>Head tracking Code</FormLabel>
          <Input
            bgColor="white"
            color="black"
            sx={inputStyles}
            resize="none"
            as={Textarea}
            placeholder="Html head code"
          />
          <Text size={"sm"}>Funnel wide tracking code for the head tag</Text>
        </FormControl>
        <FormControl>
          <FormLabel>Body tracking Code</FormLabel>
          <Input
            bgColor="white"
            color="black"
            sx={inputStyles}
            resize="none"
            as={Textarea}
            placeholder="html body code"
          />
          <Text size={"sm"}>Funnel wide tracking code for the body tag</Text>
        </FormControl>
      </SimpleGrid>
      <Box my={4}>
        <Button colorScheme={"green"} size={"sm"}>
          Save
        </Button>
      </Box>
    </Stack>
  );
};

export default DomainSetupPage;

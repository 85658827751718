import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FcPlus } from "react-icons/fc";
import {
  deleteDocumentFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from "../../Apis/firebaseApi";
import {
  TiEdit,
  TiFeather,
  TiHeadphones,
  TiMessageTyping,
  TiMessages,
  TiNews,
  TiPuzzleOutline,
  TiSortAlphabeticallyOutline,
  TiStarFullOutline,
  TiThumbsOk,
  TiTrash,
  TiWaves,
} from "react-icons/ti";
import { useNavigate, useParams } from "react-router-dom";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../Config/firebase";
import BackButtons from "../../Components/Button/BackButtons";
import moment from "moment";

const LandingpageTrainingTemplateContent = () => {
  const navigate = useNavigate();
  const [folder, setFolder] = useState();
  const [templates, setTemplates] = useState();
  const [data, setData] = useState();
  const toast = useToast();
  const [inputSearchTemplate, setInputSearchTemplate] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const globalState = UseAuthStore();
  const deleteModal = useDisclosure();
  const [dataTemplate, setDataTemplate] = useState();
  const param = useParams();

  const getTemplateData = async () => {
    // try {
    //   const res = await getCollectionFirebase(`templates/${param.id}/prompt`);

    //   setData(res);
    // } catch (error) {
    //   console.log(error, "ini error");
    // }

    const q = query(collection(db, `templates/${param.id}/prompt`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const dataArr = [];
      querySnapshot.forEach((doc) => {
        dataArr.push({ ...doc.data(), id: doc.id });
      });

      setData(dataArr);
    });
  };

  const getFolderData = async () => {
    try {
      const result = await getSingleDocumentFirebase("templates", param.id);

      setFolder(result);
    } catch (error) {
      console.log(error);
    }
  };

  const searchWebTemplate = (text) => {
    if (text) {
      const newData = data?.filter((item) => {
        const itemData = item.title
          ? item.title.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setSearchedData(newData);
      setInputSearchTemplate(text);
    } else {
      setSearchedData(data);
      setInputSearchTemplate(text);
    }
  };

  const getuserTemplates = async () => {
    const q = query(
      collection(db, "templates"),
      // where("createdBy", "==", globalState.uid),
      where("type", "==", "website")
      // where("companyId", "==", globalState.currentCompany)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const templateArr = [];
      querySnapshot.forEach((doc) => {
        templateArr.push({ ...doc.data(), id: doc.id });
      });

      setTemplates(templateArr);
    });
  };

  const handleOpenModal = (template) => {
    deleteModal.onOpen();
    setDataTemplate(template);
  };

  const handleDeleteTemplate = async () => {
    try {
      const result = await deleteDocumentFirebase(
        `templates/${param.id}/prompt`,
        dataTemplate?.id
      );

      toast({
        status: "success",
        title: "Deoapp AI",
        description: "Template Deleted",
        duration: 1000,
      });

      deleteModal.onClose();
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
    }
  };

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  useEffect(() => {
    getTemplateData();
    getFolderData();
  }, []);

  return (
    <Box>
      <BackButtons />
      <HStack my={5}>
        <Heading>{folder?.title} Collections</Heading>
        <Spacer />
        <Button
          colorScheme="green"
          variant="outline"
          onClick={() => navigate(`/lptrain/${folder.id}/create`)}
          // onClick={handleNewTemplate}
        >
          <HStack spacing={2}>
            <FcPlus />
            <Text textTransform="uppercase" fontSize="sm" fontWeight={500}>
              New Folder
            </Text>
          </HStack>
        </Button>
      </HStack>

      <Input
        type="text"
        placeholder="Search Files ..."
        bgColor="white"
        color="black"
        sx={inputStyles}
        fontSize="sm"
        onChange={(e) => searchWebTemplate(e.target.value)}
      />

      {data?.length === 0 && searchedData?.length === 0 ? (
        <Spinner />
      ) : (
        <SimpleGrid columns={4} spacing={4} my={8}>
          {inputSearchTemplate === "" ? (
            <>
              {data?.map((x, i) => {
                return (
                  <Stack
                    _hover={{
                      transform: "scale(1.03)",
                      shadow: "xl",
                    }}
                    transition={"0.2s ease-in-out"}
                    spacing={2}
                    borderTopWidth={3}
                    borderColor="green.500"
                    alignItems="center"
                    justifyContent="start"
                    bgColor="#2B2B2B"
                    key={i}
                    py={4}
                    px={2}
                    borderRadius="md"
                    shadow="md"
                    // Tambahkan properti disabled jika id kosong
                    disabled={!x.id}
                    opacity={!x.id ? 0.5 : 1}
                    cursor={!x.id ? "not-allowed" : "pointer"}
                  >
                    <Stack
                      p={5}
                      onClick={() => {
                        if (x.id) {
                          navigate(`/lptrain/${param.id}/view/${x.id}`);
                        }
                      }}
                    >
                      <Icon
                        as={TiFeather}
                        color={x.iconColor}
                        boxSize={12}
                        alignSelf="center"
                      />
                    </Stack>
                    <Box align={"center"}>
                      <Text fontWeight="bold" textTransform={"capitalize"}>
                        {x?.title} Template
                      </Text>
                    </Box>
                    <HStack fontSize={"md"}>
                      <Text fontSize="2xs">
                        {moment
                          .unix(
                            x?.lastUpdated?.seconds ?? x?.createdAt?.seconds
                          )
                          .fromNow()}
                      </Text>
                      <Spacer />
                      <Icon as={TiTrash} onClick={() => handleOpenModal(x)} />
                    </HStack>
                  </Stack>
                );
              })}
            </>
          ) : (
            <>
              {searchedData?.map((x, i) => {
                return (
                  <Stack
                    _hover={{
                      transform: "scale(1.03)",
                      shadow: "xl",
                    }}
                    transition={"0.2s ease-in-out"}
                    spacing={2}
                    borderTopWidth={3}
                    borderColor="green.500"
                    alignItems="center"
                    justifyContent="start"
                    bgColor="#2B2B2B"
                    key={i}
                    py={4}
                    px={2}
                    borderRadius="md"
                    shadow="md"
                    onClick={() => {
                      if (x.id) {
                        navigate(
                          `/template-web/tmGwKieqFWt7ura1LvU4/view/${x.id}`
                        );
                      }
                    }}
                    // Tambahkan properti disabled jika id kosong
                    disabled={!x.id}
                    opacity={!x.id ? 0.5 : 1}
                    cursor={!x.id ? "not-allowed" : "pointer"}
                  >
                    <Stack
                      p={5}
                      onClick={() => {
                        if (x.id) {
                          navigate(`/lptrain/${param.id}/view/${x.id}`);
                        }
                      }}
                    >
                      <Icon
                        as={TiFeather}
                        color={x.iconColor}
                        boxSize={12}
                        alignSelf="center"
                      />
                    </Stack>
                    <Box align={"center"}>
                      <Text fontWeight="bold" textTransform={"capitalize"}>
                        {x?.title}
                      </Text>
                    </Box>
                    <HStack fontSize={"md"}>
                      <Text fontSize="2xs">
                        {moment
                          .unix(
                            x?.lastUpdated?.seconds ?? x?.createdAt?.seconds
                          )
                          .fromNow()}
                      </Text>
                      <Spacer />
                      <Icon as={TiTrash} onClick={() => handleOpenModal(x)} />
                    </HStack>
                  </Stack>
                );
              })}
            </>
          )}
        </SimpleGrid>
      )}

      <Modal isOpen={deleteModal.isOpen} onClose={deleteModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{dataTemplate?.title} Template</ModalHeader>
          <ModalBody>Template will be deleted, are you sure?</ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                size={"sm"}
                colorScheme="green"
                onClick={handleDeleteTemplate}
              >
                Yes
              </Button>
              <Button
                size={"sm"}
                colorScheme="red"
                onClick={deleteModal.onClose}
              >
                No
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default LandingpageTrainingTemplateContent;

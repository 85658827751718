import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FcPlus } from "react-icons/fc";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from "../../Apis/firebaseApi";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import { aissistantTemplates } from "./aisistantDataTemplate";
import { capitalize } from "../../Utils/capitalizeUtil";
import {
  FaBlog,
  FaFacebook,
  FaInstagram,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import { clientTypessense } from "../../Apis/Typesense";
import { LockIcon, Search2Icon } from "@chakra-ui/icons";
import {
  TiEdit,
  TiFeather,
  TiHeadphones,
  TiMessages,
  TiMessageTyping,
  TiNews,
  TiPuzzleOutline,
  TiSortAlphabeticallyOutline,
  TiStarFullOutline,
  TiThumbsOk,
  TiWaves,
  TiLeaf,
} from "react-icons/ti";
import { query, where, orderBy, startAfter, limit } from "firebase/firestore";
import { collection, getDocs } from "@firebase/firestore";
import { db } from "../../Config/firebase";

function AissistantPage() {
  const globalState = UseAuthStore();
  const [data, setData] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [customTemplate, setCustomTemplate] = useState([]);
  const [dataSearchedFile, setDataSearchedFile] = useState(data);
  const [inputSearch, setInputSearch] = useState("");
  const [lastDoc, setLastDoc] = useState(null);
  const [inputSearchFile, setInputSearchFile] = useState("");
  const [dataSearchedTemplate, setDataSearchedTemplate] = useState(templates);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [lastUpdated, setLastUpdated] = useState(null);
  const navigate = useNavigate();
  const param = useParams();
  const toast = useToast({
    position: "top",
    align: "center",
  });
  console.log(templates,'oo')

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = templates.filter((item) => {
        const itemData = item.title
          ? item.title.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setDataSearchedTemplate(newData);
      setInputSearch(text);
    } else {
      setDataSearchedTemplate(templates);
      setInputSearch(text);
    }
  };

  const searchFileFilterFunction = (text) => {
    if (text) {
      const newData = data.filter((item) => {
        const itemData = item.title
          ? item.title.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setDataSearchedFile(newData);
      setInputSearchFile(text);
    } else {
      setDataSearchedFile(data);
      setInputSearchFile(text);
    }
  };

  const getData = async (date) => {
    if (!globalState?.currentCompany) {
      return toast({
        title: "Error",
        description: "Please check your select company",
        status: "error",
      });
    }
    if (!globalState?.currentProject) {
      return toast({
        title: "Error",
        description: "Please check your select project",
        status: "error",
      });
    }

    try {
      let q = query(
        collection(db, "files"),
        where("companyId", "==", globalState.currentCompany),
        where("projectId", "==", globalState.currentProject),
        where("socialMedia", "==", param.name),
        orderBy("createdAt", "desc")
      );

      if (lastDoc) {
        q = query(q, startAfter(lastDoc));
      }

      q = query(q, limit(9));

      const querySnapshot = await getDocs(q);

      const items = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() });
      });

      setData((prevData) => [...prevData, ...items]);

      if (querySnapshot.size > 0) {
        const lastVisible = querySnapshot.docs[querySnapshot.size - 1];
        setLastDoc(lastVisible);
      } else {
        setLastDoc(null); // If no more documents to fetch, set lastDoc to null
      }
    } catch (error) {
      console.log("Error getting documents: ", error);
    }

    // const conditions = [
    //   { field: "companyId", operator: "==", value: globalState.currentCompany },
    //   { field: "socialMedia", operator: "==", value: param.name },
    //   { field: "projectId", operator: "==", value: globalState.currentProject },
    // ];

    // const sortBy = { field: "createdAt", direction: "desc" };
    // const limitValue = 10;
    // // const startAfterData = 10;

    // getCollectionFirebase(
    //   "files",
    //   conditions,
    //   sortBy,
    //   limitValue
    //   // startAfterData
    // )
    //   .then((x) => {
    //     if (x.length > 0) {
    //       console.log(x, "wkwkw");
    //       setData((prevData) => [...prevData, ...x]);
    //       setLastUpdated(x[x.length - 1].lastUpdated);
    //     } else {
    //       setLastUpdated(null);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err.message);
    //     toast({
    //       title: "Error",
    //       description: err.message,
    //       status: "error",
    //     });
    //   });
  };

  const getDataTemplate = async () => {
    const conditions = [
      {
        field: "category",
        operator: "==",
        value: "aissistant-copywriting",
      },
      {
        field: "type",
        operator: "==",
        value: "openai",
      },
    ];
    const sortBy = { field: "name", direction: "asc" };
    // const limitValue = 10;

    try {
      const res = await getCollectionFirebase("templates", conditions, sortBy);
      console.log(res, "template collection");
      setTemplates(res);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const getDataCustomTemplate = async () => {
    const conditions = [
      {
        field: "category",
        operator: "==",
        value: "aissistant-copywriting",
      },
      {
        field: "companyId",
        operator: "==",
        value: globalState.currentCompany,
      },
      {
        field: "type",
        operator: "==",
        value: "openai-custom",
      },
    ];
    // const sortBy = { field: "name", direction: "desc" };
    // const limitValue = 10;

    try {
      const res = await getCollectionFirebase("templates", conditions);
      console.log(res, "custom template collection");
      setCustomTemplate(res);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const handleNewAi = () => {
    getDataTemplate();
    getDataCustomTemplate();
    onOpen();
  };

  const handleSearchFiles = (q) => {
    console.log(q);
    if (!q) {
      return getData(null);
    }

    const searchParameters = {
      q: q,
      query_by: "title",
      filter_by: `type:text`,
      // sort_by: "_text_match:desc",
    };

    clientTypessense
      .collections("files")
      .documents()
      .search(searchParameters)
      .then((x) => {
        const hits = x.hits.map((x) => x.document);
        setData(hits);
        console.log(hits);
        // setLastUpdated(null);
      })
      .catch((err) => {
        console.log(err.message);
        toast({
          title: "Error",
          description: err.message,
          status: "error",
        });
      });
  };

  const loadMoreData = () => {
    getData(lastUpdated);
  };

  useEffect(() => {
    getData(null);
    return () => {
      setData([]);
      setLastUpdated(null);
    };
  }, [globalState.currentProject, param.name]);

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  return (
    <>
      <Stack p={[1, 1, 5]} spacing={5}>
        <HStack>
          <Heading>Aissistant - {capitalize(param.name)}</Heading>
          <Spacer />
          <Button
            colorScheme="green"
            variant="outline"
            // onClick={() => navigate(`/create-aissistant/${param.name}`)}
            onClick={handleNewAi}
          >
            <HStack spacing={2}>
              <FcPlus />
              <Text textTransform="uppercase" fontSize="sm" fontWeight={500}>
                New AI
              </Text>
            </HStack>
          </Button>
        </HStack>

        <Input
          type="text"
          placeholder="Search Files ..."
          bgColor="white"
          color="black"
          sx={inputStyles}
          fontSize="sm"
          onChange={(e) => searchFileFilterFunction(e.target.value)}
        />

        {inputSearchFile !== "" ? (
          <>
            {dataSearchedFile.length > 0 ? (
              <SimpleGrid columns={[1, 2, 3]} gap="4">
                {dataSearchedFile?.map((x, i) => (
                  <Stack
                    key={i}
                    _hover={{
                      transform: "scale(1.05)",
                      shadow: "xl",
                    }}
                    transition="0.2s ease-in-out"
                    spacing={2}
                    borderTopWidth={3}
                    borderColor="green.500"
                    bgColor="#2B2B2B"
                    py={4}
                    px={2}
                    borderRadius="md"
                    shadow="md"
                  >
                    <Link to={`view/${x.id}`}>
                      <HStack spacing={2}>
                        <Stack p={5}>
                          {x.socialMedia === "facebook" && (
                            <Icon
                              as={FaFacebook}
                              color={"blue.300"}
                              boxSize={12}
                            />
                          )}
                          {x.socialMedia === "instagram" && (
                            <Icon
                              as={FaInstagram}
                              color={"red.300"}
                              boxSize={12}
                            />
                          )}
                          {x.socialMedia === "youtube" && (
                            <Icon
                              as={FaYoutube}
                              // color={
                              //   aissistantTemplates.find((z) => z.id === x.template)
                              //     .iconColor
                              // }
                              boxSize={12}
                            />
                          )}
                          {x.socialMedia === "tiktok" && (
                            <Icon as={FaTiktok} boxSize={12} />
                          )}
                          {x.socialMedia === "blog" && (
                            <Icon as={FaBlog} boxSize={12} />
                          )}
                        </Stack>
                        <Box>
                          <Text textTransform="capitalize" fontWeight={500}>
                            {x.title}
                          </Text>
                          <Text textTransform="capitalize" fontSize={12}>
                            {x.category}
                          </Text>
                        </Box>
                      </HStack>
                    </Link>

                    <Stack alignItems="flex-end" justifyContent="flex-end">
                      <Text fontSize="2xs">
                        {moment
                          .unix(
                            x?.lastUpdated?.seconds ?? x?.createdAt?.seconds
                          )
                          .fromNow()}
                      </Text>
                    </Stack>
                  </Stack>
                ))}
              </SimpleGrid>
            ) : (
              <Stack fontSize="md">
                <Text textAlign="center" fontWeight="semibold">
                  No Data, Create your first copywritting
                </Text>
                <Text textAlign="center" fontWeight="semibold">
                  by clicking New AI button
                </Text>
              </Stack>
            )}
          </>
        ) : (
          <SimpleGrid columns={[1, 2, 3]} gap="4">
            {data?.map((x, i) => (
              <Stack
                key={i}
                _hover={{
                  transform: "scale(1.05)",
                  shadow: "xl",
                }}
                transition="0.2s ease-in-out"
                spacing={2}
                borderTopWidth={3}
                borderColor="green.500"
                bgColor="#2B2B2B"
                py={4}
                px={2}
                borderRadius="md"
                shadow="md"
              >
                <Link to={`view/${x.id}`}>
                  <HStack spacing={2}>
                    <Stack p={5}>
                      {x.socialMedia === "facebook" && (
                        <Icon as={FaFacebook} color={"blue.300"} boxSize={12} />
                      )}
                      {x.socialMedia === "instagram" && (
                        <Icon as={FaInstagram} color={"red.300"} boxSize={12} />
                      )}
                      {x.socialMedia === "youtube" && (
                        <Icon
                          as={FaYoutube}
                          // color={
                          //   aissistantTemplates.find((z) => z.id === x.template)
                          //     .iconColor
                          // }
                          boxSize={12}
                        />
                      )}
                      {x.socialMedia === "tiktok" && (
                        <Icon as={FaTiktok} boxSize={12} />
                      )}
                      {x.socialMedia === "blog" && (
                        <Icon as={FaBlog} boxSize={12} />
                      )}
                    </Stack>
                    <Box>
                      <Text textTransform="capitalize" fontWeight={500}>
                        {x.title}
                      </Text>
                      <Text textTransform="capitalize" fontSize={12}>
                        {x.category}
                      </Text>
                    </Box>
                  </HStack>
                </Link>

                <Stack alignItems="flex-end" justifyContent="flex-end">
                  <Text fontSize="2xs">
                    {moment
                      .unix(x?.lastUpdated?.seconds ?? x?.createdAt?.seconds)
                      .fromNow()}
                  </Text>
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        )}

        <Center>
          {dataSearchedFile === 0 || inputSearchFile !== "" ? null : (
            <>
              {data?.length % 9 === 0 && (
                <Button mt="2" colorScheme="green" onClick={loadMoreData}>
                  Load more
                </Button>
              )}
            </>
          )}
        </Center>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose Post Type</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Search2Icon color="gray.300" />
                </InputLeftElement>
                <Input
                  onChange={(e) => searchFilterFunction(e.target.value)}
                  bgColor="white"
                  color="black"
                  sx={inputStyles}
                  fontSize="sm"
                  placeholder="Search templates..."
                />
              </InputGroup>
            </Box>
            <Tabs isFitted colorScheme="green" mt={5}>
              <TabList>
                <Tab>Templates</Tab>
                <Tab>Your Templates</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Box
                    my={2}
                    height={500}
                    w={"100%"}
                    overflowY={"auto"}
                    overflowX={"hidden"}
                    sx={{
                      "&::-webkit-scrollbar": {
                        w: "2",
                        h: "3",
                      },
                      "&::-webkit-scrollbar-track": {
                        w: "6",
                        h: "5",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "10",
                        bg: `gray.600`,
                      },
                    }}
                  >
                    <SimpleGrid my={3} columns="3" gap="3">
                      {dataSearchedTemplate.length === 0 ? (
                        <>
                          {templates?.map((x, i) => {
                            return (
                              <Stack
                                _hover={{
                                  transform: "scale(1.05)",
                                  shadow: "xl",
                                }}
                                transition={"0.2s ease-in-out"}
                                spacing={2}
                                borderTopWidth={3}
                                borderColor="green.500"
                                alignItems="center"
                                justifyContent="start"
                                bgColor="#2B2B2B"
                                key={i}
                                py={4}
                                px={2}
                                borderRadius="md"
                                shadow="md"
                                onClick={() => {
                                  if (x.id) {
                                    navigate(
                                      `/create-aissistant/${param.name}/${x.title}`
                                    );
                                  }
                                }}
                                // Tambahkan properti disabled jika id kosong
                                disabled={!x.id}
                                opacity={!x.id ? 0.5 : 1}
                                cursor={!x.id ? "not-allowed" : "pointer"}
                              >
                                <Stack p={5}>
                                  <Icon
                                    as={
                                      x.title === "funfact"
                                        ? TiPuzzleOutline
                                        : x.title === "headline"
                                        ? TiSortAlphabeticallyOutline
                                        : x.title === "review"
                                        ? TiThumbsOk
                                        : x.title === "story"
                                        ? TiMessageTyping
                                        : x.title === "podcast"
                                        ? TiMessages
                                        : x.title === "motivation"
                                        ? TiStarFullOutline
                                        : x.title === "comparing"
                                        ? TiWaves
                                        : x.title === "tutorial"
                                        ? TiNews
                                        : x.title === "caption"
                                        ? TiEdit
                                        : x.title === "quiz"
                                        ? TiHeadphones
                                        : TiFeather
                                    }
                                    color={x.iconColor}
                                    boxSize={12}
                                    alignSelf="center"
                                  />
                                </Stack>
                                <Box align={"center"}>
                                  <Text
                                    fontWeight="bold"
                                    textTransform={"capitalize"}
                                  >
                                    {x.title}
                                  </Text>
                                  <Text>{x.description}</Text>
                                </Box>
                              </Stack>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {dataSearchedTemplate?.map((x, i) => {
                            return (
                              <Stack
                                _hover={{
                                  transform: "scale(1.05)",
                                  shadow: "xl",
                                }}
                                transition={"0.2s ease-in-out"}
                                spacing={2}
                                borderTopWidth={3}
                                borderColor="green.500"
                                alignItems="center"
                                justifyContent="start"
                                bgColor="#2B2B2B"
                                key={i}
                                py={4}
                                px={2}
                                borderRadius="md"
                                shadow="md"
                                onClick={() => {
                                  if (x.id) {
                                    navigate(
                                      `/create-aissistant/${param.name}/${x.title}`
                                    );
                                  }
                                }}
                                // Tambahkan properti disabled jika id kosong
                                disabled={!x.id}
                                opacity={!x.id ? 0.5 : 1}
                                cursor={!x.id ? "not-allowed" : "pointer"}
                              >
                                <Stack p={5}>
                                  <Icon
                                    as={
                                      (x.title === "funfact" &&
                                        TiPuzzleOutline) ||
                                      (x.title === "headline" &&
                                        TiSortAlphabeticallyOutline) ||
                                      (x.title === "review" && TiThumbsOk) ||
                                      (x.title === "story" &&
                                        TiMessageTyping) ||
                                      (x.title === "podcast" && TiMessages) ||
                                      (x.title === "motivation" &&
                                        TiStarFullOutline) ||
                                      (x.title === "comparing" && TiWaves) ||
                                      (x.title === "tutorial" && TiNews) ||
                                      (x.title === "caption" && TiEdit) ||
                                      (x.title === "quiz" && TiHeadphones)
                                    }
                                    color={x.iconColor}
                                    boxSize={12}
                                    alignSelf="center"
                                  />
                                </Stack>
                                <Box align={"center"}>
                                  <Text
                                    fontWeight="bold"
                                    textTransform={"capitalize"}
                                  >
                                    {x.title}
                                  </Text>
                                  <Text>{x.description}</Text>
                                </Box>
                              </Stack>
                            );
                          })}
                        </>
                      )}
                    </SimpleGrid>
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box
                    my={2}
                    height={500}
                    w={"100%"}
                    overflowY={"auto"}
                    overflowX={"hidden"}
                    sx={{
                      "&::-webkit-scrollbar": {
                        w: "2",
                        h: "3",
                      },
                      "&::-webkit-scrollbar-track": {
                        w: "6",
                        h: "5",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "10",
                        bg: `gray.600`,
                      },
                    }}
                  >
                    <SimpleGrid my={3} columns="3" gap="3">
                      {customTemplate?.length > 0 ? (
                        <>
                          {customTemplate?.map((x, i) => {
                            return (
                              <Stack
                                _hover={{
                                  transform: "scale(1.05)",
                                  shadow: "xl",
                                }}
                                transition={"0.2s ease-in-out"}
                                spacing={2}
                                borderTopWidth={3}
                                borderColor="green.500"
                                alignItems="center"
                                justifyContent="start"
                                bgColor="#2B2B2B"
                                key={i}
                                py={4}
                                px={2}
                                borderRadius="md"
                                shadow="md"
                                onClick={() => {
                                  if (x.id) {
                                    navigate(
                                      `/create-aissistant/${param.name}/${x.title}`
                                    );
                                  }
                                }}
                                // Tambahkan properti disabled jika id kosong
                                disabled={!x.id}
                                opacity={!x.id ? 0.5 : 1}
                                cursor={!x.id ? "not-allowed" : "pointer"}
                              >
                                <Stack p={5}>
                                  <Icon
                                    as={TiFeather}
                                    color={x.iconColor}
                                    boxSize={12}
                                    alignSelf="center"
                                  />
                                </Stack>
                                <Box align={"center"}>
                                  <Text
                                    fontWeight="bold"
                                    textTransform={"capitalize"}
                                  >
                                    {x.title}
                                  </Text>
                                  <Text>{x.description}</Text>
                                </Box>
                              </Stack>
                            );
                          })}
                        </>
                      ) : (
                        <Center>
                          <Text>No Custom Template</Text>
                          <Button
                            colorScheme="green"
                            onClick={() => navigate("/texttrain")}
                          >
                            Create Your Custom Template Here
                          </Button>
                        </Center>
                      )}
                    </SimpleGrid>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AissistantPage;

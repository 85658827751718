import React from "react";
import { Route, Routes } from "react-router-dom";
import FunnelEditPageV2 from "../Pages/LadingPageV2/Funnels/FunnelEditPageV2";

// const EditorFunnelRouter = [
//   {
//     path: "/funnel/view/:id/edit",
//     element: <FunnelEditPageV2 />,
//   },
// ];

function EditorFunnelRouter() {
  return (
    <Routes>
      <Route path={"/funnel/view/:id/edit"} element={<FunnelEditPageV2 />} />
    </Routes>
  );
}
export default EditorFunnelRouter;

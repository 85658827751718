import React, { useEffect, useState } from "react";
import BackButtons from "../../../Components/Button/BackButtons";
import {
  Box,
  Button,
  Center,
  Flex,
  FormLabel,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { getCollectionFirebase } from "../../../Apis/firebaseApi";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { FiFeather } from "react-icons/fi";
import { generateChatCompletion16K } from "../../../Apis/openAi";
import Loading from "../../../Components/Loading/Loading";

const OneClickCreateLandingPage = () => {
  const [choosenTemplate, setChoosenTemplate] = useState();
  const [templateActive, setTemplateActive] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [aiGenerator, setAiGenerator] = useState();
  const [templates, setTemplates] = useState();
  const [result, setResult] = useState([]);
  const [link, setLink] = useState("");

  const getDataTemplate = async () => {
    const conditions = [{ field: "type", operator: "==", value: "website" }];
    // const sortBy = { field: "createdAt", direction: "asc" };
    // const limitValue = 10;

    try {
      const res = await getCollectionFirebase(
        "templates",
        conditions
        // sortBy,
        // limitValue
      );
      setTemplates(res);
      console.log(res);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const handleTemplate = async (x) => {
    console.log(x);
    try {
      const res = await getCollectionFirebase(`templates/${x.id}/prompt`);
      setChoosenTemplate(res);
      console.log(res);

      const data = res.map((html) => {
        return {
          id: html.id,
          title: html.title,
          code: html.code,
        };
      });

      setAiGenerator(data);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  console.log(aiGenerator, "ini generator");

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === aiGenerator?.length - 1 ? 0 : prevIndex + 1
    );
    console.log(currentIndex);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? aiGenerator?.length - 1 : prevIndex - 1
    );
    console.log(currentIndex);
  };

  // const generateAi = async () => {
  //   const results = [];

  //   try {
  //     setIsLoading(true);

  //     for (let i = 0; i < aiGenerator.length; i++) {
  //       const currentData = aiGenerator[i];
  //       const message = `Change all the sentence with \n\nMedian Keren Banget Sumpah Gak Boong\n\n and suit the color in this reference code with red color. The reference of the code is ${currentData.code}. Answer with the modified code, don't include any other sentence that's not a code structure`;

  //       const requestMessage = [
  //         {
  //           role: "system",
  //           content:
  //             "You’re a site layout creator that responds with information which will be used to configure the sections of a page on a website, based on the user-provided input. All text content should be as impressive and exciting as possible.",
  //         },
  //         { role: "user", content: message },
  //       ];

  //       const generate = await generateChatCompletion16K(requestMessage);

  //       results.push({
  //         data: currentData,
  //         result: generate.choices[0]?.message,
  //       });
  //     }
  //     setResult(results);
  //   } catch (error) {
  //     console.log(error, "ini error");
  //   } finally {
  //     setIsLoading(false);
  //   }

  //   // Now you have the results array containing the data and generated responses
  //   console.log(results);
  // };

  const generateAi = async () => {
    const results = [];
    setIsLoading(true);

    const generateWithPromise = async (currentData) => {
      const message = `Change all the element that has a text in this reference code to a sentence \n\nMedian Keren Banget Sumpah Gak Boong\n\n and suit the color in this reference code. The reference of the code is ${currentData.code}. Answer with only the modified code, don't include any other sentence that's not a code structure`;

      const requestMessage = [
        // {
        //   role: "system",
        //   content:
        //     "You’re a site layout creator that responds with information which will be used to configure the sections of a page on a website, based on the user-provided input. All text content should be as impressive and exciting as possible.",
        // },
        { role: "user", content: message },
      ];

      try {
        const generate = await generateChatCompletion16K(requestMessage);

        return {
          data: currentData,
          result: generate.choices[0]?.message,
        };
      } catch (error) {
        console.log(error, "ini error");
        return null;
      }
    };

    const promises = aiGenerator.map(generateWithPromise);

    try {
      const generatedResults = await Promise.all(promises);
      results.push(...generatedResults.filter((result) => result !== null));

      setResult(results);
    } catch (error) {
      console.log(error, "error during promise.all");
    } finally {
      setIsLoading(false);
      console.log(results, "ini resultnya");
    }
  };

  useEffect(() => {
    getDataTemplate();
  }, []);

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  return (
    <>
      <BackButtons />
      <Stack>
        <Heading my={5}>Magic Landing Page Builder</Heading>
        <Flex mb={5} gap={5} flexDir={"column"}>
          <Stack w={"100%"} spacing={3}>
            <FormLabel>Input Your Site Link Here</FormLabel>
            <Input
              type="text"
              bgColor="white"
              color="black"
              sx={inputStyles}
              fontSize="sm"
              placeholder="Input Link"
              onChange={(e) => setLink(e.target.value)}
            />

            {link === "" ? (
              <Box border={"1px"} h={"80vh"}>
                <Center my={5}>Your site will be displayed here</Center>
              </Box>
            ) : (
              <Box border={"1px"} h={"80vh"}>
                <iframe width={"100%"} height={"100%"} src={link} />
              </Box>
            )}
          </Stack>

          <Stack w={"100%"} mt={5}>
            <Heading alignSelf={"center"}>Choose Template</Heading>
            {templates?.length > 0 && (
              <HStack
                my={5}
                spacing={5}
                w={"100%"}
                overflowX={"auto"}
                overflowY={"hidden"}
              >
                {templates?.map((x, index) => {
                  return (
                    <Stack
                      _hover={{
                        transform: "scale(1.03)",
                        shadow: "xl",
                      }}
                      transition={"0.2s ease-in-out"}
                      spacing={2}
                      borderTopWidth={3}
                      borderColor="green.500"
                      alignItems="center"
                      justifyContent="start"
                      bgColor="#2B2B2B"
                      key={index}
                      py={4}
                      px={2}
                      borderRadius="md"
                      shadow="md"
                      // Tambahkan properti disabled jika id kosong
                      disabled={!x.id}
                      opacity={!x.id ? 0.5 : 1}
                      cursor={!x.id ? "not-allowed" : "pointer"}
                    >
                      {/* <Image src={x?.media} width="80px" borderRadius={"md"} /> */}
                      <Box onClick={() => handleTemplate(x)}>
                        <Icon as={FiFeather} />
                        <Text
                          textAlign={"center"}
                          textTransform={"capitalize"}
                          noOfLines={1}
                        >
                          {x?.title}
                        </Text>
                      </Box>
                    </Stack>
                  );
                })}
              </HStack>
            )}

            <Box p={4} color="black">
              {aiGenerator?.length > 0 && (
                <Flex alignItems="center" justifyContent="center">
                  <IconButton
                    icon={<ChevronLeftIcon />}
                    variant="ghost"
                    size="lg"
                    onClick={handlePrev}
                  />
                  <Stack alignItems={"center"} justifyContent="center">
                    <Text
                      color={"white"}
                      fontWeight={500}
                      textTransform="capitalize"
                    >
                      {aiGenerator[currentIndex]?.title}
                    </Text>
                    {aiGenerator[currentIndex]?.loading ? (
                      <Spinner />
                    ) : (
                      <Box>
                        {aiGenerator[currentIndex]?.code ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: aiGenerator[currentIndex]?.code,
                            }}
                            style={{ background: "white" }}
                          />
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: aiGenerator[currentIndex]?.code,
                            }}
                            style={{ background: "white" }}
                          />
                        )}
                      </Box>
                    )}
                  </Stack>
                  <IconButton
                    icon={<ChevronRightIcon />}
                    variant="ghost"
                    size="lg"
                    onClick={handleNext}
                  />
                </Flex>
              )}
            </Box>
          </Stack>
          <Stack>
            <Box>
              <Center>
                {isLoading === true ? (
                  <Button isLoading colorScheme={"green"} onClick={generateAi}>
                    Run
                  </Button>
                ) : (
                  <Button colorScheme={"green"} onClick={generateAi}>
                    Run
                  </Button>
                )}
              </Center>

              {result.length !== 0 ? (
                <>
                  <Heading align={"center"}>Result</Heading>
                  <Box>
                    {isLoading === true ? (
                      <Loading />
                    ) : (
                      <Box>
                        {result.map((x) => (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: x?.result?.content,
                            }}
                            style={{ background: "white" }}
                          />
                        ))}
                      </Box>
                    )}
                  </Box>
                </>
              ) : null}
            </Box>
          </Stack>
        </Flex>
      </Stack>
    </>
  );
};

export default OneClickCreateLandingPage;

import { AspectRatio, Button, Center, Heading, Input, Spacer, Stack, Text } from '@chakra-ui/react'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import UppyComponent from '../../Components/Uppy/UppyComponent';
import BackButtons from '../../Components/Button/BackButtons';

function VideoAssetsAddPage() {
	const [file, setFile] = useState(null);
	const [fileLink, setFileLink] = useState('');
	const [filePreview, setFilePreview] = useState('');
	const [uploadProgess,setUploadProgress]=useState(0)

	const accessToken =  process.env.REACT_APP_DROPBOX; // Replace with your Dropbox access token

	const getData = async()=>{
		const url = 'https://api.dropboxapi.com/2/files/get_temporary_link';

		const headers = {
		  Authorization: `Bearer ${accessToken}`,
		  'Content-Type': 'application/json',
		};
	  
		const data = {
		  path: "/uploads/example.mp4",
		};
	  
		try {
		  const response = await axios.post(url, data, { headers });
		  const { link } = response.data;
		  setFileLink(link);

		  console.log('Temporary Link:', link);
		  // Use the temporary link to view or download the file
		} catch (error) {
		  console.error('Error retrieving file link:', error);
		}
	}

	const getPreview = async()=>{

		console.log('getting thumbnail')
		const url = 'https://content.dropboxapi.com/2/files/get_thumbnail';

		const headers = {
		  Authorization: `Bearer ${accessToken}`,
		  'Content-Type': 'application/json'
		};

		const body = {
			path: "/companyid/projectid/videos/header.svg",
			format: 'svg',
			size: 'w64h64',
		  };
  
		try {
		  const response = await axios.post(url, body, { headers });
		  console.log(response.data,'thumbnail')

		  const thumbnailUrl = URL.createObjectURL(response.data);
		  setFilePreview(thumbnailUrl);
		} catch (error) {
		  console.error('Error retrieving file thumbnail:', error.message);
		}
	}

	const hanledUpload = (file) =>{

		const hedear = {
			'Content-Type': 'application/octet-stream',
			Authorization: `Bearer ${accessToken}`,
			'Dropbox-API-Arg': JSON.stringify({
			path: `/uploads/projectId/${file.name}`,
			mode: 'add',
			autorename: true,
			mute: false,
			})
		}

		axios.post('https://content.dropboxapi.com/2/files/upload',)

	}



	useEffect(() => {
		//  getData()
		// getPreview()
	
	  return () => {
		
	  }
	}, [])
	

  return (
	<Stack p={[1, 1, 5]} spacing={5}>
		<BackButtons/>
		<Heading>Upload Video</Heading>
		{/* <AspectRatio maxW='570px' ratio={1}>
		

</AspectRatio> */}
		<Center>

		<UppyComponent/>
		</Center>
		{/* <Input type='file' onChange={(e)=>hanledUpload(e.target.files[0])} /> */}
	</Stack>
  )
}

export default VideoAssetsAddPage
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getSingleDocumentFirebase } from "../../Apis/firebaseApi";
import { Preview } from "@creatomate/preview";
import { getBasicComposition } from "../../Components/Videos/BasicCompotition";
import { Box, Flex } from "@chakra-ui/react";
import { useWindowWidth } from "../../Components/Videos/PreviewUtility";

const VideoCreateEditPage = () => {
  const param = useParams();
  const previewRef = useRef();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState();
  const windowWidth = useWindowWidth();
  const [isReady, setIsReady] = useState(false);
  const [currentState, setCurrentState] = useState();
  const [videoAspectRatio, setVideoAspectRatio] = useState();

  const getDataVideo = async () => {
    const videoData = await getSingleDocumentFirebase("files", param.id);
    console.log(videoData);
    setData(videoData);
  };

  useEffect(() => {
    getDataVideo();
  }, []);

  const setUpPreview = (htmlElement) => {
    // Clean up an older instance of the preview SDK
    if (previewRef.current) {
      previewRef.current.dispose();
      previewRef.current = undefined;
    }

    // Initialize a preview. Make sure you provide your own public token
    const preview = new Preview(
      htmlElement,
      "player",
      process.env.REACT_APP_CREATOMATE_VIDEO_PUBLIC_TOKEN
    );

    preview.onReady = async () => {
      // Once the SDK is ready, create a basic video scene
      await preview.setSource(getBasicComposition(data?.link));

      // Skip to 2 seconds into the video
      await preview.setTime(2);

      setIsLoading(false);
    };

    previewRef.current = preview;
  };

  const applyTextValue = async (value) => {
    // Change the 'Title' element to the provided text value
    // For more information: https://creatomate.com/docs/api/rest-api/the-modifications-object
    await previewRef.current?.setModifications({
      Title: value,
    });
  };

  return (
    <>
      <div>VideoCreateEditPage</div>

      <Flex h={"80vh"} w={"50%"} justifyContent={"center"} mt={10}>
        <Box
          ref={(htmlElement) => {
            if (htmlElement && htmlElement !== previewRef.current?.element) {
              setUpPreview(htmlElement);
            }
          }}
          style={{
            height:
              videoAspectRatio && windowWidth && windowWidth < 768
                ? window.innerWidth / videoAspectRatio
                : undefined,
          }}
        />
      </Flex>
    </>
  );
};

export default VideoCreateEditPage;

import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardRouter from "./DashboardRouter";
import SettingRouter from "./SettingRouter";
import CopywrittingRouter from "./CopywrittingRouter";
import LandingPageRouter from "./LandingPageRouter";
import PaymentRouter from "./PaymentRouter";
import FunnelEditPageV2 from "../Pages/LadingPageV2/Funnels/FunnelEditPageV2";
import MarketerRouter from "./MarketerRouter";
import EditorFunnelRouter from "./EditorFunnelRouter";
import TrainingRouter from "./TrainingRouter";
import VideosRouter from "./VideosRouter";
import TemplateRouter from "./TemplateRouter";
import ImageRouter from "./ImageRouter";

function MainRouter() {
  const allRouter = [
    ...DashboardRouter,
    ...CopywrittingRouter,
    ...LandingPageRouter,
    ...MarketerRouter,
    ...SettingRouter,
    ...PaymentRouter,
    ...TrainingRouter,
    ...VideosRouter,
    ...TemplateRouter,
    ...ImageRouter,
  ];

  return (
    <Routes>
      {allRouter.map((item, index) => {
        return <Route key={index} path={item.path} element={item.element} />;
      })}
    </Routes>
  );
}

export default MainRouter;

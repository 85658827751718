import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Spacer,
  Stack,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from "../../Apis/firebaseApi";
import BackButtons from "../../Components/Button/BackButtons";
import { BsBox2HeartFill } from "react-icons/bs";

const TextTrainingEdit = () => {
  const [data, setData] = useState({});
  const [template, setTemplate] = useState([]);
  const param = useParams();
  const toast = useToast();

  const getDataTemplate = async () => {
    try {
      const result = await getSingleDocumentFirebase("templates", param.id);

      setTemplate(result);
    } catch (error) {
      console.log(error);
    }
  };
  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  const updateTemplate = async () => {
    try {
      const result = await updateDocumentFirebase("templates", param.id, data);

      toast({
        status: "success",
        title: "Deoapp AI",
        description: "Template Added",
        duration: 3000,
      });
    } catch (error) {
      console.log("Terjadi kesalahan:", error);

      toast({
        status: "error",
        title: "Deoapp AI",
        description: `Error added template ${error}`,
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    getDataTemplate();

    return () => {};
  }, []);

  return (
    <Box my={4}>
      <HStack>
        <BackButtons />
        <Spacer />
        <Heading size={"lg"}>{template.title} Template</Heading>
      </HStack>
      <Flex my={4} gap={5}>
        <Stack w={"50%"}>
          <FormControl>
            <FormLabel>Title</FormLabel>
            <Input
              bgColor="white"
              color="black"
              defaultValue={template.name}
              sx={inputStyles}
              onChange={(e) => setData({ ...data, title: e.target.value })}
              placeholder={`Your Prompt Title. Ex: Script Content Generator`}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Prompt Template</FormLabel>
            <Textarea
              bgColor="white"
              color="black"
              defaultValue={template.message}
              sx={inputStyles}
              resize="none"
              onChange={(e) => setData({ ...data, message: e.target.value })}
              placeholder={`Your template prompt. Ex: Write a short script with more than 1000 character with topic about {topic} with {language} language in {languageStyle} tone`}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Textarea
              bgColor="white"
              color="black"
              defaultValue={template.description}
              sx={inputStyles}
              resize="none"
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
              placeholder={`Your template description`}
            />
          </FormControl>
        </Stack>
        <Stack w={"50%"}>
          <FormControl>
            <FormLabel>Topic</FormLabel>
            <Input
              bgColor="white"
              color="black"
              defaultValue={template.topic}
              sx={inputStyles}
              resize="none"
              onChange={(e) => setData({ ...data, topic: e.target.value })}
              placeholder={`Your main topic about this template. Ex: Copywriting, Script, Marketing`}
            />
          </FormControl>
          <Button my={3} colorScheme="green" onClick={updateTemplate}>
            Edit Template
          </Button>
        </Stack>
      </Flex>
    </Box>
  );
};

export default TextTrainingEdit;

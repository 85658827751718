import React, { useState, useEffect } from "react";
import { Select, Box, FormLabel } from "@chakra-ui/react";
import axios from "axios";

const LanguageStyleSelect = ({ onChange }) => {
  //   const [languages, setLanguages] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState("");

  const styles = [
    "Friendly",
    "Persuasive",
    "Humorous",
    "Casual",
    "Conversational",
    "Informative",
  ];

  const handleLanguageStyleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedStyle(selectedValue);
    onChange(selectedValue);
  };

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  return (
    <Box>
      <FormLabel>Language Style</FormLabel>
      <Select
        bgColor="white"
        color="black"
        sx={inputStyles}
        placeholder="Select language style"
        value={selectedStyle}
        onChange={handleLanguageStyleChange}
      >
        {styles.map((style, index) => (
          <option key={index} value={style}>
            {style}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default LanguageStyleSelect;

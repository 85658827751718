import { SiGoogleads } from "react-icons/si";
import { TiSocialFacebookCircular, TiSortAlphabeticallyOutline } from "react-icons/ti";
import { BsLadder } from "react-icons/bs";

export const aissistantTemplates=[
	{id:'',name:'Targetting FB ads', icon:TiSocialFacebookCircular, iconColor:'blue',description:'Get facebook ads targetting based on your datas'},
	{id:'',name:'Targetting Google ads',icon:SiGoogleads, iconColor:'red' , description:'Get facebook ads targetting based on your datas'},
	{id:'',name:'Value ladder' ,icon:BsLadder ,iconColor:'purple',description:'Create value ladder for your ideas'},
	// {id:'',name:'TOR'},
	// {id:'',name:'Hook Story offer'},
]
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { addDocumentFirebase } from "../../Apis/firebaseApi";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../Config/firebase";
import { useNavigate } from "react-router-dom";

function TextTraining() {
  const toast = useToast();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const globalState = UseAuthStore();
  const [template, setTemplate] = useState([]);

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  const addTemplate = async () => {
    try {
      const docID = await addDocumentFirebase(
        "templates",
        {
          ...data,
          iconColor: "green.300",
          type: "openai",
          title: data.name,
          category: "aissistant-copywriting",
        },
        globalState.currentCompany
      );

      const resultUpdate = await addDocumentFirebase(
        "logs",
        {
          activity: `add template ${data.name}`,
          uid: globalState.uid,
          projectId: globalState.currentProject,
          details: {
            type: "openai",
            title: data.name,
            category: "aissistant-copywriting",
          },
        },
        globalState.currentCompany
      );
      console.log(resultUpdate, "logs update");

      toast({
        status: "success",
        title: "Deoapp AI",
        description: "Template Added",
        duration: 3000,
      });
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
      toast({
        status: "error",
        title: "Deoapp AI",
        description: `Error added template ${error}`,
        duration: 3000,
      });
    }
  };

  const getuserTemplates = async () => {
    const q = query(
      collection(db, "templates"),
      where("createdBy", "==", globalState.uid)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const templateArr = [];
      querySnapshot.forEach((doc) => {
        templateArr.push({ ...doc.data(), id: doc.id });
      });

      setTemplate(templateArr);
    });
  };

  useEffect(() => {
    getuserTemplates();

    return () => {};
  }, []);

  return (
    <Box>
      <Heading>Create Template for Text Copywriting</Heading>
      <Flex my={4} gap={5}>
        <Stack w={"50%"}>
          <FormControl>
            <FormLabel>Title</FormLabel>
            <Input
              bgColor="white"
              color="black"
              sx={inputStyles}
              onChange={(e) => setData({ ...data, name: e.target.value })}
              placeholder={`Your Prompt Title. Ex: Script Content Generator`}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Prompt Template</FormLabel>
            <Textarea
              bgColor="white"
              color="black"
              sx={inputStyles}
              resize="none"
              onChange={(e) => setData({ ...data, message: e.target.value })}
              placeholder={`Your template prompt. Ex: Write a short script with more than 1000 character with topic about {topic} with {language} language in {languageStyle} tone`}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Textarea
              bgColor="white"
              color="black"
              sx={inputStyles}
              resize="none"
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
              placeholder={`Your template description`}
            />
          </FormControl>
        </Stack>
        <Stack w={"50%"}>
          <FormControl>
            <FormLabel>Topic</FormLabel>
            <Input
              bgColor="white"
              color="black"
              sx={inputStyles}
              resize="none"
              onChange={(e) => setData({ ...data, topic: e.target.value })}
              placeholder={`Your main topic about this template. Ex: Copywriting, Script, Marketing`}
            />
          </FormControl>
          <Button my={3} colorScheme="green" onClick={addTemplate}>
            Make Your Template
          </Button>
        </Stack>
      </Flex>
    </Box>
  );
}

export default TextTraining;

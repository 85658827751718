import React from 'react'



function NaturalTeeth() {
  return (
	<div data-block-color="0074C7" style="padding-top: 150px; padding-bottom: 100px; outline: none; border-color: rgb(0, 140, 113); background-color: rgb(255, 255, 255); margin-top: 0px; background-image: url(&quot;//naturalteethwhitener.com/hosted/images/19/44e7776ec547e4b883212c649b8109/hero-image.jpg&quot;);padding-bottom:100px;outline:rgb(47, 47, 47) none 0px;border-color:rgb(47, 47, 47) rgb(47, 47, 47) rgb(0, 140, 113);background-color:rgb(255, 255, 255);margin-top:0px;background-image:url('https://naturalteethwhitener.com/hosted/images/19/44e7776ec547e4b883212c649b8109/hero-image.jpg');width: 1170px;background-size:cover;background-repeat:no-repeat;border-bottom-color:rgb(0, 140, 113);border-top:0px none rgb(47, 47, 47);border-left:0px none rgb(47, 47, 47);border-right:0px none rgb(47, 47, 47);border-width:0px 0px 10px;border-style:none none solid;border-radius:0px;padding-left:0px;padding-right:0px;margin-right: auto;margin-left: auto;box-sizing:border-box;overflow:hidden;" data-trigger="none" data-animate="fade" data-delay="500" data-hide-on="">
    <div style="padding-left: 0px; padding-right: 0px;padding-right:0px;width: 1030px;max-width:100%;margin-right: auto;margin-left: auto;box-sizing:border-box;">
        <div data-trigger="none" data-animate="fade" data-delay="500" data-title="2 column row" style="padding: 0px; margin: 0px; outline: none;margin:0px;outline:rgb(47, 47, 47) none 0px;background-position:50% 50%;background-size:cover;background-attachment:fixed;background-repeat:repeat;background-position-y:50%;border:0px none rgb(47, 47, 47);border-width:0px;border-style:none;border-radius:0px;margin-left:0px;margin-right:0px;box-sizing:border-box;">
            <div data-col="left" data-trigger="none" data-animate="fade" data-delay="500" data-title="1st column" style="outline: none;width: 66.6667%;float:left;overflow-wrap:break-word;position:relative;min-height:1px;padding-left:15px;padding-right:15px;box-sizing:border-box;">
                <div style="padding: 0px;background-position:50% 50%;background-size:cover;background-attachment:fixed;background-repeat:repeat;background-position-y:50%;border:0px none rgb(47, 47, 47);border-width:0px;border-style:none;border-radius:0px;box-sizing:border-box;">
                    <div data-de-type="textblock" data-de-editing="false" data-title="text block" data-ce="false" data-trigger="none" data-animate="fade" data-delay="500" data-gramm="false" style="margin-top: 30px; outline: none; cursor: pointer;outline:rgb(47, 47, 47) none 0px;cursor:auto;position:relative;box-sizing:border-box;" aria-disabled="false">
                        <div data-gramm="false" style="box-sizing:border-box;">
                            <h1 style="font-size:43px;color:rgb(255, 255, 255);font-family:Nevis;font-weight:700;margin-top:0px;margin-bottom:10px;line-height:47.3px;box-sizing:border-box;margin:0px 0px 10px;">
                                Strange <span style="color:rgb(251, 211, 16);box-sizing:border-box;">"Mouth Detox"</span><br style="box-sizing:border-box;" /> <span style="font-size:32px;box-sizing:border-box;">Makes You Appear</span><br style="box-sizing:border-box;" /> <span style="font-size:65px;color:rgb(0, 255, 206);box-sizing:border-box;">10 Years Younger...</span> </h1>
                            <h2 style="font-size:37px;color:rgb(255, 255, 255);font-family:Nevis;font-weight:700;margin-top:0px;margin-bottom:10px;line-height:40.7px;box-sizing:border-box;">
                                — Whitens Teeth Up To 6<br style="box-sizing:border-box;" /> Shades In Less Than<br style="box-sizing:border-box;" />
                                16 Minutes...
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div data-col="right" data-trigger="none" data-animate="fade" data-delay="500" data-title="2nd column" style="outline: none;width: 33.3333%;float:left;overflow-wrap:break-word;position:relative;min-height:1px;padding-left:15px;padding-right:15px;box-sizing:border-box;">
                <div style="padding: 0px;background-position:50% 50%;background-size:cover;background-attachment:fixed;background-repeat:repeat;background-position-y:50%;border:0px none rgb(47, 47, 47);border-width:0px;border-style:none;border-radius:0px;box-sizing:border-box;"></div>
            </div>
        </div>
    </div>
</div>
  )
}

export default NaturalTeeth
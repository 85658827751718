import { Button, HStack, Icon, Text } from '@chakra-ui/react'

export const NavButton = (props) => {
    const { icon, label, ...buttonProps } = props
    return (
        <Button variant="ghost" justifyContent="start" size={'sm'} {...buttonProps}>
            <HStack spacing="3">
                <Icon as={icon} boxSize="4" color="subtle" />
                <Text fontSize={'sm'}>{label}</Text>
            </HStack>
        </Button>
    )
}
import React, { useContext, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  MdAccountCircle,
  MdEmail,
  MdFlag,
  MdLock,
  MdOutlinePhoneIphone,
} from "react-icons/md";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../Config/firebase";
import { updateDocumentFirebase } from "../../Apis/firebaseApi";

function PricingDetailPage() {
  const toast = useToast();
  const navigate = useNavigate();

  const currentUser = auth.currentUser;

  const [subscription, setSubscription] = useState(null);
  const [paymentLink, setPaymentLink] = useState(true);
  const [productsArr, setProductsArr] = useState([]);
  const [modalAccount, setModalAccount] = useState(false);
  const [priceIdValue, setPriceIdValue] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const getDataProducts = async () => {
    let products = {};
    const col = collection(db, "products");
    const q = query(col, where("active", "==", true));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      products[doc.id] = doc.data();

      // for get price
      const priceSnapshot = await getDocs(
        collection(db, `products/${doc.id}/prices`)
      );
      priceSnapshot.forEach((priceDoc) => {
        products[doc.id].price = {
          priceId: priceDoc.id,
          priceData: priceDoc.data(),
        };
      });
    });

    setProductsArr(products);
  };

  const getPrice = (name) => {
    if (name === "Professional Package") {
      return 27;
    } else if (name === "Unlimited Package") {
      return 97;
    } else if (name === "Enterprise Package") {
      return 297;
    }
    return 0;
  };

  useEffect(() => {
    getDataProducts();

    return () => {};
  }, []);

  const checkOut = async (priceId, uid) => {
    const docref = await addDoc(
      collection(db, "customers", uid, "checkout_sessions"),
      {
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      }
    );

    // console.log(docref.id, "ini docref");

    // if (docref.id) {
    //   const updatePayment = await updateDocumentFirebase("customers", uid, {
    //     paymentStatus: "stripe",
    //     createdBy: uid,
    //   });
    // }

    onSnapshot(
      doc(db, "customers", uid, "checkout_sessions", docref.id),
      async (doc) => {
        const { sessionId } = doc.data();
        try {
          if (sessionId) {
            const stripe = await loadStripe(
              process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
            ); // Menggunakan kunci publik Stripe
            stripe.redirectToCheckout({ sessionId });
          }
        } catch (error) {
          console.log(error.message);
        }
      }
    );
  };

  const handleModal = async (priceId) => {
    setModalAccount(true);
    setPriceIdValue(priceId);
  };

  const handleAccount = () => {
    const displayName = name;

    if (
      (email === "" && password === "" && name === "") ||
      password !== confirmPassword
    )
      return toast({
        title: "Something Wrong",
        description: "check your email, password, data",
        status: "error",
        duration: 10000,
        isClosable: true,
        position: "top-end",
      });

    if (email !== "" && password !== "" && name !== "") {
      try {
        setLoading(true);
        createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            await updateProfile(auth.currentUser, {
              displayName,
            });
            sendEmailVerification(auth.currentUser);

            // Signed in
            const user = userCredential.user;
            if (user) {
              await setDoc(doc(db, "users", user.uid), {
                name: name,
                keyword_name: name.toLowerCase().split(" ").join(""),
                email: user.email,
                createdAt: new Date(),
              });

              toast({
                title: "Success Create",
                description: `Success Create account ${user.displayName}`,
                status: "success",
                duration: 10000,
                isClosable: true,
                position: "top-right",
              });
            }

            await checkOut(priceIdValue, user.uid);
          })
          .catch((error) => {
            toast({
              title: "Something Wrong",
              description: `It looks like you don't have account in your browser, please signup and reload this page / ${error.message}`,
              status: "error",
              duration: 10000,
              isClosable: true,
              position: "top-right",
            });
            setLoading(false);
          });
      } catch (error) {
        toast({
          title: "Something Wrong",
          description: error,
          status: "error",
          duration: 10000,
          isClosable: true,
          position: "top-end",
        });
        setLoading(false);
      }
    } else {
      toast({
        title: "Something Wrong",
        description: "check your data",
        status: "error",
        duration: 10000,
        isClosable: true,
        position: "top-end",
      });
    }
  };

  const height = window.innerHeight;
  const width = window.innerWidth;

  return (
    <>
      <Stack minH={height} spacing="-10">
        <Stack
          pt={20}
          spacing={10}
          minH={height / 1.8}
          alignItems="center"
          justifyContent={"center"}
          bg="url(https://buildfire.com/wp-content/themes/buildfire/assets/images/pricing_background@3x.png) no-repeat center  fixed"
          bgSize="cover"
        >
          <Stack alignItems="center" justifyContent={"center"}>
            <Image
              src="https://buildfire.com/wp-content/themes/buildfire/assets/images/diy-icon-white.svg"
              alt="https://buildfire.com/wp-content/themes/buildfire/assets/images/diy-icon-white.svg"
              w={"50px"}
            />
            <Heading size={["xl", null, "2xl"]} color="white">
              Deoapp AI Platform
            </Heading>
            <Text fontSize={"sm"} color="white" fontWeight="bold">
              Build your own app. No coding required.
            </Text>
          </Stack>

          <Stack
            bgColor={"white"}
            p={2}
            borderRadius="xl"
            borderWidth="medium"
            borderColor="blue.400"
          >
            <Text fontWeight={"bold"} fontSize="sm" color="blue.400">
              Quarterly
            </Text>
          </Stack>
        </Stack>

        <Stack my={5}>
          {paymentLink !== null && (
            <SimpleGrid columns={[1, null, 3]} gap={6} px={6}>
              {Object.entries(productsArr) &&
                Object.entries(productsArr).map(([productId, productData]) => {
                  const isCurrentPlan = productsArr?.name
                    ?.toLowerCase()
                    .includes(subscription?.role);
                  const price = getPrice(productData.name);

                  return (
                    <VStack
                      key={productId}
                      bgColor="gray.600"
                      shadow={"base"}
                      borderRadius="lg"
                      spacing={5}
                      p={3}
                    >
                      <Stack
                        alignItems={"flex-start"}
                        justifyContent="flex-start"
                        w={"100%"}
                        spacing={3}
                      >
                        <Image
                          w={"30px"}
                          src="https://buildfire.com/wp-content/themes/buildfire/assets/images/plan2@3x.png"
                          alt="https://buildfire.com/wp-content/themes/buildfire/assets/images/plan1@3x.png"
                        />
                        <Text fontSize={"lg"} fontWeight="bold" color="black">
                          {productData.name}
                        </Text>
                        <Text fontSize={"sm"} color="black">
                          {productData.description}
                        </Text>
                        <HStack>
                          <Text alignSelf={"flex-start"} fontWeight="bold">
                            $
                          </Text>
                          <Heading size="3xl">{price}</Heading>
                          <Text alignSelf={"flex-end"} fontWeight="bold">
                            / mo
                          </Text>
                        </HStack>
                        <Text fontSize={"sm"} color="black">
                          Per month billed quarterly.
                        </Text>
                      </Stack>
                      <Spacer />
                      <Stack w={"full"}>
                        <Button
                          fontSize="sm"
                          fontWeight="bold"
                          size={"sm"}
                          disabled={isCurrentPlan}
                          // onClick={() => checkOut(productData.price.priceId)}
                          onClick={
                            currentUser !== null
                              ? () =>
                                  checkOut(
                                    productData.price.priceId,
                                    currentUser?.uid
                                  )
                              : () => handleModal(productData.price.priceId)
                          }
                          // onClick={currentUser !== null ? (() => checkOut(productData.price.priceId, currentUser?.uid)) : (() => navigate('/login'))}
                          bgColor={"blue.400"}
                        >
                          <Text>Get Started</Text>
                        </Button>
                      </Stack>
                    </VStack>
                  );
                })}
            </SimpleGrid>
          )}

          <Modal isOpen={modalAccount} onClose={() => setModalAccount(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <Stack cursor={"pointer"} onClick={() => setModalAccount(true)}>
                  <HStack spacing={2} alignItems="center">
                    <Text fontSize={"lg"} fontWeight="bold">
                      New Account
                    </Text>
                  </HStack>
                </Stack>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack>
                  <Stack alignItems="center">
                    <InputGroup
                      w={{
                        base: "100%",
                        md: "285",
                      }}
                    >
                      <InputLeftAddon
                        bgColor={"blue.400"}
                        children={<MdAccountCircle size={24} />}
                      />
                      <Input
                        placeholder="Full name"
                        fontSize={"md"}
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </InputGroup>
                  </Stack>

                  <Stack alignItems="center">
                    <InputGroup
                      w={{
                        base: "100%",
                        md: "285",
                      }}
                    >
                      <InputLeftAddon
                        bgColor={"blue.400"}
                        children={<MdEmail name="email" size={24} />}
                      />
                      <Input
                        w={{
                          base: "100%",
                          md: "100%",
                        }}
                        placeholder="Email"
                        fontSize={"md"}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </Stack>

                  <Stack alignItems="center">
                    <InputGroup
                      w={{
                        base: "100%",
                        md: "285",
                      }}
                    >
                      <InputLeftAddon
                        bgColor={"blue.400"}
                        children={<MdLock size={24} />}
                      />
                      <Input
                        w={{
                          base: "100%",
                          md: "100%",
                        }}
                        placeholder="Password"
                        fontSize={"md"}
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                  </Stack>

                  <Stack alignItems="center">
                    <InputGroup
                      w={{
                        base: "100%",
                        md: "285",
                      }}
                    >
                      <InputLeftAddon
                        bgColor={"blue.400"}
                        children={<MdLock size={24} />}
                      />
                      <Input
                        w={{
                          base: "100%",
                          md: "100%",
                        }}
                        placeholder="Confirm password"
                        fontSize={"md"}
                        id="password"
                        type="password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </InputGroup>
                  </Stack>
                </Stack>
              </ModalBody>

              <ModalFooter>
                {loading ? (
                  <Stack alignItems={"center"} justifyContent="center">
                    <Spinner />
                  </Stack>
                ) : (
                  <>
                    <Button
                      size={"sm"}
                      colorScheme="twitter"
                      mr={3}
                      onClick={() => setModalAccount(false)}
                    >
                      <Text color={"white"}>Close</Text>
                    </Button>
                    <Button
                      colorScheme="twitter"
                      size={"sm"}
                      onClick={() => handleAccount()}
                    >
                      <Text color={"white"}>Submit</Text>
                    </Button>
                  </>
                )}
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Stack>
      </Stack>
    </>
  );
}

export default PricingDetailPage;

import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Input,
  Spacer,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  addDocumentFirebase,
  arrayUnionFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
  updateDocumentFirebase,
} from "../../Apis/firebaseApi";
import { generateChatCompletion, openai } from "../../Apis/openAi";
import BackButtons from "../../Components/Button/BackButtons";
import Loading from "../../Components/Loading/Loading";
import { UseAuthStore } from "../../Hooks/Zustand/store";

function AissistantViewPage() {
  const globalState = UseAuthStore();
  const [title, setTitle] = useState("");
  const [requestDetail, setRequestDetail] = useState();
  const [messageContent, setMessageContent] = useState();
  const [userMessage, setUserMessage] = useState();
  const [data, setData] = useState([]);
  const [lastToken, setLastTokens] = useState();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const toast = useToast({
    position: "top",
    align: "center",
  });

  function replaceWithBr(data) {
    if (data) {
      return data.replace(/\n/g, "<br />");
    }
  }

  const handleQuestion = async (q) => {
    if (!q) return;
    setLoading(true);

    const newData = [...data, ...q.message];

    try {
      const chatCompletion = await generateChatCompletion(newData);
      setLoading(false);

      setData([...data, chatCompletion?.choices[0]?.message]);
      // arrayUnionFirebase("files", params.id, "message", [...q.message]);
      arrayUnionFirebase("files", params.id, "message", [
        chatCompletion?.choices[0]?.message,
      ]);

      const result = await getSingleDocumentFirebase("logs", globalState.uid);

      const resultUpdate = await addDocumentFirebase(
        "logs",
        {
          type: "copywriting",
          activity: `generate ai for result`,
          uid: globalState.uid,
          tokenUsage: chatCompletion?.usage?.total_tokens,
          projectId: globalState.currentProject,
          details: {
            requestMessage: q.message,
            answerMessage: chatCompletion?.choices[0]?.message,
          },
        },
        globalState.currentCompany
      );

      // getQuery();
    } catch (error) {
      console.log(error.message);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getQuery = async () => {
    try {
      const x = await getSingleDocumentFirebase("files", params.id);
      setRequestDetail(x);
      setData(x.message);
      setTitle(x.title);
      console.log(x, " ini");

      // if (x.message.length % 2 !== 0) {
      // 	const z = await getSingleDocumentFirebase('files', x.template);
      // 	handleQuestion({ ...x, ...z });
      // }
    } catch (err) {
      console.log(err.message);
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    }
  };

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  useEffect(() => {
    getQuery();

    return () => {};
  }, []);

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack>
        <BackButtons />
        <Spacer />
        <Heading textTransform="capitalize">{title ? title : <></>}</Heading>
      </HStack>

      <Divider />

      <Stack minH="100vh" overflowX="auto">
        {data?.map((x, i) => {
          if (i === 0) {
            // Modify the content of the first object
            return null;
          }
          return (
            <Flex
              key={i}
              justify={x.role === "user" ? "flex-end" : "flex-start"}
            >
              <Box
                shadow="base"
                p="5"
                borderRadius="xl"
                bgColor={x.role === "user" ? "#2B2B2B" : "#2B2B2B"}
                maxW="80%"
              >
                {x.role === "user" ? (
                  <div>
                    <Text textTransform="capitalize">{title} ?</Text>
                  </div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<div style="background-color: #2B2B2B" color='white'>${replaceWithBr(
                        x.content
                      )}</div>`,
                    }}
                  />
                )}
              </Box>
            </Flex>
          );
        })}
        {loading ? (
          <Loading />
        ) : (
          <>
            {requestDetail?.category !== "headline" ? (
              <>
                {
                  data.length === 1 ? (
                    <Button
                      my={5}
                      colorScheme="green"
                      onClick={() =>
                        handleQuestion({
                          message: [
                            { role: "user", content: `${data[0]?.content}` },
                          ],
                        })
                      }
                    >
                      See Result
                    </Button>
                  ) : (
                    <>
                      <Button
                        my={5}
                        colorScheme="green"
                        onClick={() =>
                          handleQuestion({
                            message: [
                              {
                                role: "user",
                                content: `generate another result with same topic`,
                              },
                            ],
                          })
                        }
                      >
                        Result
                        {/* Send Result */}
                      </Button>
                    </>
                  )
                  // Remove the outer curly braces from here
                }
              </>
            ) : (
              <>
                {
                  data.length === 3 ? (
                    <Button
                      my={5}
                      colorScheme="green"
                      onClick={() =>
                        handleQuestion({
                          message: [
                            { role: "user", content: `${data[3]?.content}` },
                          ],
                        })
                      }
                    >
                      See Result
                    </Button>
                  ) : (
                    <>
                      <Button
                        my={5}
                        colorScheme="green"
                        onClick={() =>
                          handleQuestion({
                            message: [
                              {
                                role: "user",
                                content: `give me 10 more`,
                              },
                            ],
                          })
                        }
                      >
                        Another Result
                        {/* Send Result */}
                      </Button>
                    </>
                  )
                  // Remove the outer curly braces from here
                }
              </>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
}

export default AissistantViewPage;

import { Configuration, OpenAIApi } from 'openai'
import { addDocumentFirebase } from './firebaseApi';
import { UseAuthStore } from '../Hooks/Zustand/store';

// const globalState = UseAuthStore()

const openaiApiKey = process.env.REACT_APP_OPENAI_API

const configuration = new Configuration({
  apiKey: openaiApiKey
})

export const openai = new OpenAIApi(configuration)

//chatGPT function
export const generateChatCompletion = async (data) => {
  try {
    const chatCompletion = await openai.createChatCompletion({
      model: "gpt-3.5-turbo",
      temperature: 0.8,
      messages: data,
    });
    
    return chatCompletion.data;
  } catch (error) {
    throw error;
  }
};

//chatGPT function for code generation
export const generateChatCompletionLandingPage = async (data) => {
  try {
    const chatCompletion = await openai.createChatCompletion({
      model: "gpt-3.5-turbo",
      temperature: 0.9,
      messages: data,
    });
    
    return chatCompletion.data;
  } catch (error) {
    throw error;
  }
};

export const generateChatCompletion16K = async (data) => {
  try {
    const chatCompletion = await openai.createChatCompletion({
      model: "gpt-3.5-turbo-16k",
      temperature: 0.85,
      messages: data,
    });
    // return chatCompletion.data.choices[0].message;

    // const addToLog = await addDocumentFirebase(
    //   "logs",
    //   {
    //     activity: `generate ai`,
    //     uid: globalState.uid,
    //     projectId: globalState.currentProject,
    //     details: {
    //       data
    //     },
    //     tokenUsage: chatCompletion?.data?.usage?.total_tokens
    //   },
    //   globalState.currentCompany
    // );
    return chatCompletion.data;
  } catch (error) {
    return { error: error.message }
    // throw error;
  }
};

//dall-e function
export const generateDalleImage = async (data, quantity) => {
  try {
    const imageGeneration = await openai.createImage({
      prompt: data,
      n: quantity,
      response_format: 'url',
      size: "512x512"
    })

    return imageGeneration
  } catch (error) {
    throw error
  }
}
export function getBasicComposition(id, videoUrl) {
  return {
    output_format: "mp4",
    width: 1080,
    height: 720,
    elements: [
      {
        id: `3b7c3aa0-607a-4cda-9d4e-2fe0f70ca5b4`,
        name: "Video-1",
        type: "video",
        source:
          "https://cdn.creatomate.com/renders/3b7c3aa0-607a-4cda-9d4e-2fe0f70ca5b4.mp4",
      },
    ],
  };
}

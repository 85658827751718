import {
  Button,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { MdArrowRightAlt } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function PricingPage() {
  const imageSponsor = [
    "https://buildfire.com/wp-content/themes/buildfire/assets/images/logos/pepsi@2x.png",
    "https://buildfire.com/wp-content/themes/buildfire/assets/images/logos/paypal@2x.png",
    "https://buildfire.com/wp-content/themes/buildfire/assets/images/logos/praxair@2x.png",
    "https://buildfire.com/wp-content/themes/buildfire/assets/images/logos/cisco@2x.png",
    "https://buildfire.com/wp-content/themes/buildfire/assets/images/logos/pandora@2x.webp",
    "https://buildfire.com/wp-content/themes/buildfire/assets/images/logos/aon@2x.webp",
    "https://buildfire.com/wp-content/themes/buildfire/assets/images/logos/travelers@2x.webp",
    "https://buildfire.com/wp-content/themes/buildfire/assets/images/logos/la-phil@2x.webp",
  ];

  const height = window.innerHeight;
  const width = window.innerWidth;

  const navigate = useNavigate();

  return (
    <>
      <Stack
        pt={20}
        bgColor={"black"}
        minH={height}
        bg="url(https://buildfire.com/wp-content/themes/buildfire/assets/images/gsf-hero-sm.jpg) no-repeat center center fixed"
        bgSize="cover"
      >
        <Stack py={10} px={[null, null, 20]}>
          <Stack alignItems={"center"} justifyContent="center">
            <Heading size={"2xl"} textAlign="center" color={"white"}>
              Pricing to meet your budget and needs
            </Heading>
          </Stack>
          <SimpleGrid columns={[1]} gap={5} py={10}>
            <Stack alignItems={"center"} justifyContent="center">
              <Stack
                w={["90%", null, "90%"]}
                bgColor="white"
                borderRadius={"xl"}
                _hover={{ transform: "scale(1.1)", shadow: "xl" }}
                transition={"0.2s ease-in-out"}
                alignItems={"center"}
                justifyContent="center"
              >
                <Stack
                  p={5}
                  pt={10}
                  w={"80%"}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image
                    src="https://buildfire.com/wp-content/themes/buildfire/assets/images/footer/app-builder.svg"
                    w={"30px"}
                  />
                </Stack>
                <Stack p={5} w={"80%"}>
                  <Heading color={"black"} size="lg" textAlign={"center"}>
                    Deoapp AI platform
                  </Heading>
                </Stack>
                <Stack w={"80%"}>
                  <Text color={"black"} fontSize="sm" textAlign={"center"}>
                    Build your AI with our DIY platform - no coding required.{" "}
                    <br />
                  </Text>
                </Stack>
                <Stack py={5}>
                  <Button
                    bgColor={"blue.500"}
                    colorScheme={"blue"}
                    size={"lg"}
                    px={12}
                    onClick={() => navigate("detail")}
                  >
                    <HStack alignItems={"center"} justifyContent="center">
                      <Text fontSize={"md"} color="white">
                        View plans
                      </Text>
                      <MdArrowRightAlt size={30} color="white" />
                    </HStack>
                  </Button>
                </Stack>
                <Spacer />
                <Stack
                  bgColor={"gray.100"}
                  p={5}
                  w="100%"
                  borderBottomRadius={"xl"}
                >
                  <Text textAlign={"center"} color="black" fontWeight={"bold"}>
                    Get a world-class app built by our talented design and
                    development team
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          </SimpleGrid>
        </Stack>

        <Stack bgColor={"gray.100"} py={10}>
          <SimpleGrid columns={[1, null, 3]} spacing={[10, null, null]}>
            <Stack alignItems={"center"} justifyContent="center">
              <Stack
                bgColor={"white"}
                p={3}
                borderRadius="md"
                shadow={"md"}
                w="50px"
                h={"50px"}
                alignItems="center"
                justifyContent={"center"}
              >
                <Image src="https://buildfire.com/wp-content/themes/buildfire/assets/images/footer/app-builder.svg" />
              </Stack>
              <Stack>
                <Text color={"gray.600"} fontSize="sm" fontWeight={"bold"}>
                  Powerful app builder
                </Text>
              </Stack>
            </Stack>

            <Stack alignItems={"center"} justifyContent="center">
              <Stack
                bgColor={"white"}
                p={3}
                borderRadius="md"
                shadow={"md"}
                w="50px"
                h={"50px"}
                alignItems="center"
                justifyContent={"center"}
              >
                <Image src="https://buildfire.com/wp-content/themes/buildfire/assets/images/footer/ios-and-android.svg" />
              </Stack>
              <Stack>
                <Text color={"gray.600"} fontSize="sm" fontWeight={"bold"}>
                  IOS, Android, & PWA
                </Text>
              </Stack>
            </Stack>

            <Stack alignItems={"center"} justifyContent="center">
              <Stack
                bgColor={"white"}
                p={3}
                borderRadius="md"
                shadow={"md"}
                w="50px"
                h={"50px"}
                alignItems="center"
                justifyContent={"center"}
              >
                <Image src="https://buildfire.com/wp-content/themes/buildfire/assets/images/footer/unlimited-customization.svg" />
              </Stack>
              <Stack>
                <Text color={"gray.600"} fontSize="sm" fontWeight={"bold"}>
                  Unlimited Costumization
                </Text>
              </Stack>
            </Stack>
          </SimpleGrid>
        </Stack>
      </Stack>
    </>
  );
}

export default PricingPage;

import {
  AspectRatio,
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Input,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FcPlus } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import {
  deleteDocumentFirebase,
  getCollectionFirebase,
} from "../../Apis/firebaseApi";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import { FaRegFileVideo } from "react-icons/fa";
import moment from "moment";
import { TiTrash } from "react-icons/ti";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../Config/firebase";

function ImageCollection() {
  const toast = useToast();
  const navigate = useNavigate();
  const [dataImage, setDataImage] = useState();
  const [inputSearch, setInputSearch] = useState();
  const [dataSearchedFile, setDataSearchedFile] = useState();
  const [files, setFiles] = useState([]);
  const [preview, setPreview] = useState();
  const globalState = UseAuthStore();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  const getDataFile = async () => {
    const q = query(
      collection(db, "files"),
      where("createdBy", "==", globalState.uid),
      where("type", "==", "image"),
      where("companyId", "==", globalState.currentCompany)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const imageArr = [];
      querySnapshot.forEach((doc) => {
        imageArr.push({ ...doc.data(), id: doc.id });
      });

      console.log(imageArr);
      setFiles(imageArr);
    });

    onClose();
  };

  const handleDelete = async (id) => {
    try {
      const result = await deleteDocumentFirebase("files", id);
      toast({
        title: "Deoapp AI",
        description: "Image deleted",
        status: "success",
        duration: 2000,
      });
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
      toast({
        title: "Deoapp AI",
        description: `${error}`,
        status: "error",
        duration: 2000,
      });
    }
  };

  const handleViewImage = (link) => {
    window.open(link, "_blank");
  };

  const handleOpenModal = (detail) => {
    onOpen();
    setDataImage(detail);
  };

  const searchFiles = (text) => {
    if (text) {
      const newData = files.filter((item) => {
        const itemData = item.name ? item.name.toUpperCase() : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setDataSearchedFile(newData);
      setInputSearch(text);
    } else {
      setDataSearchedFile(files);
      setInputSearch(text);
    }
  };

  useEffect(() => {
    getDataFile();

    return () => {};
  }, []);

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack>
        <Heading>Image Collections</Heading>
        <Spacer />
        <Button
          colorScheme="green"
          variant="outline"
          // onClick={() => navigate(`/create-aissistant/${param.name}`)}
        >
          <HStack spacing={2}>
            <FcPlus />
            <Text textTransform="uppercase" fontSize="sm" fontWeight={500}>
              Upload
            </Text>
          </HStack>
        </Button>
      </HStack>

      <Input
        type="text"
        placeholder="Search Files ..."
        bgColor="white"
        color="black"
        sx={inputStyles}
        fontSize="sm"
        onChange={(e) => searchFiles(e.target.value)}
      />

      {inputSearch ? (
        <>
          {dataSearchedFile?.length > 0 ? (
            <>
              <SimpleGrid columns={(2, null, 5)} spacing={3} my={5}>
                {dataSearchedFile.map((file, i) => (
                  <Box
                    cursor={"pointer"}
                    key={i}
                    _hover={{
                      transform: "scale(1.02)",
                      shadow: "xl",
                    }}
                    transition={"0.2s ease-in-out"}
                    spacing={2}
                    borderTopWidth={3}
                    borderColor="green.500"
                    align="center"
                    justifyContent="start"
                    bgColor="#2B2B2B"
                    py={4}
                    px={2}
                    borderRadius="md"
                    shadow="md"
                  >
                    <Image
                      src={file.link}
                      onClick={() => handleViewImage(file.link)}
                    />
                    {/* <Icon boxSize={"75px"} as={FaRegFileVideo} /> */}
                    <Stack mt={4}>
                      <Text>{file.name}</Text>
                      <HStack>
                        <Text fontSize="2xs">
                          {moment
                            .unix(
                              file?.lastUpdated?.seconds ??
                                file?.createdAt?.seconds
                            )
                            .fromNow()}
                        </Text>
                        <Spacer />
                        <Icon
                          as={TiTrash}
                          onClick={() => handleOpenModal(file)}
                        />
                      </HStack>
                    </Stack>
                  </Box>
                ))}
              </SimpleGrid>
            </>
          ) : (
            <Stack fontSize="md">
              <Text textAlign="center" fontWeight="semibold">
                No Data, Create your first image first
              </Text>
            </Stack>
          )}
        </>
      ) : (
        <>
          {files?.length > 0 ? (
            <>
              <SimpleGrid columns={(2, null, 5)} spacing={3} my={5}>
                {files.map((file, i) => (
                  <Box
                    cursor={"pointer"}
                    key={i}
                    _hover={{
                      transform: "scale(1.02)",
                      shadow: "xl",
                    }}
                    transition={"0.2s ease-in-out"}
                    spacing={2}
                    borderTopWidth={3}
                    borderColor="green.500"
                    align="center"
                    justifyContent="start"
                    bgColor="#2B2B2B"
                    py={4}
                    px={2}
                    borderRadius="md"
                    shadow="md"
                  >
                    <Image
                      src={file.link}
                      onClick={() => handleViewImage(file.link)}
                    />
                    {/* <Icon boxSize={"75px"} as={FaRegFileVideo} /> */}
                    <Stack mt={4}>
                      <Text>{file.name}</Text>
                      <HStack>
                        <Text fontSize="2xs">
                          {moment
                            .unix(
                              file?.lastUpdated?.seconds ??
                                file?.createdAt?.seconds
                            )
                            .fromNow()}
                        </Text>
                        <Spacer />
                        <Icon
                          as={TiTrash}
                          onClick={() => handleOpenModal(file)}
                        />
                      </HStack>
                    </Stack>
                  </Box>
                ))}
              </SimpleGrid>
            </>
          ) : (
            <Stack fontSize="md">
              <Text textAlign="center" fontWeight="semibold">
                No Data, Create your first image first
              </Text>
            </Stack>
          )}
        </>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{dataImage?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure want to delete this image?</ModalBody>
          <ModalFooter>
            <HStack>
              <Button size={"sm"} colorScheme={"red"} onClick={onClose}>
                Cancel
              </Button>
              <Button
                size={"sm"}
                colorScheme="green"
                onClick={() => handleDelete(dataImage.id)}
              >
                Delete
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default ImageCollection;

import React from "react";
import AimarketerCreatePage from "../Pages/Marketer/AiMarketerCreatePage";

const MarketerRouter = [

  {
    path: "/create-aimarketer",
    element: <AimarketerCreatePage />,
  },

];

export default MarketerRouter;

import axios from "axios";

const UploadUrlToDropbox = async (link, parentPath, token) => {
  const accessToken = token;
  const url = "https://api.dropboxapi.com/2/files/save_url";

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  const data = {
    path: `${parentPath}/image/image1`,
    url: `${link}`,
  };

  console.log(data);

  try {
    // const response = await axios.post(url, data, {
    //   headers: headers,
    // });
    // console.log("File berhasil diunggah ke Dropbox:", response);

    axios
      .post(url, data, { headers: headers })
      .then((response) => {
        console.log(response.data);
        // Handle the API response here
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });

    // if (response?.data?.path_lower) {
    //   const dropboxLink = await createShareLink(
    //     response.data.path_lower,
    //     `image`,
    //     accessToken
    //   );
    //   return dropboxLink;
    // }

    return "yes";
  } catch (error) {
    console.error("Terjadi kesalahan saat mengunggah file ke Dropbox:", error);
    return error;
  }
};

const createShareLink = async (filePath, typeFile, token) => {
  // const accessToken = process.env.REACT_APP_DROPBOX;
  const accessToken = token;

  const url =
    "https://api.dropboxapi.com/2/sharing/create_shared_link_with_settings";

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  const requestData = {
    path: filePath,
  };

  try {
    const response = await axios.post(url, requestData, {
      headers: headers,
    });

    const urlData = response?.data?.url;
    const urlRaw = `${urlData}&raw=1`;
    return { link: urlRaw, type: typeFile };
  } catch (error) {
    console.error("Terjadi kesalahan saat membuat tautan berbagi:", error);
    return error;
  }
};

export default UploadUrlToDropbox;

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Select,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  getCollectionFirebase,
  updateDocumentFirebase,
} from "../../../Apis/firebaseApi";
import { generateChatCompletion16K, openai } from "../../../Apis/openAi";
import BackButtons from "../../../Components/Button/BackButtons";
import Loading from "../../../Components/Loading/Loading";

function ExampleCreate() {
  const [message, setMessage] = useState({});
  const [choosenTemplate, setChoosenTemplate] = useState();
  const [tamplatesData, setTamplatesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [aiGenerator, setAiGenerator] = useState([]);
  const [tamplateActive, setTamplateActive] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const { state } = useLocation();

  const param = useParams();

  const toast = useToast({
    position: "top",
    align: "center",
  });

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  const getDataTamplate = async () => {
    const conditions = [{ field: "type", operator: "==", value: "website" }];
    // const sortBy = { field: "createdAt", direction: "asc" };
    // const limitValue = 10;

    try {
      const res = await getCollectionFirebase(
        "templates",
        conditions
        // sortBy,
        // limitValue
      );
      setTamplatesData(res);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const handleTemplate = async (x) => {
    // setChoosenTemplate(x);

    try {
      const res = await getCollectionFirebase(`templates/${x.id}/prompt`);
      setChoosenTemplate(res);

      const data = res.map((html) => {
        return {
          title: html?.title,
          loading: false,
          htmlContent: null,
          htmlTemplate: html?.code,
        };
      });

      setAiGenerator(data);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  useEffect(() => {
    getDataTamplate();
  }, []);

  const handleAi = async () => {
    setLoading(true);

    try {
      for (let i = 0; i < aiGenerator?.length; i++) {
        const x = aiGenerator[i];
        setAiGenerator((prevState) => {
          const updatedGenerator = [...prevState];
          updatedGenerator[i] = { ...x, loading: true };
          return updatedGenerator;
        });

        console.log(x, "ini generator");
        const promptMap = {
          hero: "Headline and subheadline",
          keyFeatures: "3 features and or benefits",
          // tech specs: "3 technical specs",
          pricing: "give pricing from input pricing",
          testimonial: "3 Testimonials",
          faq: "3 FAQs",
          cta: "Call to actions",
          footer: "Call to actions",
        };

        console.log(aiGenerator);

        const prompt = promptMap[x.title] || "";

        console.log(prompt);

        let str = "";
        for (const [p, val] of Object.entries(message)) {
          str += `${p.replace("_", " ")}:\n -${val}\n`;
        }

        console.log(x.htmlContent);
        const newJSON = `Create a high converting copy for ${x.title} section ${prompt} to be used in a sales page. Please make it simple, unique, not too long sentence, straight to the point and persuasive with data per below\n\n${str}`;
        // const newJSON = `Change the text content in this html inline css template ${x.htmlContent} code for a high converting copy for ${x.title} section ${prompt} to be used in a sales page. Please make it simple, unique, not too long sentence, straight to the point and persuasive with data per below\n\n${str}`;
        console.log(newJSON, "ini newJson");

        const requestMessage = [
          {
            role: "system",
            content:
              "You’re a site layout creator that responds with information which will be used to configure the sections of a page on a website, based on the user-provided input. All text content should be as impressive and exciting as possible.",
          },
          { role: "user", content: newJSON },
        ];

        const chatCompletion = await generateChatCompletion16K(requestMessage);

        const messageData = chatCompletion.choices[0]?.message;

        console.log(messageData, "ini messageData");

        if (messageData.content) {
          // const htmlPrompt = `Create a high converting ${x.title} section in html format to be used in a sales page with data per below\n\n${messageData.content}\n\n make it simple, unique, short and persuasive with beautiful and interesting UI for return in optimized HTML inline CSS format. I provide the code for your reference and you should follow from this code <section>${x?.htmlTemplate}</section>`;
          const htmlPrompt = `Change the text content in this html inline css template ${messageData.content} code for a high converting copy for ${x.title} section ${prompt} to be used in a sales page. Please make it simple, unique, not too long sentence, straight to the point and persuasive with data per below. \n\n${str}\n\n Match this data with the ${prompt} section, (example: hero is for headline and subheadline, etc) If it doesnt match, make a new code for that section`;
          console.log(htmlPrompt, "ini htmlPrompt");
          const htmlRequestMessage = [
            {
              role: "system",
              content:
                "You’re a site layout creator that responds with information which will be used to configure the sections of a page on a website, based on the user-provided input. All text content should be as impressive and exciting as possible.",
            },
            { role: "user", content: htmlPrompt },
          ];

          const chatCompletionWeb = await generateChatCompletion16K(
            htmlRequestMessage
          );
          const htmlData = chatCompletionWeb.choices[0]?.message;
          const htmlContent = htmlData.content;

          console.log(htmlContent);
          // setAiGenerator((prevState) => {
          //   const updatedGenerator = [...prevState];
          //   updatedGenerator[i] = { ...x, loading: false, htmlContent };
          //   return updatedGenerator;
          // });
        }
      }
    } catch (error) {
      console.log(error.message);
    }

    setLoading(false);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === aiGenerator?.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? aiGenerator?.length - 1 : prevIndex - 1
    );
  };

  const handleSave = async () => {
    const collectionName = `funnels/${param.id}/page`;
    const docName = state.id;
    const data = {
      message: aiGenerator,
    };

    try {
      const result = await updateDocumentFirebase(
        collectionName,
        docName,
        data
      );

      toast({
        title: "Success",
        description: `Success save funnel.`,
        status: "success",
        duration: 10000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
    }
  };

  return (
    <Box>
      <HStack>
        <BackButtons />
        <Spacer />
        <Heading>
          {state.title} - {state.title_page}
        </Heading>
      </HStack>

      <Stack gap={5} py={5}>
        <Stack>
          <Heading size={"md"} pb={3}>
            Choose template :{" "}
          </Heading>
          {tamplatesData?.length > 0 && (
            <HStack
              spacing={5}
              w={"100%"}
              overflowX={"auto"}
              overflowY={"hidden"}
            >
              {tamplatesData?.map((x, index) => {
                return (
                  <Stack
                    _hover={{
                      transform: "scale(1.03)",
                      shadow: "xl",
                    }}
                    transition={"0.2s ease-in-out"}
                    spacing={2}
                    borderTopWidth={3}
                    borderColor="green.500"
                    alignItems="center"
                    justifyContent="start"
                    bgColor="#2B2B2B"
                    key={index}
                    py={4}
                    px={2}
                    borderRadius="md"
                    shadow="md"
                    onClick={() => handleTemplate(x)}
                    // Tambahkan properti disabled jika id kosong
                    disabled={!x.id}
                    opacity={!x.id ? 0.5 : 1}
                    cursor={!x.id ? "not-allowed" : "pointer"}
                  >
                    <Image src={x?.media} width="80px" borderRadius={"md"} />
                    <Text
                      textAlign={"center"}
                      textTransform={"capitalize"}
                      noOfLines={1}
                    >
                      {x?.title}
                    </Text>
                  </Stack>
                );
              })}
            </HStack>
          )}

          <Divider />

          <Box p={4} color="black">
            {aiGenerator?.length > 0 && (
              <Flex alignItems="center" justifyContent="center">
                <IconButton
                  icon={<ChevronLeftIcon />}
                  variant="ghost"
                  size="lg"
                  onClick={handlePrev}
                />
                <Stack alignItems={"center"} justifyContent="center">
                  <Text
                    type="text"
                    bgColor="white"
                    color="black"
                    sx={inputStyles}
                    fontSize="sm"
                    fontWeight={500}
                    textTransform="capitalize"
                  >
                    {aiGenerator[currentIndex]?.title}
                  </Text>
                  {aiGenerator[currentIndex]?.loading ? (
                    <Spinner />
                  ) : (
                    <Box>
                      {aiGenerator[currentIndex]?.htmlContent ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: aiGenerator[currentIndex].htmlContent,
                          }}
                          style={{ background: "white" }}
                        />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: aiGenerator[currentIndex]?.htmlTemplate,
                          }}
                          style={{ background: "white" }}
                        />
                      )}
                    </Box>
                  )}
                </Stack>
                <IconButton
                  icon={<ChevronRightIcon />}
                  variant="ghost"
                  size="lg"
                  onClick={handleNext}
                />
              </Flex>
            )}
          </Box>
        </Stack>

        <Stack m="2" p="2" shadow="base" spacing={2}>
          <Stack>
            <FormControl>
              <FormLabel>Heading</FormLabel>
              <Input
                type="text"
                disabled={loading}
                placeholder="Say Goodbye to Financial Worries: Earn a Whopping 200k/year as a Full Stack Programmer!"
                onChange={(e) =>
                  setMessage({ ...message, topic: e.target.value })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Unique Selling Propotition</FormLabel>
              <Textarea
                resize="none"
                onChange={(e) =>
                  setMessage({
                    ...message,
                    uniqueSellingPropotition: e.target.value,
                  })
                }
                placeholder={`Our unique program equips you with the in-demand skills of a full stack programmer, unlocking a world of lucrative opportunities. Join our comprehensive training and fast-track your career to earn an impressive annual income of $200k or more. Say goodbye to financial worries and embrace a future filled with financial stability, job satisfaction, and limitless growth potential. Don't just dream of success, make it a reality with our proven pathway to a high-earning future in the dynamic world of technology.`}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Pricing</FormLabel>
              <Textarea
                resize="none"
                onChange={(e) =>
                  setMessage({ ...message, pricing: e.target.value })
                }
                placeholder={`$300 package one , $500 package two, $1000 package three`}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Benefits</FormLabel>
              <Textarea
                resize="none"
                onChange={(e) =>
                  setMessage({ ...message, benefits: e.target.value })
                }
                placeholder={`Financial Security \nLucrative Career Opportunities \nPrestige and Recognition`}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Fears</FormLabel>
              <Textarea
                resize="none"
                onChange={(e) =>
                  setMessage({ ...message, fears: e.target.value })
                }
                placeholder={`Stagnant Income \nCompetitive Job Market \nLimited Career Options`}
              />
            </FormControl>
          </Stack>

          <Stack>
            <FormControl>
              <FormLabel>Social Proof</FormLabel>
              <Textarea
                resize="none"
                onChange={(e) =>
                  setMessage({ ...message, testimonials: e.target.value })
                }
                placeholder={`Alumni Success Stories:"Thanks to the program, I secured a position at a leading tech company and doubled my previous salary within six months. The support from the alumni community has been invaluable in my professional growth." - Sarah Johnson, Program Graduate.`}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Brand Color</FormLabel>
              <Input
                type="text"
                onChange={(e) =>
                  setMessage({ ...message, brand_color: e.target.value })
                }
                placeholder={`#ffd600`}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Language</FormLabel>
              <Select
                placeholder="Language"
                onChange={(e) =>
                  setMessage({ ...message, language: e.target.value })
                }
              >
                <option value="use english for page content">English</option>
                <option value="use bahasa indonesia for page content">
                  Indonesia
                </option>
              </Select>
            </FormControl>
            {loading ? (
              <Button isLoading mt="5" w="full" colorScheme="green">
                Generating landing page with AI
              </Button>
            ) : (
              <Button
                onClick={() => handleAi()}
                mt="5"
                w="full"
                colorScheme="green"
              >
                Generate landing page with AI
              </Button>
            )}
            <Button
              onClick={() => handleSave()}
              mt="5"
              w="full"
              colorScheme="green"
            >
              Save
            </Button>
            {/* <Button
              onClick={() => console.log(aiGenerator)}
              mt="5"
              w="full"
              colorScheme="green"
            >
              Console
            </Button> */}
          </Stack>
        </Stack>
      </Stack>

      {/* <Stack bgColor={'white'} color='black'>
        <Box>
          {aiGenerator.length > 0 && (
            <Stack >
              {aiGenerator.map((x, index) => (
                <Stack key={index}>
                  <Text>{x.title}</Text>
                  {x.loading ? (
                    <Stack>
                      <Spinner />
                    </Stack>
                  ) : (
                    <Stack>
                      {x.htmlContent ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: x.htmlContent }}
                          style={{ background: "white" }}
                        />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: x.htmlTemplate }}
                          style={{ background: "white" }}
                        />
                      )}
                    </Stack>
                  )}
                </Stack>
              ))}
            </Stack>
          )}
        </Box>
      </Stack> */}
    </Box>
  );
}

export default ExampleCreate;

import React, { useState } from "react";
import { Preview } from "@creatomate/preview";
import { Button } from "@chakra-ui/button";
import {
  creatomateGetVideo,
  creatomateTemplateRenderApi,
} from "../../Apis/videoApi";
import { addDocumentFirebase } from "../../Apis/firebaseApi";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import { useToast } from "@chakra-ui/toast";
import { HStack, Spacer } from "@chakra-ui/layout";

export const CreateButton = (props) => {
  const toast = useToast();
  const globalState = UseAuthStore();
  const [isRendering, setIsRendering] = useState(false);
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [render, setRender] = useState();

  const editVideo = async () => {
    setCheck(true);
    try {
      const res = await creatomateGetVideo(render.data[0]?.id);
      console.log(res.data);

      if (res.data?.status === "succeeded") {
        setResult(res.data);
      } else {
        toast({
          status: "warning",
          title: "Deoapp AI",
          description: "Video is still rendering. Please Wait",
          duration: 2000,
        });
      }
    } catch (error) {
      console.log(error, "ini error");
    } finally {
      setCheck(false);
    }
  };

  const saveVideo = async () => {
    setIsSave(true);
    if (result?.status === "succeeded") {
      try {
        const docID = await addDocumentFirebase(
          "files",
          {
            name: "Generated Video 1",
            type: "video_assets",
            category: "video_results",
            createdBy: globalState.uid,
            projectId: globalState.currentProject,
            link: result?.url,
          },
          globalState.currentCompany
        );
        console.log("ID Dokumen Baru:", docID);
        toast({
          status: "success",
          title: "Deoapp AI",
          description: "Video save succeeded. Please Wait",
          duration: 2000,
        });
        window.open(result.url, "_blank");
      } catch (error) {
        console.log("Terjadi kesalahan:", error);
        toast({
          status: "error",
          title: "Deoapp AI",
          description: error,
          duration: 2000,
        });
      }
    } else {
      toast({
        status: "Warning",
        title: "Deoapp AI",
        description: `Video is ${result.status}`,
        duration: 2000,
      });
    }
    setIsSave(false);
  };

  if (isRendering) {
    return <Button style={{ background: "#e67e22" }}>Rendering...</Button>;
  }

  if (render) {
    return (
      <HStack>
        {check === true ? (
          <Button
            isLoading
            style={{ background: "#2ecc71" }}
            onClick={() => {
              editVideo();
            }}
            // onClick={() => console.log(render)}
          >
            Check Video
          </Button>
        ) : (
          <Button
            style={{ background: "#2ecc71" }}
            onClick={() => {
              editVideo();
            }}
            // onClick={() => console.log(render)}
          >
            Check Video
          </Button>
        )}
        <Spacer />
        {result && result.status === "succeeded" ? (
          <>
            {isSave === true ? (
              <Button
                isLoading
                style={{ background: "#2ecc71" }}
                onClick={() => {
                  saveVideo();
                }}
                // onClick={() => console.log(render)}
              >
                Save Video
              </Button>
            ) : (
              <Button
                style={{ background: "#2ecc71" }}
                onClick={() => {
                  saveVideo();
                }}
                // onClick={() => console.log(render)}
              >
                Save Video
              </Button>
            )}
          </>
        ) : (
          <Button
            style={{ background: "#2ecc71" }}
            isDisabled
            onClick={() => {
              saveVideo();
            }}
            // onClick={() => console.log(render)}
          >
            Save Video
          </Button>
        )}
      </HStack>
    );
  }

  if (result?.status === "succeeded") {
    return (
      <Button
        style={{ background: "#2ecc71" }}
        onClick={() => {
          saveVideo();
        }}
      >
        Save
      </Button>
    );
  }

  return (
    <HStack spacing={3}>
      <Button
        colorScheme="green"
        style={{ marginLeft: "auto" }}
        onClick={async () => {
          setIsRendering(true);

          try {
            const render = await finishVideo(props.preview);
            if (render?.status === 202) {
              setRender(render);
              console.log(render, "ini render");
              toast({
                status: "success",
                title: "Deoapp AI",
                description: "Video is Rendering",
                duration: 3000,
              });
            } else {
              window.alert(`Rendering failed: ${render.errorMessage}`);
            }
          } catch (error) {
            window.alert(error);
            console.log(error);
          } finally {
            setIsRendering(false);
          }
        }}
      >
        Create Video
      </Button>
    </HStack>
  );
};

const finishVideo = async (preview) => {
  //   const response = await fetch("https://api.creatomate.com/v1/renders", {
  //     method: "POST",
  //     headers: {

  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       source: preview.getSource(),
  //     }),
  //   });

  //   if (!response.ok) {
  //     if (response.status === 401) {
  //       throw new Error(
  //         "No API key was provided. Please refer to the README.md for instructions."
  //       );
  //     } else {
  //       throw new Error(`The request failed with status code ${response.status}`);
  //     }
  //   }

  //   return await response.json();

  const response = await creatomateTemplateRenderApi(preview.getSource());
  console.log(response);
  return response;
};

import {
  Box,
  Button,
  CloseButton,
  Container,
  Divider,
  Flex,
  HStack,
  Icon,
  Image,
  Input,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BackButtons from "../../Components/Button/BackButtons";
import { FcHeatMap } from "react-icons/fc";
import { formatPrice, PriceTag } from "../../Components/PriceTag/PriceTag";
import { FiCopy } from "react-icons/fi";
import axiosInstance from "../../Apis/axiosWithBarier";
import { formatFrice } from "../../Utils/numberUtil";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import { auth } from "../../Config/firebase";
import { addDocumentFirebase } from "../../Apis/firebaseApi";

const PaymentPage = () => {
  const [payment, setPayment] = useState({
    bank: null,
    button: false,
    data: null,
  });
  const [orderList, setOrderList] = useState([]);
  const [loadingPay, setLoadingPay] = useState(false);

  const globalState = UseAuthStore();

  const [packageActive, setPackageActive] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [paymentActive, setPaymentActive] = useState("");

  const getMembership = async () => {
    try {
      const res = await axiosInstance.get("/membershipList");
      if (res.status === true) {
        setOrderList(res.message);
      } else {
        console.log(res.message);
      }
    } catch (error) {
      console.log(error, "ini eror");
    }
  };

  useEffect(() => {
    getMembership();

    return () => {};
  }, []);

  const handlePackageActive = (value) => {
    console.log(value, "ini value");
    setPackageActive(value);
  };

  const handlePay = async () => {
    const data = {
      is_production: true,
      package_code: packageActive.package_code,
      company_name: companyName,
      user_name: auth.currentUser.displayName || globalState.name,
      user_email: auth.currentUser.email || globalState.email,
      user_phone: phoneNumber || auth.currentUser.phoneNumber,
      redirect_url: `https://ai.deoapp.com/account`,
    };

    const res = await axiosInstance.post("/membershipCreate", data);
    if (res.status === true) {
      setPaymentActive(res.data);
    }
  };

  const handlePayPayment = async () => {
    setLoadingPay(true);

    const data = {
      order_id: paymentActive.order_id,
    };
    try {
      const res = await axiosInstance.post("/membershipPay", data);
      if (res.status === true) {
        window.open(res.message.link, "_blank");
      }

      const resultUpdate = await addDocumentFirebase(
        "logs",
        {
          activity: `pay membership`,
          uid: globalState.uid,
          projectId: globalState.currentProject,
        },
        globalState.currentCompany
      );
      console.log(resultUpdate, "logs updated");

      setLoadingPay(false);
    } catch (error) {
      console.log(error, "ini error");
      setLoadingPay(false);
    } finally {
      setLoadingPay(false);
    }
  };

  useEffect(() => {
    return () => {
      setPayment({ bank: null, button: false, data: null });
    };
  }, []);

  return (
    <Box
      as="section"
      py={{
        base: "4",
        md: "8",
      }}
    >
      <Stack spacing="5">
        <Stack
          spacing="4"
          direction={{
            base: "column",
            sm: "row",
          }}
          justify="space-between"
        >
          <Box>
            <Text fontSize="lg" fontWeight="medium">
              Payment
            </Text>
            <Text color="muted" fontSize="sm">
              Check your order list and choose your payment method
            </Text>
          </Box>

          {/* <Button alignSelf="start" onClick={() => handleUpdate()}>
                                Save
                           </Button> */}
        </Stack>
        <Divider />
        <Stack spacing="5" divider={<StackDivider />}>
          {paymentActive ? (
            <Stack spacing={5}>
              <Stack>
                <Text textTransform={"uppercase"}>
                  {paymentActive.company_name} - {paymentActive.user_name}
                </Text>
                <Text>{paymentActive.order_id}</Text>
              </Stack>

              <Spacer />

              <Stack>
                <Text fontSize={"2xl"} fontWeight={"bold"}>
                  Rp.{formatFrice(paymentActive.package_amount)}
                </Text>
              </Stack>

              <Button
                colorScheme={"green"}
                isLoading={loadingPay}
                onClick={() => handlePayPayment()}
              >
                Pay
              </Button>
            </Stack>
          ) : (
            <Stack spacing={5}>
              {orderList?.length > 0 &&
                orderList?.map((x, i) => {
                  return (
                    <Stack
                      key={i}
                      _hover={{
                        transform: "scale(1.05)",
                        shadow: "xl",
                      }}
                      transition="0.2s ease-in-out"
                      spacing={2}
                      borderStartWidth={5}
                      borderEndWidth={5}
                      borderColor={
                        packageActive.package_name === x.package_name
                          ? "green.500"
                          : "transparent"
                      }
                      bgColor="#2B2B2B"
                      py={2}
                      px={2}
                      borderRadius="md"
                      shadow="md"
                      width="100%"
                      onClick={() => handlePackageActive(x)}
                    >
                      <HStack spacing={2} key={i} width="100%">
                        <Stack p={5}>
                          <Icon as={x.icon} boxSize={12} />
                        </Stack>
                        <Box>
                          <Text
                            textTransform="capitalize"
                            fontSize={"md"}
                            fontWeight={500}
                          >
                            {x.package_name}
                          </Text>
                          <Text fontSize="xs">{x.description}</Text>
                        </Box>
                        <Spacer />
                        <Box px={5}>
                          <Text>Rp. {formatFrice(x.package_amount)}</Text>
                        </Box>
                      </HStack>
                    </Stack>
                  );
                })}

              <Text>Company Name</Text>
              <Input
                placeholder="Company Name"
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <Text>Phone Number</Text>
              <Input
                placeholder="Phone Number"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <Button onClick={() => handlePay()}>Create</Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default PaymentPage;

import React from "react";
import FunnelCreatePageV2 from "../Pages/LadingPageV2/Funnels/FunnelCreatePageV2";
import FunnelPageV2 from "../Pages/LadingPageV2/Funnels/FunnelPageV2";
import FunnelsViewPageV2 from "../Pages/LadingPageV2/Funnels/FunnelViewPageV2";
import FunnelEditPageV2 from "../Pages/LadingPageV2/Funnels/FunnelEditPageV2";
import DomainPage from "../Pages/LadingPageV2/Domains/DomainPage";
import DomainCreatePage from "../Pages/LadingPageV2/Domains/DomainCreatePage";
import DomainSetupPage from "../Pages/LadingPageV2/Domains/DomainSetupPage";
import ExampleCreate from "../Pages/LadingPageV2/Funnels/ExampleCreate";
import ExampleCreateV2 from "../Pages/LadingPageV2/Funnels/ExampleCreateV2";
import OneClickCreateLandingPage from "../Pages/LadingPageV2/Funnels/OneClickCreateLandingPage";

const LandingPageRouter = [
  {
    path: "/funnel/quicklp",
    element: <OneClickCreateLandingPage />,
  },
  {
    path: "/funnel",
    element: <FunnelPageV2 />,
  },
  {
    path: "/funnel/view/:id/useAi/:pageId",
    element: <FunnelCreatePageV2 />,
  },
  {
    path: "/funnel/view/:id/template/:pageId",
    element: <ExampleCreateV2 />,
  },
  {
    path: "/funnel",
    element: <FunnelPageV2 />,
  },
  {
    path: "/funnel/view/:id",
    element: <FunnelsViewPageV2 />,
  },

  {
    path: "/funnel/view/:id/edit/:pageId",
    element: <FunnelEditPageV2 />,
  },
  {
    path: "/domain",
    element: <DomainPage />,
  },

  {
    path: "/create-domain",
    element: <DomainCreatePage />,
  },
  {
    path: "/create-domain/setup",
    element: <DomainSetupPage />,
  },

  {
    path: "/funnel/view/:id/edit/:pageId",
    element: <FunnelEditPageV2 />,
  },
];

export default LandingPageRouter;

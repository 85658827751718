import {
  AspectRatio,
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FcPlus } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import {
  deleteDocumentFirebase,
  getCollectionFirebase,
} from "../../Apis/firebaseApi";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import { FaRegFileVideo } from "react-icons/fa";
import BackButtons from "../../Components/Button/BackButtons";
import moment from "moment";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "@firebase/firestore";
import { db } from "../../Config/firebase";

function VideoAssetsPage() {
  const navigate = useNavigate();
  const toast = useToast();
  const [files, setFiles] = useState([]);
  const [preview, setPreview] = useState();
  const [dataVideo, setDataVideo] = useState();
  const [generatedVideo, setGeneratedVideo] = useState();
  const globalState = UseAuthStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const deleteAssetModal = useDisclosure();

  const getDataFile = async () => {
    // const conditions = [
    //   { field: "companyId", operator: "==", value: globalState.currentCompany },
    //   { field: "type", operator: "==", value: "video_assets" },
    //   { field: "category", operator: "==", value: "video" },
    // ];
    // // const sortBy = { field: "nama_field_sort", direction: "asc" };

    // try {
    //   const res = await getCollectionFirebase("files", conditions);

    //   setFiles(res);
    // } catch (error) {
    //   console.log(error, "ini error");
    // }

    if (!globalState?.currentCompany) {
      return toast({
        title: "Error",
        description: "Please check your select company",
        status: "error",
      });
    }
    if (!globalState?.currentProject) {
      return toast({
        title: "Error",
        description: "Please check your select project",
        status: "error",
      });
    }

    try {
      let q = query(
        collection(db, "files"),
        where("projectId", "==", globalState.currentProject),
        where("companyId", "==", globalState.currentCompany),
        where("type", "==", "video_assets"),
        where("category", "==", "video"),
        orderBy("createdAt", "desc")
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const uploadedvideoArr = [];
        querySnapshot.forEach((doc) => {
          uploadedvideoArr.push({ ...doc.data(), id: doc.id });
        });

        setFiles(uploadedvideoArr);
      });
    } catch (error) {
      console.log("Error getting documents: ", error);
    }
  };

  const getDataGeneratedVideo = async () => {
    // const conditions = [
    //   { field: "type", operator: "==", value: "video_assets" },
    //   { field: "category", operator: "==", value: "video_results" },
    //   { field: "companyId", operator: "==", value: globalState.currentCompany },
    // ];
    // // const sortBy = { field: "nama_field_sort", direction: "asc" };

    // try {
    //   const res = await getCollectionFirebase("files", conditions);

    //   setGeneratedVideo(res);
    // } catch (error) {
    //   console.log(error, "ini error");
    // }

    if (!globalState?.currentCompany) {
      return toast({
        title: "Error",
        description: "Please check your select company",
        status: "error",
      });
    }
    if (!globalState?.currentProject) {
      return toast({
        title: "Error",
        description: "Please check your select project",
        status: "error",
      });
    }

    try {
      let q = query(
        collection(db, "files"),
        where("projectId", "==", globalState.currentProject),
        where("companyId", "==", globalState.currentCompany),
        where("type", "==", "video_assets"),
        where("category", "==", "video_results"),
        orderBy("createdAt", "desc")
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const generatedVideoArr = [];
        querySnapshot.forEach((doc) => {
          generatedVideoArr.push({ ...doc.data(), id: doc.id });
        });

        setGeneratedVideo(generatedVideoArr);
      });
    } catch (error) {
      console.log("Error getting documents: ", error);
    }
  };

  const handleOpenModal = (video) => {
    deleteAssetModal.onOpen();
    setDataVideo(video);
  };

  const handleDeleteAsset = async () => {
    try {
      const result = await deleteDocumentFirebase("funnels", dataVideo.id);

      toast({
        status: "success",
        title: "Deoapp AI",
        description: "Video Deleted",
        duration: 1000,
      });

      deleteAssetModal.onClose();
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
    }
  };

  const handleViewVideo = (file) => {
    setPreview(file);

    onOpen();
  };

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  useEffect(() => {
    getDataFile();
    getDataGeneratedVideo();

    return () => {};
  }, [globalState.currentCompany, globalState.currentProject]);

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <BackButtons />

      <HStack>
        <Heading>Video Assets</Heading>
        <Spacer />
        <Button
          colorScheme="green"
          variant="outline"
          // onClick={() => navigate(`/create-aissistant/${param.name}`)}
        >
          <HStack spacing={2}>
            <FcPlus />
            <Text
              textTransform="uppercase"
              fontSize="sm"
              fontWeight={500}
              onClick={() => navigate("upload")}
            >
              Upload
            </Text>
          </HStack>
        </Button>
      </HStack>

      {/* <Input
        type="text"
        placeholder="Search Files ..."
        bgColor="white"
        color="black"
        sx={inputStyles}
        fontSize="sm"
        // onChange={(e) => searchFileFilterFunction(e.target.value)}
      /> */}

      <Tabs colorScheme="green" isFitted>
        <TabList>
          <Tab>Uploaded Video</Tab>
          <Tab>Generated Video Results</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {files?.length > 0 ? (
              <>
                <SimpleGrid columns={(2, null, 4)} spacing={3} my={4}>
                  {files.map((file, i) => (
                    <Stack
                      cursor={"pointer"}
                      key={i}
                      _hover={{
                        transform: "scale(1.02)",
                        shadow: "xl",
                      }}
                      transition={"0.2s ease-in-out"}
                      spacing={2}
                      borderTopWidth={3}
                      borderColor="green.500"
                      justifyContent="start"
                      bgColor="#2B2B2B"
                      py={4}
                      px={2}
                      borderRadius="md"
                      shadow="md"
                    >
                      <Box align="center" onClick={() => handleViewVideo(file)}>
                        <Icon boxSize={"50px"} as={FaRegFileVideo} />
                        <Text mt={4}>{file.name}</Text>
                      </Box>
                      <HStack mt={3} px={3}>
                        <Text fontSize={"xs"}>
                          {moment
                            .unix(
                              file?.lastUpdated?.seconds ??
                                file?.createdAt?.seconds
                            )
                            .fromNow()}
                        </Text>
                        <Spacer />
                        <Button
                          size={"xs"}
                          onClick={() => handleOpenModal(file)}
                        >
                          <DeleteIcon />
                        </Button>
                      </HStack>
                    </Stack>
                  ))}
                </SimpleGrid>
              </>
            ) : null}
          </TabPanel>
          <TabPanel>
            {generatedVideo?.length > 0 ? (
              <>
                <SimpleGrid columns={(2, null, 4)} spacing={3} mt={4}>
                  {generatedVideo.map((file, i) => (
                    <Box
                      cursor={"pointer"}
                      key={i}
                      _hover={{
                        transform: "scale(1.02)",
                        shadow: "xl",
                      }}
                      transition={"0.2s ease-in-out"}
                      spacing={2}
                      borderTopWidth={3}
                      borderColor="green.500"
                      align="center"
                      justifyContent="start"
                      bgColor="#2B2B2B"
                      py={4}
                      px={2}
                      borderRadius="md"
                      shadow="md"
                    >
                      <Box align="center" onClick={() => handleViewVideo(file)}>
                        <Icon boxSize={"50px"} as={FaRegFileVideo} />
                        <Text mt={4}>{file.name}</Text>
                      </Box>
                      <HStack mt={3} px={3}>
                        <Text fontSize={"xs"}>
                          {moment
                            .unix(
                              file?.lastUpdated?.seconds ??
                                file?.createdAt?.seconds
                            )
                            .fromNow()}
                        </Text>
                        <Spacer />
                        <Button
                          size={"xs"}
                          onClick={() => handleOpenModal(file)}
                        >
                          <DeleteIcon />
                        </Button>
                      </HStack>
                    </Box>
                  ))}
                </SimpleGrid>
              </>
            ) : null}
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{preview?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AspectRatio>
              <iframe src={preview?.link} />
            </AspectRatio>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={deleteAssetModal.isOpen}
        onClose={deleteAssetModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{dataVideo?.name} Video</ModalHeader>
          <ModalBody>Video will be deleted, are you sure?</ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                size={"sm"}
                colorScheme="green"
                onClick={handleDeleteAsset}
              >
                Yes
              </Button>
              <Button
                size={"sm"}
                colorScheme="red"
                onClick={deleteAssetModal.onClose}
              >
                No
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default VideoAssetsPage;
